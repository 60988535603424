import React, { Component } from "react";
import "./viewProfile.scss";
import avtarIcon from "../../assets/images/avtar.png";
import { Picker } from "emoji-mart";
import { instance, baseURL } from "../../axios";
import { quote } from "../../shared/utility";
import checkIcon from "../../assets/images/check-icon.png";
import closeIcon from "../../assets/images/close-icon.png";
import { connect } from "react-redux";
import * as actions from "../../store/actions";

class viewProfile extends Component {
  state = {
    userID: "",
    photoID: "",
    sendMessage: "",
    showEmoji: false,
    replyTo: "",
  };
  componentDidMount() {
    let userID = localStorage.getItem("user_id");
    let photoID = localStorage.getItem("photo_id");
    let groupTable = window.location.pathname.split("/")[3];
    let groupEvent = window.location.pathname.split("/")[1];
    this.setState({
      userID: userID,
      photoID: photoID,
      groupEvent: groupEvent,
      groupTable: groupTable,
      // activeChat: groupEvent,
    });

    instance
      .get(`_getfomoonboard.json?user_id=${userID}&photo_id=${photoID}`)
      .then((res) => {
        let requiredDetail = res.data.onboard_dic.profile.actions;
        let getGuest = res.data.onboard_completed_dic.profile;
        let getKeys = Object.keys(requiredDetail);
        let completeObject = {};
        getKeys.map((opt) => {
          let getField = requiredDetail[opt];
          let checkFields = getField.filter((element) =>
            getGuest.includes(element)
          );
          if (checkFields.length == getField.length) {
            completeObject[opt] = true;
          } else {
            completeObject[opt] = false;
          }
        });
        console.log(completeObject, "working");
        this.setState({
          getAllFields: completeObject,
        });
      });
  }
  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  emojiHandlar = () => {
    this.setState((prevState) => ({
      backDrop: !prevState.backDrop,
      showEmoji: !prevState.showEmoji,
    }));
  };
  addEmoji = (e) => {
    let emoji = e.native;
    this.setState({
      backDrop: true,
      sendMessage: this.state.sendMessage + emoji,
    });
  };
  sendMessageToUser = (e) => {
    e.preventDefault();
    if (this.state.getAllFields.fomomsg_chat == false) {
      this.props.stepHandler(7, "signup", "fomomsg_chat");
    } else {
      let message = quote(this.state.sendMessage);

      if (this.state.sendMessage.trim() !== "") {
        instance
          .get(
            `_fomomsg_sendmsg?from_user_id=${this.state.userID}&from_photo_id=${this.state.photoID}&from_alias=${this.props.userDetail.alias}&msg_type=fomo_msg&object_id=@${this.props.popupDetail.photo_id}&msg_text=${message}&msg_content_type=text&fomomsg_source=userlist&group_id=${this.state.groupEvent}`
          )
          .then((res) => {
            console.log(res, "working");
            instance
              .get(
                `_fomomsg_getprofile?user_id=${this.state.userID}&photo_id=${res.data.photo_id}&notification_flag=true`
              )
              .then((response) => {
                this.setState({
                  replyTo: this.props.popupDetail.alias,
                  sendMessage: "",
                  showEmoji: false,
                });
                setTimeout(() => {
                  this.setState({
                    replyTo: "",
                  });
                }, 10000);
                // console.log(res.data, res.data.photo_id)
                response.data.from_alias = response.data.alias;
                response.data.from_photo_id = response.data.photo_id;

                this.props.addChat(response.data);
              });
          });
      }
    }
  };
  closeHandler = () => {
    this.setState({
      sendMessage: "",
    });
    this.props.click();
  };
  render() {
    return (
      <div
        className={
          this.props.toggleHandler ? "view-profile active" : "view-profile"
        }
      >
        {/* <div className="backDrop" onClick={this.props.click}></div> */}
        <div
          className={
            this.state.replyTo ? "success-message active" : "success-message"
          }
        >
          <span>
            <img src={checkIcon} />
          </span>
          <p>Message sent to {this.state.replyTo}</p>
          <button onClick={() => this.setState({ replyTo: "" })} type="button">
            <img src={closeIcon} />
          </button>
        </div>
        {this.props.popupDetail ? (
          <div className="popup-box">
            <span onClick={this.closeHandler} className="back-btn">
              <i className="fa fa-angle-left" aria-hidden="true"></i> Back
            </span>
            <div className="head-box">
              <figure>
                <img
                  src={
                    this.props.popupDetail.thumb_value
                      ? baseURL +
                        this.props.popupDetail.thumb_value.split("..")[1]
                      : avtarIcon
                  }
                />
              </figure>
              <div className="detail-box">
                <h4>{this.props.popupDetail.alias}</h4>
                <div className="detail-group">
                  <ul>
                    {this.props.popupDetail.profile_age !== 0 ? (
                      <li>{this.props.popupDetail.profile_age} years old</li>
                    ) : (
                      ""
                    )}
                    {this.props.popupDetail.birthdate_sign ? (
                      <li>this.props.popupDetail.birthdate_sign</li>
                    ) : (
                      ""
                    )}
                    {/* <li>♍︎︎️ Virgo</li> */}
                  </ul>
                  <ul className="social-detail">
                    {this.props.popupDetail.account_links.link_facebook ? (
                      <li>
                        <a
                          href={
                            this.props.popupDetail.account_links.link_facebook
                          }
                          target="_blank"
                        >
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.props.popupDetail.account_links.link_instagram ? (
                      <li>
                        <a
                          href={
                            this.props.popupDetail.account_links.link_instagram
                          }
                          target="_blank"
                        >
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.props.popupDetail.account_links.link_twitter ? (
                      <li>
                        <a
                          href={
                            this.props.popupDetail.account_links.link_twitter
                          }
                          target="_blank"
                        >
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.props.popupDetail.account_links.link_soundcloud ? (
                      <li>
                        <a
                          href={
                            this.props.popupDetail.account_links.link_soundcloud
                          }
                          target="_blank"
                        >
                          <i
                            className="fa fa-soundcloud"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                {this.state.photoID !== this.props.popupDetail.photo_id ? (
                  <div className="send-box">
                    <div
                      className="back-drop"
                      style={{
                        display: this.state.showEmoji ? "block" : "none",
                      }}
                      onClick={this.emojiHandlar}
                    ></div>
                    <form autoComplete="off" onSubmit={this.sendMessageToUser}>
                      <input
                        type="text"
                        name="sendMessage"
                        placeholder={
                          "Send message to " + this.props.popupDetail.alias
                        }
                        value={this.state.sendMessage}
                        onChange={this.inputHandler}
                      />
                      <ul>
                        <li>
                          <button type="submit">
                            <i className="ion-android-send" />
                          </button>
                        </li>
                        <li>
                          <i
                            onClick={this.emojiHandlar}
                            className="fa fa-smile-o"
                          ></i>
                          <Picker
                            style={{
                              display: this.state.showEmoji ? "block" : "none",
                            }}
                            onSelect={this.addEmoji}
                          />
                        </li>
                      </ul>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="body-section">
              <div className="colmn-text">
                {this.props.popupDetail.profile_desc ? (
                  <div className="text-box">
                    <span>Fun fact about me</span>
                    <p>{this.props.popupDetail.profile_desc}</p>
                  </div>
                ) : (
                  ""
                )}
                {this.props.popupDetail.profile_interests ? (
                  <div className="text-box">
                    <span>Interests</span>
                    <p>{this.props.popupDetail.profile_interests}</p>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="text-box">
								<span>Interests</span>
								<p>Surfing, hiking, camping, rock climbing, singing in the shower</p>
							</div> */}
              </div>
              {/* <div className="colmn-img">
							<span>Posts</span>
							<ul>
								<li>
									<img src={avtarIcon} />
								</li>
								<li>
									<img src={avtarIcon} />
								</li>
							</ul>
						</div> */}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    addChat: (user) => dispatch(actions.addChat(user)),
    stepHandler: (step, type, checkType) =>
      dispatch(actions.stepHandler(step, type, checkType)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(viewProfile);
