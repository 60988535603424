import * as actionTypes from './actionTypes';

export const groupDetailSuccess = (detail) => {
	return {
		type: actionTypes.GROUP_DETAIL,
		payload: detail
	};
};

export const groupDetail = (detail) => dispatch => {
	dispatch(groupDetailSuccess(detail))

}
