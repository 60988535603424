import React, { Component } from "react";
import { instance } from "../../axios";
import { Redirect, Link } from "react-router-dom";
import "./recover.scss";

class Recover extends Component {
  state = {
    success: null,
  };
  componentDidMount() {
    instance.get(`__recover/${this.props.match.params.id}`).then((res) => {
      if (res.data.valid_response === "VALID") {
        localStorage.setItem("photo_id", res.data.photo_id);
        localStorage.setItem("user_email", res.data.user_email);
        localStorage.setItem("user_id", res.data.user_id);
        if (res.data.data_url && res.data.dest_url != "") {
          window.location.href = res.data.dest_url;
        }

        setTimeout(() => {
          this.setState({
            success: true,
            url: res.data.dest_url,
          });
        }, 2000);
      }
      if (res.data.valid_response === "INVALID_LINK") {
        setTimeout(() => {
          this.setState({
            success: false,
          });
        }, 2000);
      }
    });
  }

  render() {
    // let pageView = '';
    // if (this.state.success) {
    // 	pageView = <Redirect to={localStorage.getItem('confirmLink')} />
    // }
    // if (this.state.success === false) {
    // 	pageView = ''
    // }
    return (
      <>
        {this.state.success ? (
          this.state.url ? (
            (window.location.href = this.state.url)
          ) : (
            <Redirect to={localStorage.getItem("confirmLink")} />
          )
        ) : null}
        {this.state.success === false ? (
          <div className="recover-box">
            <h2>Invalid link</h2>
            <p>Please generate another magic link</p>
          </div>
        ) : null}
      </>
    );
  }
}
export default Recover;
