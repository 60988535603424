import * as actionTypes from './actionTypes';

export const addChatSuccess = (detail) => {
	return {
		type: actionTypes.ADD_CHAT,
		payload: detail
	};
};

export const addChat = (detail) => dispatch => {
	dispatch(addChatSuccess(detail))

}
