import React, { Component } from "react";
import "./App.scss";
import Login from "./containers/login/login";
import Profile from "./containers/profile/profile";
import Home from "./containers/home/home";
import Registration from "./containers/registration/registration";
import Recover from "./containers/recover/recover";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import NoMatchPage from "./containers/noMatchPage/noMatchPage";
import MapView from "./components/map/map";
import { instance } from "./axios";
import confirmation from "./containers/confirmation/confirmation";
import CreateEvent from "./containers/createEvent/createEvent";
import NotFound from "./containers/notFound/notFound";
import EnterCode from "./containers/enterCode/enterCode";

class App extends Component {
  componentDidMount() {}

  render() {
    let redirect = "";
    let not_protected = [
      "/_login",
      "/_recover:id",
      "/_sign-up",
      "/_profile",
      "/_emailconfirmed",
      "/_notfound",
      "/_newevent",
    ];
    let validateUrls = ["_login", "_recover", "_sign-up", "_profile", "map"];

    if (
      localStorage.getItem("user_id") === null ||
      localStorage.getItem("user_id") === ""
    ) {
      if (
        !not_protected.includes(window.location.pathname) &&
        window.location.pathname.indexOf("_recover") === -1
      ) {
        // redirect = <Redirect to='/_login' />
      }
    }

    // validateUrls.map(url => {
    // 	if (url !== window.location.pathname.split('/')[1]) {
    // 		if (!localStorage.getItem('user_id')) {
    // 			localStorage.setItem('inviteId', window.location.pathname)
    // 		}
    // 	}
    // })
    if (!validateUrls.includes(window.location.pathname.split("/")[1])) {
      if (!localStorage.getItem("user_id")) {
        localStorage.setItem("inviteId", window.location.pathname);
        console.log("working");
      }
    }

    return (
      <>
        {redirect}
        <Switch>
          <Route path="/_login" exact component={Login} />
          <Route path="/_sign-up" exact component={Registration} />
          <Route path="/_profile" exact component={Profile} />
          <Route path="/_emailconfirmed" exact component={confirmation} />
          <Route path="/_recover/:id" exact component={Recover} />
          <Route path="/_notfound" exact component={NotFound} />
          <Route path="/_newevent" exact component={CreateEvent} />
          <Route path="/:eventName" exact component={Home} />
          <Route path="/:eventName/:userId" exact component={Home} />
          <Route path="/:eventName/:userId/:tableId" exact component={Home} />
          <Route
            path="/:eventName/:invited_by/:eventTable/:id"
            exact
            component={MapView}
          />
          <Route path="/" exact component={EnterCode} />
          {/* <Route path="/:eventName/0/:eventTable" exact component={MapView} /> */}
          <Route component={NoMatchPage} />
        </Switch>
      </>
    );
  }
}

export default App;
