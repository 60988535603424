import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import './confirmation.scss';
import checkIcon from '../../assets/images/check-pink.svg';
import ProfileLayout from '../../hoc/profileLayout/profileLayout'



class Profile extends Component {
	state = {
		email: ''


	}

	componentDidMount() {
		this.setState({
			email: window.location.search.split('%22')[1]
		})
	}


	render() {
		return (
			<ProfileLayout>

				<div className="profile-group confirmation-box">
					<figure>
						<img src={checkIcon} />
					</figure>
					<h3>Email address confirmed</h3>
					<p>Thanks! You have successfully confirmed your email address.</p>
					<p>{this.state.email}</p>
					<p> Enjoy your stay and have fun!</p>
					<button type="button" className="btn btn-pink">CLOSE</button>
				</div>
			</ProfileLayout>
		)
	}
}
export default Profile;
