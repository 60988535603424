import React, { Component } from 'react';
import { Holder } from '../holder';
import './profileLayout.scss';


class profileLayout extends Component {

	render() {
		return (
			<Holder>
				<main className="profile-layout">
					<div className="container">
						{this.props.children}
					</div>
				</main>
			</Holder>
		)
	}
}
export default profileLayout;
