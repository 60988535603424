import * as actionTypes from './actionTypes';

export const stepHandlerSuccess = (step, type, fieldType) => {
	return {
		type: actionTypes.WELCOME_STEP,
		payload: {
			number: step,
			type: type,
			fieldType: fieldType,
		}
	};
};

export const stepHandler = (step, type = null, fieldType = null) => dispatch => {
	dispatch(stepHandlerSuccess(step, type, fieldType))

}
