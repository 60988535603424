import React, { Component } from "react";
import AdminLayout from "../../hoc/adminLayout/adminLayout";
import loginImg from "../../assets/images/login-img.png";
import "./login.scss";
import { Link } from "react-router-dom";
import { instance } from "../../axios";
import { checkValidity } from "../../shared/utility";
import { Redirect } from "react-router-dom";
import Loading from "../../components/loading/loading";

class Login extends Component {
  state = {
    email: "",
    emailError: "",
    loading: false,
    formMessage: "",
    success: null,
    password: "",
  };

  validateCheck = [
    {
      field: "email",
      rules: [
        { type: "isEmail", message: "Enter a valid email address." },
        { type: "required", message: "This field is required" },
      ],
    },
    {
      field: "password",
      rules: [{ type: "required", message: "This field is required" }],
    },
  ];
  checkField = (...para) => {
    let that = this;
    // console.log(para)
    this.validateCheck.forEach((field) => {
      let value = that.state[field["field"]];
      let isvalid = checkValidity(value, field["rules"]);
      para.map((test) => {
        if (isvalid != null) {
          if (field["field"] == test) {
            this.setState({
              [`${test}Error`]: isvalid.error,
            });
          }
        }
      });
    });
  };

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: null,
    });
  };

  blurHendler = (e) => {
    this.checkField(e.target.name);
    console.log(e.target.name);
  };

  submitHandler = (e) => {
    e.preventDefault();

    this.checkField("email");
    if (this.state.emailError == null) {
      this.setState({
        loading: true,
      });
      instance
        .get(`_recoverprofile.json?user_email=${this.state.email}`)
        .then((res) => {
          this.setState({
            loading: false,
            formMessage: "",
          });
          if (res.data.valid_response === "VALID") {
            this.setState({
              formMessage: "We sent a magic link to your email",
              success: true,
            });
          }
          if (res.data.valid_response === "INVALID") {
            localStorage.setItem("not_email", this.state.email);
            this.setState({
              formMessage: "Email not exist",
              success: false,
            });
          }
        });
    }
  };
  componentDidMount() {
    localStorage.setItem("confirmLink", localStorage.getItem("inviteId"));
  }

  render() {
    return (
      <AdminLayout>
        <Loading show={this.state.loading} />
        <div className="authentication-group">
          <div className="head-box">
            <h1>
              {window.location.hostname == "theplaya.org"
                ? "The Playa"
                : "FOMO"}
            </h1>
            <p>
              Hooray! No need to remember pesky passwords! Just type in your
              email and we’ll send you a login link
            </p>
          </div>
          {/* <h2>Log in to your account</h2>
					<h3>Enter the email you’ve signed up with and we’ll send you a link to enter your account</h3> */}
          <form autoComplete="off" onSubmit={this.submitHandler}>
            <div className="form-group">
              <input
                autoComplete="off"
                type="text"
                onBlur={this.blurHendler}
                className="form-control"
                name="email"
                onChange={this.inputHandler}
                value={this.state.email}
                placeholder="Email"
              />
              {this.state.emailError ? (
                <div className="error-input">
                  <p>{this.state.emailError}</p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={this.state.loading}
                className="btn"
              >
                Log in
              </button>
            </div>
          </form>
          <ul>
            {/* <li><a href="forgot-password">Forgot password?</a></li> */}
            <li>
              <Link to="/_sign-up">
                <>
                  <span>
                    New to{" "}
                    {window.location.hostname == "theplaya.org"
                      ? "The Playa"
                      : "FOMO"}
                    ?
                  </span>{" "}
                  Sign up
                </>
              </Link>
            </li>
          </ul>
          {this.state.formMessage && this.state.success == false ? (
            <Redirect to="/_sign-up" />
          ) : null}

          {this.state.formMessage && this.state.success == true ? (
            <div
              className={
                this.state.success
                  ? "form-message success"
                  : "form-message error"
              }
            >
              <p>{this.state.formMessage}</p>
            </div>
          ) : null}
        </div>
      </AdminLayout>
    );
  }
}
export default Login;
