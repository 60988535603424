import React, { Component } from 'react';
import './detailPopup.scss';
import userIcon from '../../assets/images/icon.png';
import artistIcon from '../../assets/images/artist-icon.png';
import { quote, unicodeToChar } from '../../shared/utility';

class detailPopup extends Component {
	state = {
		tabNavs: ['About', 'Donate', 'Message artist'],
		activeTab: 0,
	}
	tabHandlar = (index) => {
		this.setState({
			activeTab: index
		})
	}
	render() {
		return (
			<div style={{
				display: this.props.toggleHandler ? 'block' : 'none'

			}} className="popup detail-popup">
				<div className="backDrop" onClick={this.props.click}></div>
				<div className="popup-box">
					<span className="btn-close" onClick={this.props.click}><i className="ion-ios-close-empty"></i></span>
					<div className="tab-section">
						<ul className="tab-btn">
							{this.state.tabNavs.map((nav, key) => (
								<li key={key} className={this.state.activeTab == key ? 'active' : 'none'} onClick={() => this.tabHandlar(key)}>{nav}</li>
							))}
						</ul>
						<div className="tab-result">

							<div className="tab-group" style={{ 'display': this.state.activeTab == 0 ? 'block' : 'none' }}>
								<div className="about-box">
									<div className="icon-box">
										<figure>
											<img src={userIcon} alt="icon" />
										</figure>
									</div>
									<div className="content-section">
										{this.props.groupDetail ? <h5>{unicodeToChar(this.props.groupDetail.group_name)}</h5> : null}

										<span>2,563,108 fans</span>

										{this.props.groupDetail ? <p>{unicodeToChar(this.props.groupDetail.group_desc)}</p> : null}

									</div>
								</div>
							</div>
							<div className="tab-group" style={{ 'display': this.state.activeTab == 1 ? 'block' : 'none' }}>
								<div className="donate-box">
									<p>If you’d like to support us, you submit a donation below</p>
									<div className="form-box">
										<div className="form-group">
											<label>Choose donation amount</label>
											<ul>
												<li onClick={() => this.setState({ donate: '$5' })}><span>$5</span></li>
												<li onClick={() => this.setState({ donate: '$10' })}><span>$10</span></li>
												<li onClick={() => this.setState({ donate: '$20' })}><span>$20</span></li>
												<li onClick={() => this.setState({ donate: '$50' })}><span>$50</span></li>
												<li>other: <input autoComplete="off" type="text" value={this.state.donate} onChange={this.inuptHandler} name="donate" /></li>
											</ul>
										</div>
										<div className="form-group">
											<label>Add message <span>Optional</span></label>
											<textarea placeholder="Type your message" value={this.donateMessage} name="donateMessage" onChange={this.inuptHandler}></textarea>
										</div>
										<div className="form-group">
											<button type="button" className="btn btn-purple">Submit</button>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-group" style={{ 'display': this.state.activeTab == 2 ? 'block' : 'none' }}>
								<div className="artist-message">

									<div className="user-box">
										<figure>
											<img src={artistIcon} alt="artist icon" />
										</figure>
										<div className="test-box">
											<div className="testDetail-box">
												<h2>Martin Garrix</h2>
												<div className="detail-box">

													<ul>
														<li><a target="_blank" href="/"><figure><i className="fa fa-instagram" aria-hidden="true"></i></figure></a></li>
														<li><a target="_blank" href="/"><figure><i className="fa fa-facebook" aria-hidden="true"></i></figure></a></li>
														<li><a target="_blank" href="/"><figure><i className="fa fa-twitter" aria-hidden="true"></i></figure></a></li>
														<li><a target="_blank" href="/"><figure><i className="fa fa-soundcloud" aria-hidden="true"></i></figure></a></li>
													</ul>
												</div>
											</div>
											<div className="form-group">
												<textarea placeholder="Send message directly to the artist" value={this.artistMessage} onChange={this.inuptHandler} name="artistMessage"></textarea>
												<ul>
													<li><button type="button"><i className="ion-android-send"></i></button></li>
													<li><button type="button"><i className="fa fa-smile-o"></i></button></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="detail-box">
										<span>About</span>
										<p>Martin Garrix has risen to global stardom in pop as well as electronic circles –and yet he’s far from being done. Dance music’s freshest talent has headlined festivals around the globe, founded a label and mentored other artists and won the #1 spot in DJ Mag’s Top 100 twice. His passion, ambition, drive and maturity remain unmatched in the world.</p>
									</div>

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default detailPopup;
