import React, { Component } from 'react';
import { Holder } from '../holder';
import Header from '../../components/header/header';




class layout extends Component {

	render() {
		return (
			<Holder>
				<Header backBtn={this.props.backBtn} parentMapUrl={this.props.parentMapUrl} headerClass={this.props.headerClass} userDetail={this.props.userDetail} />
				<main>
					{this.props.children}
				</main>

			</Holder>
		)
	}
}
export default layout;
