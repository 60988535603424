import React, { Component } from "react";
import "./notFound.scss";

class NotFound extends Component {
  render() {
    return (
      <div className="recover-box">
        <h2>Event Not Found</h2>
        {/* <p>Please generate another magic link</p> */}
      </div>
    );
  }
}
export default NotFound;
