import React, { Component } from 'react';
import AdminLayout from '../../hoc/adminLayout/adminLayout';
import loginImg from '../../assets/images/login-img.png';
import { Link } from 'react-router-dom';



class noMatchPage extends Component {


	render() {

		return (
			<AdminLayout>

				<div className="authentication-group error-group">
					<h1>Oops!</h1>

					<p>We can’t find the page you’re looking for</p>
					<ul>
						{/* <li><a href="forgot-password">Forgot password?</a></li> */}
						<li><Link to="/login" className="btn">Back to Home</Link></li>
					</ul>

					{/* <figure className="image-box">
						<img src={loginImg} alt="login-img" />
					</figure> */}
				</div>
			</AdminLayout>
		)
	}
}
export default noMatchPage;
