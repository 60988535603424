import * as actionTypes from './actionTypes';

export const editProfileSuccess = (detail) => {
	return {
		type: actionTypes.CHANGE_PROFILE_PIC,
		payload: detail
	};
};

export const editProfile = (detail, type) => dispatch => {
	dispatch(editProfileSuccess({ value: detail, valueType: type }))

}
