import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	step: '',
	stepType: null,
	fieldType: null


}
const welcomeStepSuccess = (state, action) => {
	return updateObject(state, {
		step: action.payload.number,
		stepType: action.payload.type,
		fieldType: action.payload.fieldType
	})
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.WELCOME_STEP:
			return welcomeStepSuccess(state, action)
		default:
			return state;
	}

}


export default reducer
