import React, { Component } from "react";
import "./editProfile.scss";
import avtarIcon from "../../assets/images/avtar.png";
import { baseURL, instance } from "../../axios";
import BMF from "browser-md5-file";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { unicodeToChar, quote } from "../../shared/utility";

class EditProfile extends Component {
  state = {
    userID: "",
    photoID: "",
    regexp: /^[0-9\b]+$/,
    linkRegexp:
      /^(https?:\/\/)?([a-z\d]([a-z\d-.]?[a-z\d])*\.[a-z]([a-z\.]?[a-z])*){2,255}(\/[\w-\.~!$&'()*+,;=:@%]*)*$/i,
    month: "",
    day: "",
    year: "",
    editName: false,
    showNameError: false,
    groupTable: "",
    groupTable: "",
    editEmail: false,
  };
  componentDidMount() {
    console.log(this.props.onboardCompleted);
    let userID = localStorage.getItem("user_id");
    let photoID = localStorage.getItem("photo_id");
    let groupTable = window.location.pathname.split("/")[3]
      ? window.location.pathname.split("/")[3].toUpperCase()
      : window.location.pathname.split("/")[3];
    let groupEvent = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1].toUpperCase()
      : window.location.pathname.split("/")[1];
    this.setState({
      userID: userID,
      photoID: photoID,
      groupTable: groupTable,
      groupEvent: groupEvent,
      alias: this.props.userDetail.alias,
      month: this.props.userDetail.birthdate.birthdate_month,
      day: this.props.userDetail.birthdate.birthdate_day,
      year: this.props.userDetail.birthdate.birthdate_year,
      nameCopy: this.props.userDetail.profile_name
        ? this.props.userDetail.profile_name.name_first +
          " " +
          this.props.userDetail.profile_name.name_middle +
          " " +
          this.props.userDetail.profile_name.name_last
        : "",
      name: this.props.userDetail.profile_name
        ? this.props.userDetail.profile_name.name_first +
          " " +
          this.props.userDetail.profile_name.name_middle +
          " " +
          this.props.userDetail.profile_name.name_last
        : "",
      age: this.props.userDetail.profile_age
        ? this.props.userDetail.profile_age
        : "",
      userImg: this.props.userDetail.thumb_value
        ? baseURL + this.props.userDetail.thumb_value.split("..")[1]
        : avtarIcon,
      About: this.props.userDetail ? this.props.userDetail.profile_desc : "",
      birthdate_sign: this.props.userDetail
        ? this.props.userDetail.birthdate.birthdate_sign
        : "",
      Interests: this.props.userDetail
        ? this.props.userDetail.profile_interests
        : "",
      instagram: this.props.userDetail.account_links
        ? this.props.userDetail.account_links.link_instagram
        : "",
      facebook: this.props.userDetail.account_links
        ? this.props.userDetail.account_links.link_facebook
        : "",
      twitter: this.props.userDetail.account_links
        ? this.props.userDetail.account_links.link_twitter
        : "",
      soundcloud: this.props.userDetail.account_links
        ? this.props.userDetail.account_links.link_soundcloud
        : "",
    });

    instance
      .get(`_getprofile.json?user_id=${userID}&photo_id=${photoID}`)
      .then((res) => {
        console.log(res, "email");
        this.setState({
          UserEmail: res.data.user_email,
        });
      });
  }
  // componentDidUpdate(prevProps, prevState) {
  // 	if (prevProps.userDetail !== this.props.userDetail) {
  // 		this.setState({
  // 			alias: this.props.userDetail.alias,
  // 			month: this.props.userDetail.birthdate.birthdate_month,
  // 			day: this.props.userDetail.birthdate.birthdate_day,
  // 			year: this.props.userDetail.birthdate.birthdate_year,
  // 			name: this.props.userDetail.profile_name ? this.props.userDetail.profile_name.name_first + ' ' + this.props.userDetail.profile_name.name_middle + ' ' + this.props.userDetail.profile_name.name_last : '',
  // 			age: this.props.userDetail.profile_age ? this.props.userDetail.profile_age : '',
  // 			userImg: this.props.userDetail.thumb_value ? baseURL + this.props.userDetail.thumb_value.split('..')[1] : avtarIcon,
  // 			About: this.props.userDetail ? this.props.userDetail.profile_desc : '',
  // 			birthdate_sign: this.props.userDetail ? this.props.userDetail.birthdate.birthdate_sign : '',
  // 			Interests: this.props.userDetail ? this.props.userDetail.profile_interests : '',
  // 			instagram: this.props.userDetail.account_links ? this.props.userDetail.account_links.link_instagram : '',
  // 			facebook: this.props.userDetail.account_links ? this.props.userDetail.account_links.link_facebook : '',
  // 			twitter: this.props.userDetail.account_links ? this.props.userDetail.account_links.link_twitter : '',
  // 			soundcloud: this.props.userDetail.account_links ? this.props.userDetail.account_links.link_soundcloud : '',
  // 		})
  // 	}
  // }

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  inputNameHandler = (e) => {
    let fullName = [""];
    fullName = e.target.value
      .trim()
      .split(" ")
      .filter((val) => {
        if (val != "") {
          return val;
        }
      });
    // console.log(fullName)

    if (fullName[0]) {
      console.log(fullName[0][0]);
      if (fullName.length == 0 || fullName[0].length <= 20) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  nameBlurHandler = (e) => {
    let nameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-_.]*[a-zA-Z0-9])?$/;
    let fullName = [""];
    fullName = e.target.value
      .trim()
      .split(" ")
      .filter((val) => {
        if (val != "") {
          return val;
        }
      });
    if (fullName[0]) {
      if (nameRegex.test(fullName[0]) == false) {
        this.setState({
          showNameError: true,
        });
      } else {
        this.setState({
          showNameError: false,
        });
      }
    }
  };
  editHandler = (name) => {
    this.setState({
      ["edit" + name]: true,
    });
  };

  aboutCancelHandler = () => {
    this.setState({
      editAbout: false,
      About: this.props.userDetail.profile_desc,
    });
  };

  interestsCancelHandler = () => {
    this.setState({
      editInterests: false,
      Interests: this.props.userDetail.profile_interests,
    });
  };
  saveAboutHandler = () => {
    this.setState({
      editAbout: false,
    });
    instance
      .get(
        `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${
          this.state.photoID
        }&update_type=client_flag&update_struct[profile_desc]=${quote(
          this.state.About
        )}`
      )
      .then((res) => {
        // console.log(res);
      });
  };

  saveInterestsHandler = () => {
    this.setState({
      editInterests: false,
    });
    instance
      .get(
        `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${
          this.state.photoID
        }&update_type=client_flag&update_struct[profile_interests]=${quote(
          this.state.Interests
        )}`
      )
      .then((res) => {
        // console.log(res);
      });
  };
  saveNameHandler = () => {
    if (this.state.showNameError == false) {
      if (this.state.name != this.state.nameCopy) {
        let nameSplite = this.state.name
          .trim()
          .split(" ")
          .filter((val) => {
            if (val != "") {
              return val;
            }
          });

        console.log(nameSplite);
        let paraMiters = `&update_struct[name_first]=${quote(nameSplite[0])}`;

        if (nameSplite.length == 2) {
          paraMiters = `&update_struct[name_first]=${quote(
            nameSplite[0]
          )}&update_struct[name_last]=${quote(nameSplite[1])}`;
        }
        if (nameSplite.length == 3) {
          paraMiters = `&update_struct[name_first]=${quote(
            nameSplite[0]
          )}&update_struct[name_middle]=${quote(
            nameSplite[1]
          )}&update_struct[name_last]=${quote(nameSplite[2])}`;
        }

        if (nameSplite.length > 3) {
          // let nameLength = nameSplite.length;
          let middleName = nameSplite.filter((hh, key) => {
            if (key != 0 && key != nameSplite.length - 1) {
              return hh;
            }
          });

          paraMiters = `&update_struct[name_first]=${quote(
            nameSplite[0]
          )}&update_struct[name_middle]=${quote(
            middleName.join(" ")
          )}&update_struct[name_last]=${quote(
            nameSplite[nameSplite.length - 1]
          )}`;
        }

        instance
          .get(
            `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${
              this.state.photoID
            }&alias=${quote(
              nameSplite[0]
            )}${paraMiters}&update_type=client_flag`
          )
          .then((res) => {
            console.log(res);

            console.log(this.state.alias, quote(nameSplite[0]));
            if (this.state.alias != quote(nameSplite[0])) {
              this.joinEventHandler(quote(nameSplite[0]));
            }
            this.setState({
              editName: false,
              alias: quote(nameSplite[0]),
            });

            // console.log(res);
            this.props.updateProfileHandler(quote(nameSplite[0]), "updateName");
          });
      } else {
        this.setState({
          editName: false,
        });
      }
    }
  };
  saveLinksHandler = () => {
    instance
      .get(
        `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}&update_type=client_flag&update_struct[link_instagram]=${this.state.instagram}&update_struct[link_facebook]=${this.state.facebook}&update_struct[link_twitter]=${this.state.twitter}&update_struct[link_soundcloud]=${this.state.soundcloud}`
      )
      .then((res) => {
        // console.log(res)

        this.setState({
          editLinks: false,
        });
      });
  };
  cancelLinkHandler = () => {
    this.setState({
      editLinks: false,
    });
  };

  numberHandler = (e, valueLength, minVal, maxVal) => {
    console.log(e.target.value);
    if (
      e.target.value === "" ||
      (this.state.regexp.test(e.target.value) &&
        e.target.value.length <= valueLength &&
        e.target.value > minVal &&
        e.target.value <= maxVal)
    ) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  cancelDobHandler = () => {
    this.setState({
      editDob: false,
    });
  };
  saveDobHandler = () => {
    const getOld = new Date().getFullYear() - this.state.year;

    var today = new Date();
    var birthDate = new Date(
      this.state.month + "/" + this.state.day + "/" + this.state.year
    );
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    // return age;
    instance
      .get(
        `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}&update_type=client_flag&update_struct[profile_age]=${getOld}&update_struct[birthdate_day]=${this.state.day}&update_struct[birthdate_month]=${this.state.month}&update_struct[birthdate_year]=${this.state.year}`
      )
      .then((res) => {
        console.log(res, "dob");

        this.setState({
          editDob: false,
          age: age,
        });
      });
  };
  uploadHandler = (e) => {
    e.preventDefault();
    const bmf = new BMF();
    let that = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileName = e.target.value.split("\\").pop();
    if (e.target.files[0]) {
      bmf.md5(file, (err, md5) => {
        this.setState({
          hash: md5,
        });
        let photo_id = localStorage.getItem("photo_id");
        let user_id = localStorage.getItem("user_id");
        let formData = new FormData();
        formData.append("avatar", file);
        formData.append("hash", md5);
        formData.append("uuid", "@" + photo_id);
        instance
          .post(`_photo_upload?uuid=@${photo_id}&hash=${md5}`, formData)
          .then((res) => {
            // console.log(res);
            instance
              .get(
                `@${photo_id}/_photolead_v2?value=${res.data}&group_id=@${photo_id}&user_id=${user_id}&photo_id=${photo_id}&ledby=${this.props.userDetail.alias}&photo_hash=${md5}&thumb_value=${res.data}&content_type=photo&caption=&approval_status=profile&os_type=iPhone`
              )
              .then((res) => {
                // console.log(res);
                this.joinEventHandler();
              });
          });
      });
      reader.onloadend = () => {
        that.setState({
          userImg: reader.result,
        });
        this.props.updateProfileHandler(reader.result, "updateImage");
        // localStorage.setItem('changeImage', reader.result)
      };
      reader.readAsDataURL(file);
    }
  };
  linkBlurHandler = (e, type) => {
    if (e.target.value !== "") {
      if (this.state.linkRegexp.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      } else {
        this.setState({
          [e.target.name]: type + e.target.value,
        });
      }
    }
  };
  joinEventHandler = (userName) => {
    instance
      .get(
        `${this.state.groupTable}/_joingroup?user_id=${
          this.state.userID
        }&photo_id=${this.state.photoID}&alias=${
          userName ? userName : this.state.alias
        }&group_type=current_group&group_id=${this.state.groupTable}`
      )
      .then((res) => {
        console.log(res, "joined");
      });

    instance
      .get(
        `${this.state.groupEvent}/_joingroup?user_id=${
          this.state.userID
        }&photo_id=${this.state.photoID}&alias=${
          userName ? userName : this.state.alias
        }&group_type=current_group&group_id=${this.state.groupEvent}`
      )
      .then((res) => {
        // console.log(res, 'joined')
      });
  };
  render() {
    return (
      <div
        style={{
          display: this.props.toggleHandler ? "block" : "none",
        }}
        className="edit-profile"
      >
        <div className="backDrop" onClick={this.props.click}></div>
        {this.props.userDetail ? (
          <div className="popup-box">
            <span className="btn-close" onClick={this.props.click}>
              <i className="ion-ios-close-empty"></i>
            </span>
            <div className="inner-group">
              {/* <div className="slider-box">
								<Slider {...this.settings}>
									{this.state.slideImages ? this.state.slideImages.map((image, key) => (
										<div key={key}>
											<div className="slide-box">
												<figure>
													<img src={baseURL + image.thumb_value} />
												</figure>
												<ul className="slide-options">
													<li><i className="ion-ios-heart-outline"></i><span>53 Reactions</span></li>
													<li><i className="ion-plus"></i></li>
													<li><i className="ion-trash-a"></i></li>
												</ul>
											</div>
										</div>
									)) : null}
								</Slider>
							</div> */}
              <div className="profile-detail">
                <div className="user-info">
                  <figure>
                    <img src={this.state.userImg} alt="user" />
                    <div className="upload-box">
                      <input
                        autoComplete="off"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.uploadHandler}
                      />
                      <label className="btn btn-oragne">
                        <i className="ion-edit" />
                      </label>
                    </div>
                  </figure>
                  <div className="detail-box">
                    <div className="name-group">
                      {this.state.editName ? (
                        ""
                      ) : (
                        <ul className="edit-btnList">
                          <li onClick={() => this.editHandler("Name")}>
                            <i className="ion-edit" />{" "}
                          </li>
                          <li onClick={this.removeHaldlar}>
                            <i className="ion-close" />{" "}
                          </li>
                        </ul>
                      )}
                      {this.state.editName ? (
                        <input
                          type="name"
                          autoComplete="off"
                          value={
                            this.state.name
                              ? unicodeToChar(this.state.name)
                              : this.state.name
                          }
                          onChange={this.inputNameHandler}
                          onBlur={this.nameBlurHandler}
                          name="name"
                        />
                      ) : (
                        <h2>
                          {this.state.name
                            ? unicodeToChar(this.state.name)
                            : this.state.name}
                        </h2>
                      )}
                      {this.state.showNameError ? (
                        <p className="field-error">
                          first name must be alphanumeric and [.,-,_] are
                          allowed but cannot use for first and last character
                        </p>
                      ) : (
                        ""
                      )}
                      {this.state.editName ? (
                        <ul className="save-btnList">
                          <li>
                            <button
                              type="button"
                              className="btn btn-oragne"
                              onClick={this.saveNameHandler}
                            >
                              Save changes
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  editName: false,
                                  name: this.props.userDetail.alias,
                                })
                              }
                              className="btn btn-gray"
                            >
                              Cancel
                            </button>
                          </li>
                        </ul>
                      ) : null}
                    </div>

                    <div className="date-group">
                      {this.state.editDob ? (
                        ""
                      ) : (
                        <ul className="edit-btnList">
                          <li onClick={() => this.editHandler("Dob")}>
                            <i className="ion-edit" />{" "}
                          </li>
                          <li onClick={this.removeHaldlar}>
                            <i className="ion-close" />{" "}
                          </li>
                        </ul>
                      )}

                      {this.state.editDob ? (
                        <div className="dob-fields">
                          <div className="form-group">
                            <label>birthdate</label>
                            <div className="field-group">
                              <div className="field">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="month"
                                  placeholder="MM"
                                  value={this.state.month}
                                  onChange={(e) =>
                                    this.numberHandler(e, 2, 0, 12)
                                  }
                                />
                              </div>
                              <div className="field">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="day"
                                  placeholder="DD"
                                  value={this.state.day}
                                  onChange={(e) =>
                                    this.numberHandler(e, 2, 0, 31)
                                  }
                                />
                              </div>
                              <div className="field">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="year"
                                  placeholder="YYYY"
                                  value={this.state.year}
                                  onChange={(e) =>
                                    this.numberHandler(e, 4, 0, 2010)
                                  }
                                />
                              </div>
                            </div>

                            <div className="check-group">
                              <input
                                autoComplete="off"
                                type="checkbox"
                                name="showDOB"
                                checked={this.state.showDOB}
                                onChange={this.checkHandler}
                              />
                              <label>Show my age on my profile</label>
                            </div>
                            <ul className="save-btnList">
                              <li>
                                <button
                                  type="button"
                                  className="btn btn-oragne"
                                  onClick={this.saveDobHandler}
                                >
                                  Save changes
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={this.cancelDobHandler}
                                  className="btn btn-gray"
                                >
                                  Cancel
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <ul>
                          <li>{this.state.age} years old</li>
                          <li>{this.state.birthdate_sign}</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div className="text-group">
                  <h6>A fact about me that surprises people</h6>
                  {this.state.editAbout ? null : (
                    <ul className="edit-btnList">
                      <li onClick={() => this.editHandler("About")}>
                        <i className="ion-edit" />{" "}
                      </li>
                      <li onClick={this.removeHaldlar}>
                        <i className="ion-close" />{" "}
                      </li>
                    </ul>
                  )}
                  {this.state.editAbout ? (
                    <textarea
                      name="About"
                      value={
                        this.state.About
                          ? unicodeToChar(this.state.About)
                          : this.state.About
                      }
                      onChange={this.inputHandler}
                    ></textarea>
                  ) : (
                    <p>
                      {this.state.About
                        ? unicodeToChar(this.state.About)
                        : this.state.About}
                    </p>
                  )}
                  {this.state.editAbout ? (
                    <ul className="save-btnList">
                      <li>
                        <button
                          type="button"
                          className="btn btn-oragne"
                          onClick={this.saveAboutHandler}
                        >
                          Save changes
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={this.aboutCancelHandler}
                          className="btn btn-gray"
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  ) : null}
                </div>
                <div className="text-group">
                  <h6>My favorite things to do are...</h6>
                  {this.state.editInterests ? null : (
                    <ul className="edit-btnList">
                      <li onClick={() => this.editHandler("Interests")}>
                        <i className="ion-edit" />{" "}
                      </li>
                      <li onClick={this.removeHaldlar}>
                        <i className="ion-close" />{" "}
                      </li>
                    </ul>
                  )}
                  {this.state.editInterests ? (
                    <textarea
                      name="Interests"
                      value={
                        this.state.Interests
                          ? unicodeToChar(this.state.Interests)
                          : this.state.Interests
                      }
                      onChange={this.inputHandler}
                    ></textarea>
                  ) : (
                    <p>
                      {this.state.Interests
                        ? unicodeToChar(this.state.Interests)
                        : this.state.Interests}
                    </p>
                  )}
                  {this.state.editInterests ? (
                    <ul className="save-btnList">
                      <li>
                        <button
                          type="button"
                          className="btn btn-oragne"
                          onClick={this.saveInterestsHandler}
                        >
                          Save changes
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={this.interestsCancelHandler}
                          className="btn btn-gray"
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  ) : null}
                </div>
                <div className="social-detail">
                  <h6>Links</h6>
                  {this.state.editLinks ? null : (
                    <ul className="edit-btnList">
                      <li onClick={() => this.editHandler("Links")}>
                        <i className="ion-edit" />{" "}
                      </li>
                      <li onClick={this.removeHaldlar}>
                        <i className="ion-close" />{" "}
                      </li>
                    </ul>
                  )}
                  {this.state.editLinks ? (
                    <div className="edit-links">
                      <form autoComplete="off">
                        <div className="form-group">
                          <label>Instagram</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="instagram"
                            className="form-control"
                            placeholder="https://www.instagram.com/wilddaveeeeeee"
                            onBlur={(e) =>
                              this.linkBlurHandler(
                                e,
                                "https://www.instagram.com/"
                              )
                            }
                            onChange={this.inputHandler}
                            value={this.state.instagram}
                          />
                        </div>
                        <div className="form-group">
                          <label>Facebook</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="facebook"
                            className="form-control"
                            placeholder="https://www.facebook.com/wilddaveeeeeee"
                            onBlur={(e) =>
                              this.linkBlurHandler(
                                e,
                                "https://www.facebook.com/"
                              )
                            }
                            onChange={this.inputHandler}
                            value={this.state.facebook}
                          />
                        </div>
                        <div className="form-group">
                          <label>Twitter</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="twitter"
                            className="form-control"
                            placeholder="https://www.twitter.com/wilddaveeeeeee"
                            onBlur={(e) =>
                              this.linkBlurHandler(e, "https://twitter.com/")
                            }
                            onChange={this.inputHandler}
                            value={this.state.twitter}
                          />
                        </div>
                        <div className="form-group">
                          <label>Soundcloud</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="soundcloud"
                            className="form-control"
                            placeholder="https://www.soundcloud.com/wilddaveeeeeee"
                            onBlur={(e) =>
                              this.linkBlurHandler(e, "https://soundcloud.com/")
                            }
                            onChange={this.inputHandler}
                            value={this.state.soundcloud}
                          />
                        </div>
                        <ul className="save-btnList">
                          <li>
                            <button
                              type="button"
                              className="btn btn-oragne"
                              onClick={this.saveLinksHandler}
                            >
                              Save changes
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={this.cancelLinkHandler}
                              className="btn btn-gray"
                            >
                              Cancel
                            </button>
                          </li>
                        </ul>
                      </form>
                    </div>
                  ) : (
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href={
                            this.props.userDetail.account_links.link_instagram
                          }
                        >
                          <figure>
                            <i
                              className="fa fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </figure>
                          <p>Instagram</p>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={
                            this.props.userDetail.account_links.link_facebook
                          }
                        >
                          <figure>
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </figure>
                          <p>Facebook</p>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={
                            this.props.userDetail.account_links.link_twitter
                          }
                        >
                          <figure>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </figure>
                          <p>Twitter</p>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={
                            this.props.userDetail.account_links.link_soundcloud
                          }
                        >
                          <figure>
                            <i
                              className="fa fa-soundcloud"
                              aria-hidden="true"
                            ></i>
                          </figure>
                          <p>Soundcloud</p>
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
                {this.state.UserEmail ? (
                  <div className="email-group">
                    <h6>Email (not displayed publically)</h6>
                    {this.state.editEmail ? (
                      <div className="input-change-group">
                        <input
                          type="name"
                          autoComplete="off"
                          value={this.state.UserEmail}
                          onChange={this.inputNameHandler}
                          onBlur={this.nameBlurHandler}
                          name="UserEmail"
                        />
                        <ul className="save-btnList">
                          <li>
                            <button type="button" className="btn btn-oragne">
                              Save changes
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({ editEmail: false })
                              }
                              className="btn btn-gray"
                            >
                              Cancel
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div className="text-group">
                        <p>{this.state.UserEmail}</p>
                        {this.props.onboardCompleted ? (
                          this.props.onboardCompleted.includes(
                            "email-confirmed"
                          ) ? (
                            <div className="status-box">
                              <span className="confirmed">✓ confirmed</span>
                            </div>
                          ) : null
                        ) : null}
                        {/* <button className="edit-btn" type="button" onClick={() => this.editHandler('Email')}><i className="ion-edit" /> </button> */}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileHandler: (value, valueType) =>
      dispatch(actions.editProfile(value, valueType)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
