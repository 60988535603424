import React, { memo, useState, useEffect } from "react";
import "./listView.scss";
import backIcon from "../../assets/images/back-icon-white.svg";
import messageIconNew from "../../assets/images/message-icon-new.png";
import closeIconNew from "../../assets/images/close-icon-new.png";
import laughingIcon from "../../assets/images/laughing.png";
import fireIcon from "../../assets/images/fire.png";
import facepalmIcon from "../../assets/images/facepalm.png";
import headExploding from "../../assets/images/head-exploding.png";
import SendButton from "../../assets/images/send.svg";
import ImgBig from "../../assets/images/dummy-image-big.jpg";
import { instance, baseURL } from "../../axios";
import avtarIcon from "../../assets/images/avtar.png";
import { quote, unicodeToChar } from "../../shared/utility";

const ListView = (props) => {
  let groupSettings = props.groupSettings ? props.groupSettings : {};
  let allUsersPhotoId = props.allUsersPhotoId ? props.allUsersPhotoId : {};
  let [feedCount, setFeedCount] = useState([20]);
  let [feedData, setFeedData] = useState([]);
  let [showPopup, setShowPopup] = useState(false);
  // let allFeeds = props.feedsDetail.data.all;
  useEffect(() => {
    let getStatup = localStorage.getItem("openListHelp");
    setShowPopup(getStatup);
    let getData = [...props.feedsDetail.data.all];
    setFeedData(getData.splice(0, feedCount));
  }, [props.feedsDetail, props.getAllFields, props.groupSettings]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log(bottom);
    if (bottom) {
      let getData = [...props.feedsDetail.data.all];
      setFeedCount(parseInt(feedCount) + 20);
      setFeedData(getData.splice(0, feedCount));
    }
  };

  const closeHandler = () => {
    localStorage.removeItem("openListHelp");
    setShowPopup(false);
  };

  return (
    <div
      className={
        props.toggle ? "feed-list-section active" : "feed-list-section"
      }
    >
      <div onClick={props.hidelistView} className="inner-header">
        <img src={backIcon} />
        <h2>FOMO stories</h2>
      </div>
      <div onScroll={handleScroll} className="box-listing">
        {feedData.length > 0
          ? feedData.map((data, key) => {
              let getreAll =
                props.feedsDetail.reaction.all.length != 0
                  ? props.feedsDetail.reaction.all[data.object_id]
                    ? props.feedsDetail.reaction.all[data.object_id]
                    : []
                  : [];

              let headExplodingReaction =
                getreAll.length != 0
                  ? getreAll.filter((value) => {
                      if (value.reaction == quote("🤯")) {
                        return value;
                      }
                    })
                  : [];

              let fireReaction =
                getreAll.length != 0
                  ? getreAll.filter((value) => {
                      if (value.reaction == quote("🔥")) {
                        return value;
                      }
                    })
                  : [];
              let facepalmReaction =
                getreAll.length != 0
                  ? getreAll.filter((value) => {
                      if (value.reaction == quote("🤦‍♂️")) {
                        return value;
                      }
                    })
                  : [];
              let laughingReaction =
                getreAll.length != 0
                  ? getreAll.filter((value) => {
                      if (value.reaction == quote("😂")) {
                        return value;
                      }
                    })
                  : [];

              return (
                <div key={key} className="main-box">
                  {groupSettings.rcv_ledby_on == "true" ||
                  (groupSettings.rcv_captions_on == "true" && data.caption) ? (
                    <div className="user-detail">
                      {groupSettings.rcv_ledby_on == "true" ? (
                        <h2>{data.alias}</h2>
                      ) : null}

                      <div className="inner-box">
                        {groupSettings.rcv_ledby_on == "true" ? (
                          <figure>
                            <img
                              src={
                                data
                                  ? allUsersPhotoId[data.photo_id]
                                    ? allUsersPhotoId[data.photo_id].thumb_value
                                      ? baseURL +
                                        allUsersPhotoId[
                                          data.photo_id
                                        ].thumb_value.split("..")[1]
                                      : avtarIcon
                                    : avtarIcon
                                  : avtarIcon
                              }
                              alt="user"
                            />
                          </figure>
                        ) : null}

                        {groupSettings.rcv_captions_on == "true" ? (
                          data.caption ? (
                            <p>{unicodeToChar(data.caption)}</p>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="image-box">
                    <figure>
                      {data.content_type == "video" ? (
                        <video
                          // ref="vidRef"
                          playsInline={true}
                          muted={true}
                          autoPlay={true}
                          style={{ width: "100%", height: "100%" }}
                          // onEnded={props.onVideoEnd}
                          poster={baseURL + data.thumb_value.split("..")[1]}
                        >
                          <source
                            src={baseURL + data.value.split("..")[1]}
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <img
                          src={baseURL + data.value.split("..")[1]}
                          alt="status"
                        />
                      )}
                    </figure>
                  </div>
                  <div className="mobile-box">
                    <button
                      type="botton"
                      onClick={() => props.openSendHandler(data)}
                      className="messsage-btn"
                    >
                      <img src={SendButton} /> message
                    </button>

                    <ul>
                      <li
                        className={
                          headExplodingReaction.find((obj) => {
                            if (obj.photo_id == props.myphotoID) {
                              return "active";
                            }
                          })
                            ? "active"
                            : ""
                        }
                        onClick={
                          props.getAllFields
                            ? props.getAllFields.reactions
                              ? () =>
                                  props.addReactionHandler(
                                    data.object_id,
                                    quote("🤯")
                                  )
                              : () =>
                                  props.welcomeStepHandler(
                                    7,
                                    "signup",
                                    "reactions"
                                  )
                            : ""
                        }
                      >
                        <img src={headExploding} />
                        <span>{headExplodingReaction.length}</span>
                      </li>
                      <li
                        className={
                          fireReaction.find((obj) => {
                            if (obj.photo_id == props.myphotoID) {
                              return "active";
                            }
                          })
                            ? "active"
                            : ""
                        }
                        onClick={
                          props.getAllFields
                            ? props.getAllFields.reactions
                              ? () =>
                                  props.addReactionHandler(
                                    data.object_id,
                                    quote("🔥")
                                  )
                              : () =>
                                  props.welcomeStepHandler(
                                    7,
                                    "signup",
                                    "reactions"
                                  )
                            : ""
                        }
                      >
                        <img src={fireIcon} />
                        <span>{fireReaction.length}</span>
                      </li>
                      <li
                        className={
                          facepalmReaction.find((obj) => {
                            if (obj.photo_id == props.myphotoID) {
                              return "active";
                            }
                          })
                            ? "active"
                            : ""
                        }
                        onClick={
                          props.getAllFields
                            ? props.getAllFields.reactions
                              ? () =>
                                  props.addReactionHandler(
                                    data.object_id,
                                    quote("🤦‍♂️")
                                  )
                              : () =>
                                  props.welcomeStepHandler(
                                    7,
                                    "signup",
                                    "reactions"
                                  )
                            : ""
                        }
                      >
                        <img src={facepalmIcon} />
                        <span>{facepalmReaction.length}</span>
                      </li>
                      <li
                        className={
                          laughingReaction.find((obj) => {
                            if (obj.photo_id == props.myphotoID) {
                              return "active";
                            }
                          })
                            ? "active"
                            : ""
                        }
                        onClick={
                          props.getAllFields
                            ? props.getAllFields.reactions
                              ? () =>
                                  props.addReactionHandler(
                                    data.object_id,
                                    quote("😂")
                                  )
                              : () =>
                                  props.welcomeStepHandler(
                                    7,
                                    "signup",
                                    "reactions"
                                  )
                            : ""
                        }
                      >
                        <img src={laughingIcon} />
                        <span>{laughingReaction.length}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {showPopup ? (
        <div className="make-popup">
          <button type="button" className="btn-close" onClick={closeHandler}>
            <img src={closeIconNew} />
          </button>
          <figure>
            <img src={messageIconNew} alt="message icon" />
          </figure>
          <h3>Make a friend!</h3>
          <p>Someone caught your eye? Send them a message to say hi!</p>
          <button type="button" className="btn btn-pink" onClick={closeHandler}>
            Cool, got it!
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default memo(ListView);
