import React, { Component } from 'react';
import CreateEventBox from '../createEventBox/createEventBox';
import './editEvent.scss';


class EditEvent extends Component {
	render() {
		return (
			<div className={this.props.showPopup ? "edit-event active" : "edit-event"}>
				<div className="box">
					<span className="btn-close" onClick={this.props.click}><i className="ion-ios-close-empty"></i></span>
					<CreateEventBox click={this.props.click} editMode={this.props.showPopup} groupDetail={this.props.groupDetail} />
				</div>

			</div>
		)
	}
}
export default EditEvent
