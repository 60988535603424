import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	chatDetail: '',


}
const addChatSuccess = (state, action) => {
	return updateObject(state, {
		chatDetail: action.payload
	})
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_CHAT:
			return addChatSuccess(state, action)
		default:
			return state;
	}

}


export default reducer
