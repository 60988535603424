import Pusher from "pusher-js";

let userID = "";
if (typeof window !== "undefined") {
  userID = localStorage.getItem("user_id");
}

export const pusher = new Pusher("9be934f12dde987ade92", {
  authTransport: "jsonp",
  authEndpoint: `https://app.fomospaces.com/_pusher/${userID}`,
});
let pusherOne = null;
let pusherTwo = null;
if (
  window.location.pathname.split("/")[1] != "_login" &&
  window.location.pathname.split("/")[3] != "_login"
) {
  pusherOne = pusher.subscribe(
    "private-" + window.location.pathname.split("/")[1]
  );
  pusherTwo = pusher.subscribe(
    "private-" + window.location.pathname.split("/")[3]
  );
}
export const channelOne = pusherOne;
export const channelTwo = pusherTwo;
export const privateChat = pusher.subscribe(
  "private-" + localStorage.getItem("user_id")
);
export const channelOneP = pusher.subscribe("NICKEVENT");
export const channelTwoP = pusher.subscribe("NICKTABLE");
