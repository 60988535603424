import React, { Component } from "react";
import "./enterCode.scss";
import nunitoImg from "../../assets/images/nunito.svg";
import axios from "axios";
import { instance } from "../../axios";

class enterCode extends Component {
  state = {
    inputValue: "",
    groupNameRegex: /^[A-Za-z0-9][A-Za-z0-9_.-]*[A-Za-z0-9]+$/,
    showError: "",
    btnDisable: true,
    errorStatus: false,
  };

  changeHandler = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
    if (e.target.value.trim() != "") {
      if (this.state.groupNameRegex.test(e.target.value.split(" ").join(""))) {
        this.setState({
          showError: null,
        });
      } else {
        this.setState({
          showError: "Invalid code. <br>Please check your code and try again",
        });
      }
      this.setState({
        btnDisable: false,
        // showError: "",
      });
    } else {
      this.setState({
        btnDisable: true,
      });
    }

    // if (e.target.value.trim()) {
    //   if (this.state.groupNameRegex.test(e.target.value)) {
    //     this.setState({
    //       showError: "",
    //     });
    //   } else {
    //     this.setState({
    //       showError: "Invalid group code",
    //     });
    //   }
    // }
  };

  blurHandler = (e) => {
    if (e.target.value.trim()) {
      // console.log(e.target.value.split(" ").join(""));
      if (this.state.groupNameRegex.test(e.target.value.split(" ").join(""))) {
        this.setState({
          showError: null,
        });
      } else {
        this.setState({
          showError: "Invalid code. <br>Please check your code and try again",
        });
      }
    }
  };

  submitHandler = (e) => {
    console.log(e.target);
    e.preventDefault();

    let getName = this.state.inputValue.split(" ").join("").toUpperCase();
    if (this.state.showError == null) {
      instance.get(`${getName}/_groupexists.json`).then((res) => {
        console.log(res);
        if (res.data.valid_response == "EXISTS") {
          window.location.pathname = getName;
        } else {
          this.setState({
            errorStatus: true,
            showError: "Invalid code.<br> Please check your code and try again",
          });
        }
      });
    }
  };

  render() {
    return (
      <div className="create-event enter-code">
        <div className="inner-header">
          <img src={nunitoImg} />
        </div>
        <h2>Enter code</h2>
        <form onSubmit={this.submitHandler}>
          <div className="form-group">
            <input
              type="text"
              className={
                this.state.errorStatus ? "form-control error" : "form-control"
              }
              value={this.state.inputValue}
              onChange={this.changeHandler}
              onBlur={this.blurHandler}
            />
            {this.state.showError ? (
              <div className="error-box">
                <p
                  dangerouslySetInnerHTML={{ __html: this.state.showError }}
                ></p>
              </div>
            ) : null}
          </div>
          <div className="btn-box">
            <button
              type="submit"
              className="btn btn-pink"
              disabled={this.state.btnDisable}
              // onClick={this.state.submitHandler}
            >
              JOIN
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default enterCode;
