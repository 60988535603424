import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	updateImage: '',
	updateName: '',


}
const editProfileSuccess = (state, action) => {
	return updateObject(state, {
		[action.payload.valueType]: action.payload.value
	})
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CHANGE_PROFILE_PIC:
			return editProfileSuccess(state, action)
		default:
			return state;
	}

}


export default reducer
