import React, { Component } from "react";
import "./whoHere.scss";
import { instance, baseURL } from "../../axios";
import avtarIcon from "../../assets/images/avtar.png";
import ViewProfile from "../viewProfile/viewProfile";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { channelOne, channelTwo } from "../../pusher";
class whoHere extends Component {
  state = {
    groupUsers: [],
    viewDetail: false,
    profileDetail: "",
  };

  componentDidMount() {
    this.setState({
      guestUser: JSON.parse(localStorage.getItem("guestUser")),
    });
    let userID = localStorage.getItem("user_id");
    let photoID = localStorage.getItem("photo_id");
    let groupTable = window.location.pathname.split("/")[3]
      ? window.location.pathname.split("/")[3].toUpperCase()
      : window.location.pathname.split("/")[3];
    let groupEvent = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1].toUpperCase()
      : window.location.pathname.split("/")[1];
    this.setState({
      userID: userID,
      photoID: photoID,
      groupTable: groupTable,
      groupEvent: groupEvent,
    });
    setTimeout(() => {
      instance
        .get(
          `${groupEvent}/_groupuserlist.json?user_id=${userID}&photo_id=${photoID}`
        )
        .then((res) => {
          this.setState({
            groupUsers: res.data.results,
          });
        });
    }, 2000);

    channelOne.bind_global((event, data) => {
      console.log(event, data);
      if (data.type == "join-group") {
        data.profile_info = {};
        data.profile_info.thumb_value = data.thumb_value;
        let userss = [...this.state.groupUsers];
        let alreadyUser = "";
        alreadyUser = userss.find((user) => {
          if (user.photo_id == data.photo_id) {
            return user;
          }
        });
        if (alreadyUser) {
          userss.splice(userss.indexOf(alreadyUser), 1);
        }
        userss.unshift(data);
        this.setState({
          groupUsers: userss,
        });
      }
    });
  }
  showPopup = (user = null) => {
    if (user) {
      instance
        .get(
          `_fomomsg_getprofile?user_id=${this.state.userID}&photo_id=${user.photo_id}&notification_flag=true`
        )
        .then((res) => {
          this.setState((prevState) => ({
            profileDetail: res.data,
            viewDetail: true,
          }));
        });
    } else {
      this.setState({
        viewDetail: false,
      });
    }
  };

  render() {
    return (
      <div className="whoHere-box">
        <div className="main-box">
          <ViewProfile
            userDetail={this.props.userDetail}
            toggleHandler={this.state.viewDetail}
            popupDetail={this.state.profileDetail}
            click={this.showPopup}
          />
          <h3>Who’s here</h3>
          <div className="user-listing flex">
            {this.state.groupUsers
              ? this.state.groupUsers.map((user, key) => (
                  <div
                    key={key}
                    className="colmn-box"
                    onClick={() => this.showPopup(user)}
                  >
                    <figure>
                      <img
                        src={
                          user.profile_info.thumb_value
                            ? baseURL +
                              user.profile_info.thumb_value.split("..")[1]
                            : avtarIcon
                        }
                      />
                    </figure>
                    <p>{user.alias}</p>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    stepHandler: (step) => dispatch(actions.stepHandler(step)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(whoHere);
