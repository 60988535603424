import React, { Component } from "react";
import "./welcomePopup.scss";
import avatarIcon from "../../assets/images/avatar.png";
import deerIcon from "../../assets/images/deer-1.png";
import { baseURL, instance } from "../../axios";
import { checkValidity, quote, unicodeToChar } from "../../shared/utility";
import Loading from "../../components/loading/loading";
import avtarIcon from "../../assets/images/camera-icon.png";
import BMF from "browser-md5-file";
import { connect } from "react-redux";
import avtarIconNew from "../../assets/images/avtar.png";
import * as actions from "../../store/actions";

class welcomePopup extends Component {
  state = {
    inviteBy: "",
    inviteByAlise: "",
    // step: 'account_links',
    email: "",
    registerEmail: "",
    registerEmailError: null,
    registerName: "",
    registerNameError: "",
    emailError: "",
    loading: false,
    formMessage: "",
    success: null,
    password: "",
    step: 1,
    regexp: /^[0-9\b]+$/,
    linkRegexp:
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
    letters: /^[A-Z a-z]+$/,
    month: "",
    day: "",
    year: "",
    monthError: "",
    dayError: "",
    yearError: "",
    aboutMe: "",
    aboutMeError: "",
    instagram: "",
    facebook: "",
    twitter: "",
    soundcloud: "",
    instagramError: "",
    facebookError: "",
    twitterError: "",
    soundcloudError: "",
    guestName: "",
    showImageError: true,
    imageUpload: true,
    guestName: "",
    guestNameError: null,
    onBoardStatus: false,
    guestLogin: true,
    guestNeedMore: false,
    emailRegexp: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  };

  validateCheck = [
    {
      field: "email",
      rules: [
        { type: "isEmail", message: "Enter a valid email address." },
        { type: "required", message: "This field is required" },
      ],
    },
    {
      field: "registerEmail",
      rules: [
        { type: "isEmail", message: "Enter a valid email address." },
        { type: "required", message: "This field is required" },
      ],
    },
    {
      field: "registerName",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "password",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "month",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "day",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "year",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "aboutMe",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "instagram",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "facebook",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "twitter",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "soundcloud",
      rules: [{ type: "required", message: "This field is required" }],
    },
    {
      field: "guestName",
      rules: [{ type: "required", message: "This field is required" }],
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.step !== this.props.step) {
      this.setState({
        step: this.props.step,
      });
    }
    if (prevProps.stepType !== this.props.stepType) {
      let getUser_id = localStorage.getItem("user_id");
      let getPhoto_id = localStorage.getItem("photo_id");
      this.setState({
        userID: getUser_id,
        photoID: getPhoto_id,
        registerName:
          this.props.step == 7 ? localStorage.getItem("guestName") : "",
      });

      if (this.props.stepType == "signup") {
        instance
          .get(
            `_getfomoonboard.json?user_id=${getUser_id}&photo_id=${getPhoto_id}`
          )
          .then((res3) => {
            instance
              .get(
                `_fomomsg_getprofile?user_id=${getUser_id}&photo_id=${getPhoto_id}&notification_flag=true`
              )
              .then((res) => {
                console.log(res);
                this.setState({
                  uploadImage: res.data.thumb_value
                    ? baseURL + res.data.thumb_value.split("..")[1]
                    : "",
                  // imageUpload: res.data.thumb_value ? true : false,
                  aboutMe: res.data.profile_desc ? res.data.profile_desc : "",
                  aboutMeError: res.data.profile_desc ? null : "",
                  // registerEmail: localStorage.getItem('user_registerEmail') ? localStorage.getItem('user_registerEmail') : '',
                  // registerEmailError: localStorage.getItem('user_registerEmail') ? null : '',
                  alias: res.data.alias,
                  registerNameCopy: res.data.profile_name.name_first
                    ? res.data.profile_name.name_first +
                      " " +
                      res.data.profile_name.name_middle +
                      " " +
                      res.data.profile_name.name_last
                    : res.data.alias,
                  registerName: res.data.profile_name.name_first
                    ? res.data.profile_name.name_first +
                      " " +
                      res.data.profile_name.name_middle +
                      " " +
                      res.data.profile_name.name_last
                    : res.data.alias,
                  registerNameError: res.data.alias ? null : "",
                  month:
                    res.data.birthdate.birthdate_month == 0
                      ? ""
                      : res.data.birthdate.birthdate_month,
                  monthError:
                    res.data.birthdate.birthdate_month == 0 ? "" : null,
                  day:
                    res.data.birthdate.birthdate_day == 0
                      ? ""
                      : res.data.birthdate.birthdate_day,
                  dayError: res.data.birthdate.birthdate_day == 0 ? "" : null,
                  year:
                    res.data.birthdate.birthdate_year == 0
                      ? ""
                      : res.data.birthdate.birthdate_year,
                  yearError: res.data.birthdate.birthdate_year == 0 ? "" : null,
                  instagram: res.data.account_links.link_instagram,
                  instagramError: res.data.account_links.link_instagram
                    ? null
                    : "",
                  facebook: res.data.account_links.link_facebook,
                  facebookError: res.data.account_links.link_facebook
                    ? null
                    : "",
                  twitter: res.data.account_links.link_twitter,
                  twitterError: res.data.account_links.link_twitter ? null : "",
                  soundcloud: res.data.account_links.link_soundcloud,
                  soundcloudError: res.data.account_links.link_soundcloud
                    ? null
                    : "",
                });
              });
            instance
              .get(
                `_getprofile.json?user_id=${getUser_id}&photo_id=${getPhoto_id}`
              )
              .then((res) => {
                console.log(res, "email");
                this.setState({
                  registerEmail: res.data.user_email ? res.data.user_email : "",
                  registerEmailError: res.data.user_email ? null : "",
                });
                localStorage.setItem("user_registerEmail", res.data.user_email);
              });

            let steps = [];
            res3.data.onboard_dic.profile.order.map((order) =>
              steps.push(Object.keys(order))
            );
            let filterSteps = [];
            steps.map((step) => filterSteps.push(step[0]));
            if (this.props.step == 7) {
              filterSteps.splice("alias", 1);
            }

            if (this.props.dataType) {
              let getCompletedFields = res3.data.onboard_completed_dic.profile;
              let getOrders =
                res3.data.onboard_dic.profile.actions[this.props.dataType];
              // let confirmFields = getCompletedFields.map(field => {
              // 	getOrders.splice(getOrders.indexOf(field), 1)
              // })
              let difference = getOrders.filter(
                (x) => getCompletedFields.indexOf(x) === -1
              );

              let ordersSteps = [...res3.data.onboard_dic.profile.order];

              let creata = [];
              ordersSteps.map((steps) => {
                difference.map((need) => {
                  if (Object.keys(steps)[0] == need) {
                    steps[Object.keys(steps)[0]] = "required";
                    creata.push(Object.keys(steps)[0]);
                  }
                  if (typeof steps[Object.keys(steps)[0]] == "object") {
                    steps[Object.keys(steps)[0]].map((childStep) => {
                      if (Object.keys(childStep)[0] == need) {
                        childStep[Object.keys(childStep)[0]] = "required";

                        // creata.splice(Object.keys(childStep)[0], 1);
                        creata.push(Object.keys(steps)[0]);
                      }
                    });
                  }
                });
              });

              function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
              }
              var unique = creata.filter(onlyUnique);

              this.setState({
                // step: 2,
                success: false,
                registerEmail: this.state.email,
                stepOrder: unique,
                step: unique[0],
                stepInfo: res3.data.onboard_dic.profile.order,
                guestProggess: false,
                actionMode: true,
              });
            } else {
              this.setState({
                // step: 2,
                success: false,
                registerEmail: this.state.email,
                stepOrder: filterSteps,
                step: filterSteps[0],
                stepInfo: res3.data.onboard_dic.profile.order,
                guestProggess: false,
                actionMode: true,
              });
            }
          });
      }
      if (this.props.stepType == "guestNeeds") {
        instance
          .get(
            `_getfomoonboard.json?user_id=${getUser_id}&photo_id=${getPhoto_id}`
          )
          .then((response) => {
            var completedStep = response.data.onboard_completed_dic.profile;
            let requiredDetail = response.data.onboard_dic.profile.guest;

            var difference = requiredDetail.filter(
              (x) => completedStep.indexOf(x) === -1
            );
            let ordersSteps = [...response.data.onboard_dic.profile.order];

            let creata = [];

            ordersSteps.map((steps) => {
              difference.map((need) => {
                if (Object.keys(steps)[0] == need) {
                  steps[Object.keys(steps)[0]] = "required";
                  creata.push(Object.keys(steps)[0]);
                }
                if (typeof steps[Object.keys(steps)[0]] == "object") {
                  steps[Object.keys(steps)[0]].map((childStep) => {
                    if (Object.keys(childStep)[0] == need) {
                      childStep[Object.keys(childStep)[0]] = "required";
                      // childStep
                      // creata.splice(Object.keys(childStep)[0], 1);
                      creata.push(Object.keys(steps)[0]);
                    }
                  });
                }
              });
            });

            function onlyUnique(value, index, self) {
              return self.indexOf(value) === index;
            }
            var unique = creata.filter(onlyUnique);

            this.setState({
              guestNeedMore: true,
              stepOrder: unique,
              stepInfo: ordersSteps,
              step: unique[0],
              guestProggess: true,
            });
          });
      }
    }
  }
  componentDidMount() {
    let eventID = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1].toUpperCase()
      : window.location.pathname.split("/")[1];
    let groupID = window.location.pathname.split("/")[3]
      ? window.location.pathname.split("/")[3].toUpperCase()
      : window.location.pathname.split("/")[3];
    let mapID = window.location.pathname.split("/")[4]
      ? window.location.pathname.split("/")[4]
      : "";
    this.setState({
      groupID: groupID,
      eventID: eventID,
      mapID: mapID,
    });
    let inviteId = window.location.pathname.split("/")[2];
    instance
      .get(`_fomomsg_getprofile?invited_by_id=${inviteId}`)
      .then((res) => {
        if (res.data.valid_response == "VALID") {
          let userID = localStorage.getItem("user_id");
          let photoID = localStorage.getItem("photo_id");
          instance
            .get(
              `${eventID}/_groupstatus.json?userid=${
                userID ? userID : null
              }&photoid=${photoID ? photoID : null}`
            )
            .then((respo) => {
              console.log(res);
              this.setState({
                inviteBy: res.data.profile_name,
                inviteByAlise: res.data.alias,
                eventName: respo.data.group_name,
                invitedByPic: res.data.thumb_value,
              });
            });
        }
      });

    instance
      .get(
        `${eventID}/_groupstatus.json?userid=guest-user-id&photoid=guest-photo-id`
      )
      .then((respo) => {
        this.setState({
          eventName: respo.data.group_name,
        });
      });

    instance.get(`_getguestuser.json`).then((res) => {
      this.setState({
        onBoardStatus: false,
        guestName: res.data.alias,
        rendomImageDetails: res.data.avatar_content,
      });
      instance.get(`_getfomoonboard.json`).then((response) => {
        let getAlias = response.data.onboard_dic.profile.guest.find(
          (fields) => {
            if (fields == "alias") {
              return fields;
            }
          }
        );
        let getGuestneed = response.data.onboard_dic.profile.guest;
        if (getAlias) {
          this.setState({
            onBoardStatus: true,
            guestName: "",
            guestNameError: "",
          });

          getGuestneed.splice(getGuestneed.indexOf(getAlias), 1);
        }

        let ordersSteps = [...response.data.onboard_dic.profile.order];
        let creata = [];
        ordersSteps.map((steps) => {
          getGuestneed.map((need) => {
            if (Object.keys(steps)[0] == need) {
              steps[Object.keys(steps)[0]] = "required";
              creata.push(Object.keys(steps)[0]);
            }
            if (typeof steps[Object.keys(steps)[0]] == "object") {
              steps[Object.keys(steps)[0]].map((childStep) => {
                if (Object.keys(childStep)[0] == need) {
                  childStep[Object.keys(childStep)[0]] = "required";

                  // creata.splice(Object.keys(childStep)[0], 1);
                  creata.push(Object.keys(steps)[0]);
                }
              });
            }
          });
        });
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        var unique = creata.filter(onlyUnique);
        if (getGuestneed.length > 0) {
          this.setState({
            guestNeedMore: true,
            stepOrder: unique,
            stepInfo: ordersSteps,
            guestProggess: true,
          });
        }
      });
    });
  }
  checkField = (...para) => {
    let that = this;

    this.validateCheck.forEach((field) => {
      let value = that.state[field["field"]];
      let isvalid = checkValidity(value, field["rules"]);
      para.map((test) => {
        if (isvalid != null) {
          if (field["field"] == test) {
            this.setState({
              [`${test}Error`]: isvalid.error,
            });
          }
        }
      });
    });
  };

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: null,
    });
  };
  emailHandler = (e) => {
    // if (e.target.value.test(e.target.value)) {

    // }
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: null,
    });
    // this.checkField(e.target.name)
  };

  blurHendler = (e) => {
    this.checkField(e.target.name);
  };

  directBlurHendler = (e, type) => {
    this.state.stepInfo.map((step) => {
      if (step[type]) {
        if (step[type] == "required") {
          // this.checkField(detailType);
          this.checkField(e.target.name);
        }
      }
    });

    if (type == "alias") {
      let nameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-_.]*[a-zA-Z0-9])?$/;
      let fullName = [""];
      fullName = e.target.value
        .trim()
        .split(" ")
        .filter((val) => {
          if (val != "") {
            return val;
          }
        });
      if (fullName[0]) {
        if (nameRegex.test(fullName[0]) == false) {
          this.setState({
            registerNameError:
              "first name must be alphanumeric and [.,-,_] are allowed but cannot use for first and last character",
          });
        } else {
          this.setState({
            registerNameError: null,
          });
        }
      }
    }
  };

  childrenBlurHandler = (e, type, childType) => {
    this.state.stepInfo.map((step) => {
      if (step[type]) {
        step[type].map((childBlur) => {
          if (childBlur[childType]) {
            if (childBlur[childType] == "required") {
              // this.checkField(detailType);
              this.checkField(e.target.name);
            }
          }
        });
      }
    });
  };

  numberHandler = (e, valueLength, minVal, maxVal) => {
    if (
      e.target.value === "" ||
      (this.state.regexp.test(e.target.value) &&
        e.target.value.length <= valueLength &&
        e.target.value > minVal &&
        e.target.value <= maxVal)
    ) {
      this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: null,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    this.checkField("email");

    if (
      this.state.emailError == null &&
      this.state.emailRegexp.test(this.state.email)
    ) {
      this.setState({
        loading: true,
      });
      instance
        .get(
          `_magiclink.json?user_email=${this.state.email}&dest_url=${window.location.href}`
        )
        .then((res) => {
          this.setState({
            loading: false,
            formMessage: "",
          });
          localStorage.setItem("guestUser", false);
          if (res.data.valid_response === "VALID") {
            this.setState({
              formMessage: "We sent a magic link to your email",
              success: true,
            });
          }
          if (res.data.valid_response === "INVALID") {
            if (this.state.step == 1) {
              localStorage.setItem("not_email", this.state.email);
              instance
                .get(`_generateuuids?idstr=FRJS&alias=MyName`)
                .then((response) => {
                  localStorage.setItem("tempUser_id", response.data.user_id);
                  localStorage.setItem("tempPhoto_id", response.data.photo_id);
                  this.setState({
                    photoID: response.data.photo_id,
                    userID: response.data.user_id,
                  });

                  instance
                    .get(
                      `@${response.data.photo_id}/_photolead_v2?value=${this.state.rendomImageDetails.value}&group_id=@${response.data.photo_id}&user_id=${response.data.user_id}&photo_id=${response.data.photo_id}&ledby=myName)}&photo_hash=${this.state.rendomImageDetails.photo_hash}&thumb_value=${this.state.rendomImageDetails.thumb_value}&content_type=photo&caption=&approval_status=profile&os_type=iPhone&onboard_flag=false`
                    )
                    .then((respoPhoto) => {
                      console.log(respoPhoto);
                    });

                  instance
                    .get(
                      `_getfomoonboard.json?user_id=${response.data.user_id}&photo_id=${response.data.photo_id}`
                    )
                    .then((res3) => {
                      let steps = [];
                      res3.data.onboard_dic.profile.order.map((order) =>
                        steps.push(Object.keys(order))
                      );
                      let filterSteps = [];
                      steps.map((step) => filterSteps.push(step[0]));
                      // let stepOrder = filterSteps.filter(step => {
                      // 	if (step != 'email') {
                      // 		return step
                      // 	}
                      // })

                      this.setState({
                        // step: 2,

                        success: false,
                        registerEmail: this.state.email,
                        stepOrder: filterSteps,
                        step: filterSteps[0],
                        stepInfo: res3.data.onboard_dic.profile.order,
                      });
                    });
                });
            } else {
              console.log(res);
              this.setState({
                success: false,
                formMessage: "Please type a valid Email Id",
              });
            }
          }
        });
    }
  };

  registerSubmitHandler = (e) => {
    e.preventDefault();

    let checkStatus = this.state["registerEmailError"];
    this.state.stepInfo.map((step) => {
      if (step.email) {
        if (step.email == "required") {
          this.checkField("registerEmail");
        }

        if (step.email != "required") {
          checkStatus = null;
        }
      }
    });

    if (
      checkStatus == null &&
      this.state.emailRegexp.test(this.state.registerEmail)
    ) {
      this.setState({
        loading: true,
        registerformError: "",
      });
      if (
        this.state.registerEmail != localStorage.getItem("user_registerEmail")
      ) {
        instance
          .get(
            `_updateprofile.json?user_id=${this.state.userID}&photo_id=${this.state.photoID}&user_email=${this.state.registerEmail}&onboard_flag=true`
          )
          .then((res2) => {
            if (res2.data.valid_response === "VALID") {
              localStorage.setItem("photo_id", this.state.photoID);
              localStorage.setItem(
                "user_registerEmail",
                this.state.registerEmail
              );
              localStorage.setItem("user_id", this.state.userID);
              localStorage.setItem("guestUser", false);

              this.setState({
                success: true,
                loading: false,
              });
              this.nextHandler(this.state.step);
            }

            if (res2.data.valid_response === "EXISTS") {
              this.setState({
                registerformError: "This is email already exists",
                loading: false,
              });
            }
          });
      } else {
        this.nextHandler(this.state.step);
        this.setState({
          loading: false,
        });
      }
    }
  };

  saveDetailHandler = (e, detailType) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    let checkStatus = this.state[detailType + "Error"];
    this.state.stepInfo.map((step) => {
      if (step.alias) {
        if (step.alias == "required") {
          this.checkField(detailType);
        }

        if (step.alias != "required") {
          checkStatus = null;
        }
      }
    });
    if (checkStatus == null) {
      // registerName
      if (this.state.registerName != this.state.registerNameCopy) {
        let nameSplite = this.state.registerName
          .trim()
          .split(" ")
          .filter((val) => {
            if (val != "") {
              return val;
            }
          });
        console.log(nameSplite);
        let paraMiters = `&update_struct[name_first]=${quote(nameSplite[0])}`;

        if (nameSplite.length == 2) {
          paraMiters = `&update_struct[name_first]=${quote(
            nameSplite[0]
          )}&update_struct[name_last]=${quote(nameSplite[1])}`;
        }
        if (nameSplite.length == 3) {
          paraMiters = `&update_struct[name_first]=${quote(
            nameSplite[0]
          )}&update_struct[name_middle]=${quote(
            nameSplite[1]
          )}&update_struct[name_last]=${quote(nameSplite[2])}`;
        }

        if (nameSplite.length > 3) {
          // let nameLength = nameSplite.length;
          let middleName = nameSplite.filter((hh, key) => {
            if (key != 0 && key != nameSplite.length - 1) {
              return hh;
            }
          });

          paraMiters = `&update_struct[name_first]=${quote(
            nameSplite[0]
          )}&update_struct[name_middle]=${quote(
            middleName.join(" ")
          )}&update_struct[name_last]=${quote(
            nameSplite[nameSplite.length - 1]
          )}`;
        }

        instance
          .get(
            `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${
              this.state.photoID
            }&alias=${quote(nameSplite[0])}${paraMiters}&onboard_flag=true`
          )
          .then((res) => {
            console.log(res);
            this.setState({
              loading: false,
            });

            if (res.data.valid_response === "VALID") {
              this.setState({
                success: true,
              });
              this.nextHandler(this.state.step);
              localStorage.removeItem("tempPhoto_id");
              localStorage.removeItem("tempUser_id");
              if (this.props.step == 8) {
                this.props.updateProfileHandler(
                  quote(nameSplite[0]),
                  "updateName"
                );
                console.log(this.state.alias, quote(nameSplite[0]));
                if (this.state.alias != quote(nameSplite[0])) {
                  this.joinEventHandler(quote(nameSplite[0]));
                  this.setState({
                    alias: quote(nameSplite[0]),
                  });
                }
              }
            }
          });
      } else {
        this.setState({
          loading: false,
        });
        this.nextHandler(this.state.step);
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  joinEventHandler = (userName) => {
    let groupTable = window.location.pathname.split("/")[3]
      ? window.location.pathname.split("/")[3].toUpperCase()
      : window.location.pathname.split("/")[3];
    let groupEvent = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1].toUpperCase()
      : window.location.pathname.split("/")[1];
    instance
      .get(
        `${groupTable}/_joingroup?user_id=${this.state.userID}&photo_id=${
          this.state.photoID
        }&alias=${
          userName ? userName : this.state.alias
        }&group_type=current_group&group_id=${groupTable}`
      )
      .then((res) => {
        console.log(res, "joined");
      });

    instance
      .get(
        `${groupEvent}/_joingroup?user_id=${this.state.userID}&photo_id=${
          this.state.photoID
        }&alias=${
          userName ? userName : this.state.alias
        }&group_type=current_group&group_id=${groupEvent}`
      )
      .then((res) => {
        // console.log(res, 'joined')
      });
  };

  saveDobHandler = (e) => {
    e.preventDefault();
    var today = new Date();
    var birthDate = new Date(
      this.state.month + "/" + this.state.day + "/" + this.state.year
    );
    var age = birthDate.getFullYear()
      ? today.getFullYear() - birthDate.getFullYear()
      : "";
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }

    let checkStatusOne = this.state.dayError;
    let checkStatusTwo = this.state.monthError;
    let checkStatusThree = this.state.yearError;
    this.state.stepInfo.map((step) => {
      if (step.birthdate) {
        step.birthdate.map((childType) => {
          if (childType.birthdate_year == "required") {
            this.checkField("year");
            if (this.state.yearError != null) {
              checkStatusThree = "";
            }
          }
          if (childType.birthdate_month == "required") {
            this.checkField("month");
            if (this.state.monthError != null) {
              checkStatusTwo = "";
            }
          }
          if (childType.birthdate_day == "required") {
            this.checkField("day");
            if (this.state.dayError != null) {
              checkStatusOne = "";
            }
          }

          if (childType.birthdate_year == "optional") {
            checkStatusThree = null;
          }
          if (childType.birthdate_month == "optional") {
            checkStatusTwo = null;
          }
          if (childType.birthdate_day == "optional") {
            checkStatusOne = null;
          }
        });
      }
    });

    if (
      checkStatusOne == null &&
      checkStatusTwo == null &&
      checkStatusThree == null
    ) {
      this.setState({
        loading: true,
      });
      let paraMiters = "";
      if (this.state.day) {
        paraMiters += `&update_struct[birthdate_day]=${this.state.day}`;
      }
      if (this.state.month) {
        paraMiters += `&update_struct[birthdate_month]=${this.state.month}`;
      }
      if (this.state.year) {
        paraMiters += `&update_struct[birthdate_year]=${this.state.year}`;
      }
      if (this.state.day && this.state.month && this.state.year) {
        paraMiters += `&update_struct[profile_age]=${
          age.toString() ? age.toString() : ""
        }`;
      }
      instance
        .get(
          `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}${paraMiters}&onboard_flag=true`
        )
        .then((res) => {
          this.setState({
            loading: false,
          });
          this.nextHandler(this.state.step);
        });
    }
  };

  saveBioHandler = (e) => {
    e.preventDefault();
    let checkStatus = this.state.aboutMeError;
    this.state.stepInfo.map((step) => {
      if (step.profile_desc) {
        if (step.profile_desc == "required") {
          this.checkField("aboutMe");
          if (this.state.aboutMeError != null) {
            checkStatus = "";
          }
        }

        if (step.profile_desc != "required") {
          checkStatus = null;
        }
      }
    });
    if (checkStatus == null) {
      let paraMiters = "";
      if (this.state.aboutMe) {
        paraMiters = `&update_struct[profile_desc]=${this.state.aboutMe.trim()}`;
      }
      instance
        .get(
          `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}${paraMiters}&onboard_flag=true`
        )
        .then((res) => {
          this.setState({
            loading: false,
          });
          this.nextHandler(this.state.step);
        });
    }
  };

  linkBlurHandler = (e, type) => {
    let linkRegexp =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

    if (this.state[e.target.name].trim()) {
      if (linkRegexp.test(e.target.value)) {
      } else {
        this.setState({
          [e.target.name]: type + e.target.value,
          [e.target.name + "Error"]: null,
        });
      }
    }
  };
  linkHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
      [e.target.name + "Error"]: null,
    });
  };
  saveLinkHandler = (e) => {
    e.preventDefault();

    let checkStatusOne = this.state.instagramError;
    let checkStatusTwo = this.state.facebookError;
    let checkStatusThree = this.state.twitterError;
    let checkStatusFour = this.state.soundcloudError;
    this.state.stepInfo.map((step) => {
      if (step.account_links) {
        step.account_links.map((childType) => {
          if (childType.link_instagram == "required") {
            this.checkField("instagram");
            if (this.state.instagramError != null) {
              checkStatusOne = "";
            }
          }
          if (childType.link_facebook == "required") {
            this.checkField("facebook");
            if (this.state.facebookError != null) {
              checkStatusTwo = "";
            }
          }
          if (childType.link_twitter == "required") {
            this.checkField("twitter");
            if (this.state.twitterError != null) {
              checkStatusThree = "";
            }
          }
          if (childType.link_soundcloud == "required") {
            this.checkField("soundcloud");
            if (this.state.soundcloudError != null) {
              checkStatusFour = "";
            }
          }
          if (childType.link_instagram == "optional") {
            checkStatusOne = null;
          }
          if (childType.link_facebook == "optional") {
            checkStatusTwo = null;
          }
          if (childType.link_twitter == "optional") {
            checkStatusThree = null;
          }
          if (childType.link_soundcloud == "optional") {
            checkStatusFour = null;
          }
        });
      }
    });
    if (
      checkStatusOne == null &&
      checkStatusTwo == null &&
      checkStatusThree == null &&
      checkStatusFour == null
    ) {
      this.setState({
        loading: true,
      });
      let paraMiters = "";
      if (this.state.instagram) {
        paraMiters = `&update_struct[link_instagram]=${this.state.instagram}`;
      }
      if (this.state.facebook) {
        paraMiters += `&update_struct[link_facebook]=${this.state.facebook}`;
      }
      if (this.state.twitter) {
        paraMiters += `&update_struct[link_twitter]=${this.state.twitter}`;
      }
      if (this.state.soundcloud) {
        paraMiters += `&update_struct[link_soundcloud]=${this.state.soundcloud}`;
      }
      instance
        .get(
          `_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}${paraMiters}&onboard_flag=true`
        )
        .then((res) => {
          this.setState({
            loading: false,
          });
          this.nextHandler(this.state.step);
        });
    }
  };

  uploadHandler = (e) => {
    e.preventDefault();
    const bmf = new BMF();
    let that = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    // console.log(e.target.files[0])
    if (e.target.files[0]) {
      bmf.md5(file, (err, md5) => {
        this.setState({
          hash: md5,
        });
        let photo_id = this.state.photoID;
        let user_id = this.state.userID;
        let formData = new FormData();
        formData.append("avatar", file);
        formData.append("hash", md5);
        formData.append("uuid", "@" + photo_id);
        instance
          .post(`_photo_upload?uuid=@${photo_id}&hash=${md5}`, formData)
          .then((res) => {
            let splitRes = res.data.split("/");
            let fileName = splitRes.pop();
            let joinSplit = splitRes.join("/");
            let nameSplite = this.state.registerName
              .trim()
              .split(" ")
              .filter((val) => {
                if (val != "") {
                  return val;
                }
              });
            instance
              .get(
                `@${photo_id}/_photolead_v2?value=${
                  res.data
                }&group_id=@${photo_id}&user_id=${user_id}&photo_id=${photo_id}&ledby=${quote(
                  nameSplite[0]
                )}&photo_hash=${md5}&thumb_value=${joinSplit}/thumbs/${fileName}&content_type=photo&caption=&approval_status=profile&os_type=iPhone&onboard_flag=true`
              )
              .then((respo) => {
                this.setState({
                  showImageError: false,
                  imageUpload: true,
                });
              });
          });
      });
      reader.onloadend = () => {
        that.setState({
          uploadImage: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  closeHandler = () => {
    localStorage.setItem("onboardComplete", true);
    window.location.reload();
  };

  backHandler = (currentStep) => {
    let getCurrentIndex = this.state.stepOrder.indexOf(currentStep);
    if (getCurrentIndex == 0) {
      this.setState({
        step: 1,
      });
    } else {
      this.setState({
        step: this.state.stepOrder[getCurrentIndex - 1],
      });
    }
  };
  nextHandler = (currentStep) => {
    let getCurrentIndex = this.state.stepOrder.indexOf(currentStep);
    if (getCurrentIndex == this.state.stepOrder.length - 1) {
      localStorage.setItem("onboardComplete", true);
      localStorage.setItem("openListHelp", true);
      if (this.state.guestNeedMore == false) {
        instance
          .get(
            `_fomoonboardcomplete?user_id=${this.state.userID}&photo_id=${this.state.photoID}&onboard_info[event_id]=${this.state.eventID}&onboard_info[group_id]=${this.state.groupID}&onboard_info[mapdata_id]=${this.state.mapID}`
          )
          .then((res) => {
            // console.log(res)
            // window.location.reload()
          });
      } else {
        window.location.reload();
      }
      if (this.state.step == 8) {
      }
      instance
        .get(
          `_fomomsg_getprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}&notification_flag=true`
        )
        .then((respo) => {
          if (window.location.pathname.split("/").length == 4) {
            window.location.pathname =
              window.location.pathname.split("/")[1] +
              "/" +
              respo.data.invited_by_id +
              "/" +
              window.location.pathname.split("/")[3];
          }
          if (window.location.pathname.split("/").length == 5) {
            window.location.pathname =
              window.location.pathname.split("/")[1] +
              "/" +
              respo.data.invited_by_id +
              "/" +
              window.location.pathname.split("/")[3] +
              "/" +
              window.location.pathname.split("/")[4];
          }
          if (window.location.pathname.split("/").length == 2) {
            window.location.reload();
          }
          if (window.location.pathname.split("/").length == 3) {
            window.location.reload();
          }
          // else {
          // 	window.location.reload()
          // }
        });
    } else {
      this.setState({
        step: this.state.stepOrder[getCurrentIndex + 1],
      });
    }
  };

  // guest User Code
  guestHandler = (e) => {
    e.preventDefault();
    if (this.state.guestLogin) {
      this.checkField("guestName");
      if (this.state.guestNameError == null) {
        this.setState({
          loading: true,
        });

        instance
          .get(`_generateuuids?idstr=FRJS&alias=${this.state.guestName}`)
          .then((res2) => {
            localStorage.setItem("photo_id", res2.data.photo_id);
            localStorage.setItem("user_id", res2.data.user_id);
            localStorage.setItem("guestUser", true);
            this.setState({
              userID: res2.data.user_id,
              photoID: res2.data.photo_id,
            });
            // let nameSplite = this.state.guestName.split(' ');
            let nameSplite = this.state.guestName
              .trim()
              .split(" ")
              .filter((val) => {
                if (val != "") {
                  return val;
                }
              });
            let paraMiters = `&update_struct[name_first]=${quote(
              nameSplite[0]
            )}`;

            if (nameSplite.length == 2) {
              paraMiters = `&update_struct[name_first]=${quote(
                nameSplite[0]
              )}&update_struct[name_last]=${quote(nameSplite[1])}`;
            }
            if (nameSplite.length == 3) {
              paraMiters = `&update_struct[name_first]=${quote(
                nameSplite[0]
              )}&update_struct[name_middle]=${quote(
                nameSplite[1]
              )}&update_struct[name_last]=${quote(nameSplite[2])}`;
            }
            if (nameSplite.length > 3) {
              // let nameLength = nameSplite.length;
              let middleName = nameSplite.filter((hh, key) => {
                if (key != 0 && key != nameSplite.length - 1) {
                  return hh;
                }
              });

              paraMiters = `&update_struct[name_first]=${quote(
                nameSplite[0]
              )}&update_struct[name_middle]=${quote(
                middleName.join(" ")
              )}&update_struct[name_last]=${quote(
                nameSplite[nameSplite.length - 1]
              )}`;
            }

            instance
              .get(
                `_fomomsg_updateprofile?user_id=${res2.data.user_id}&photo_id=${
                  res2.data.photo_id
                }&alias=${quote(nameSplite[0])}${paraMiters}&onboard_flag=${
                  this.state.onBoardStatus
                }`
              )
              .then((ress) => {
                console.log(ress);

                instance
                  .get(
                    `@${res2.data.photo_id}/_photolead_v2?value=${
                      this.state.rendomImageDetails.value
                    }&group_id=@${res2.data.photo_id}&user_id=${
                      res2.data.user_id
                    }&photo_id=${res2.data.photo_id}&ledby=${quote(
                      nameSplite[0]
                    )}&photo_hash=${
                      this.state.rendomImageDetails.photo_hash
                    }&thumb_value=${
                      this.state.rendomImageDetails.thumb_value
                    }&content_type=photo&caption=&approval_status=profile&os_type=iPhone&onboard_flag=false`
                  )
                  .then((res) => {
                    console.log(res);
                    localStorage.setItem("guestName", quote(nameSplite[0]));
                    this.setState({
                      loading: false,
                    });
                    if (this.state.guestNeedMore) {
                      this.setState({
                        step: this.state.stepOrder[0],
                      });
                    } else {
                      // window.location.reload()
                      instance
                        .get(
                          `_fomomsg_getprofile?user_id=${res2.data.user_id}&photo_id=${res2.data.photo_id}&notification_flag=true`
                        )
                        .then((respo) => {
                          localStorage.setItem("onboardComplete", true);
                          localStorage.setItem("openListHelp", true);
                          console.log(respo);
                          console.log(
                            window.location.pathname.split("/").length,
                            respo
                          );
                          if (window.location.pathname.split("/").length == 4) {
                            window.location.pathname =
                              window.location.pathname.split("/")[1] +
                              "/" +
                              respo.data.invited_by_id +
                              "/" +
                              window.location.pathname.split("/")[3];
                          }
                          if (window.location.pathname.split("/").length == 5) {
                            window.location.pathname =
                              window.location.pathname.split("/")[1] +
                              "/" +
                              respo.data.invited_by_id +
                              "/" +
                              window.location.pathname.split("/")[3] +
                              "/" +
                              window.location.pathname.split("/")[4];
                          }
                          if (window.location.pathname.split("/").length == 2) {
                            window.location.reload();
                          }
                          if (window.location.pathname.split("/").length == 3) {
                            window.location.reload();
                          }
                          // else {
                          // 	window.location.reload()
                          // }
                        });
                    }
                  });
              });
          });
      }
    } else {
      this.setState({
        guestNameError: "You are not login as a guest user",
      });
    }
  };
  profilePicHandler = () => {
    this.state.stepInfo.map((step) => {
      if (step.profile_pic) {
        step.profile_pic.map((childType) => {
          if (childType.value) {
            if (childType.value == "required") {
              if (this.state.showImageError) {
                this.setState({
                  imageUpload: false,
                });
              } else {
                this.nextHandler("profile_pic");
              }
            } else {
              this.nextHandler("profile_pic");
            }
          }
        });
      }
    });
  };

  inputNameHandler = (e, type) => {
    let fullName = [""];
    fullName = e.target.value
      .trim()
      .split(" ")
      .filter((val) => {
        if (val != "") {
          return val;
        }
      });
    // console.log(fullName)

    if (fullName[0]) {
      console.log(fullName[0][0]);
      if (fullName.length == 0 || fullName[0].length <= 20) {
        this.setState({
          [e.target.name]: e.target.value,
          [e.target.name + "Error"]: null,
        });
        if (type == "guest") {
          this.setState({
            onBoardStatus: true,
          });
        }
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: null,
      });
      if (type == "guest") {
        this.setState({
          onBoardStatus: true,
        });
      }
    }
  };

  nameBlurHandler = (e) => {
    this.checkField(e.target.name);
    let nameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-_.]*[a-zA-Z0-9])?$/;
    let fullName = [""];
    fullName = e.target.value
      .trim()
      .split(" ")
      .filter((val) => {
        if (val != "") {
          return val;
        }
      });
    if (fullName[0]) {
      if (nameRegex.test(fullName[0]) == false) {
        this.setState({
          guestNameError:
            "first name must be alphanumeric and [.,-,_] are allowed but cannot use for first and last character",
        });
      } else {
        this.setState({
          guestNameError: null,
        });
      }
    }
  };

  render() {
    return (
      <div
        style={{
          display: this.props.toggleHandler ? "block" : "none",
        }}
        className="popup welcome-popup"
      >
        <Loading show={this.state.loading} />
        <div className="backDrop" onClick={this.props.click}></div>
        {this.state.step == 1 ? (
          <div className="popup-box">
            {/* <span className="btn-close" onClick={this.props.click}><i className="ion-ios-close-empty"></i></span> */}
            <h2 className="desktop-text">
              Welcome to:{" "}
              {this.state.eventName
                ? unicodeToChar(this.state.eventName)
                : this.state.eventID}
            </h2>
            {this.state.eventID ? (
              <h2 className="mobile-text">
                Welcome to:{" "}
                {this.state.eventName
                  ? unicodeToChar(this.state.eventName)
                  : this.state.eventID}
              </h2>
            ) : null}
            {this.state.inviteBy ? (
              <p>
                <figure>
                  <img
                    style={{ borderRadius: "50%" }}
                    src={
                      this.state.invitedByPic
                        ? baseURL + this.state.invitedByPic.split("..")[1]
                        : avatarIcon
                    }
                  />
                </figure>
                You were invited by{" "}
                {this.state.inviteBy.name_first
                  ? unicodeToChar(
                      this.state.inviteBy.name_first +
                        " " +
                        this.state.inviteBy.name_last
                    )
                  : unicodeToChar(this.state.inviteByAlise)}{" "}
              </p>
            ) : null}

            <div className="colmn-section">
              <div className="colmn-box">
                <h3>I want a full FOMO experience and meet fun people</h3>
                <form autoComplete="off" onSubmit={this.submitHandler}>
                  <div className="form-group">
                    <label>Enter your email</label>
                    <div className="input-group">
                      <input
                        autoComplete="off"
                        type="text"
                        onBlur={this.blurHendler}
                        className="form-control"
                        name="email"
                        onChange={this.inputHandler}
                        value={this.state.email}
                        placeholder="Email"
                      />
                    </div>
                    {this.state.emailError ? (
                      <div className="error-input">
                        <p>{this.state.emailError}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button type="submit" className="btn btn-pink">
                    SIGN UP / LOG IN
                  </button>
                  {this.state.formMessage && this.state.success == true ? (
                    <div
                      className={
                        this.state.success
                          ? "form-message success"
                          : "form-message error"
                      }
                    >
                      <p>{this.state.formMessage}</p>
                    </div>
                  ) : null}
                </form>
              </div>
              <div className="colmn-box">
                <h3>I don’t like committing to making accounts</h3>
                <div className="form-group">
                  <label>Enter your name</label>
                  <div className="input-group guest-group">
                    <img
                      src={
                        this.state.rendomImageDetails
                          ? baseURL +
                            this.state.rendomImageDetails.thumb_value.split(
                              ".."
                            )[1]
                          : avtarIconNew
                      }
                    />
                    <div className="input-nameGroup">
                      <input
                        autoComplete="off"
                        placeholder="Name"
                        type="text"
                        onChange={(e) => this.inputNameHandler(e, "guest")}
                        onBlur={this.nameBlurHandler}
                        value={this.state.guestName}
                        name="guestName"
                      />
                      {this.state.guestNameError ? (
                        <div className="error-input">
                          <p>{this.state.guestNameError}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={this.guestHandler}
                  className="btn btn-pink"
                >
                  CONTINUE AS GUEST
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.step == "email" ? (
          <div className="popup-box box-xm">
            {this.props.step != 9 ? (
              this.state.guestProggess ? null : (
                <span className="btn-close" onClick={this.props.click}>
                  <i className="ion-ios-close-empty"></i>
                </span>
              )
            ) : null}

            {this.state.actionMode &&
            this.state.step == this.state.stepOrder[0] ? null : (
              <span
                className="btn-back"
                onClick={() => this.backHandler("email")}
              >
                <i className="ion-arrow-left-c"></i>
              </span>
            )}
            {/* <span className="btn-back" onClick={() => this.setState({step: 1})}><i className="ion-arrow-left-c"></i></span> */}
            <h2>Sign up for a FOMO account</h2>
            {/* <p>Your name is how other people will see you.</p> */}
            <form autoComplete="off" onSubmit={this.registerSubmitHandler}>
              <div className="form-group">
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onBlur={this.blurHendler}
                    className="form-control"
                    placeholder="Email"
                    onChange={this.inputHandler}
                    name="registerEmail"
                    value={this.state.registerEmail}
                  />
                </div>
                {this.state.registerEmailError ? (
                  <div className="error-input">
                    <p>{this.state.registerEmailError}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button type="submit" className="btn btn-pink">
                CONTINUE
              </button>
              {this.state.registerformError ? (
                <div className="form-error">
                  <p>{this.state.registerformError}</p>
                </div>
              ) : null}

              {this.state.stepInfo
                ? this.state.stepInfo.map((step) => {
                    if (step.email == "optional") {
                      return (
                        <button
                          type="button"
                          className="skip-button"
                          onClick={() => this.nextHandler("email")}
                        >
                          Skip
                        </button>
                      );
                    }
                  })
                : null}
            </form>
            <div className="text-box">
              <p>
                By continuing, you agree to our{" "}
                <a href="javascript:void(0)">User Agreement</a> and{" "}
                <a href="javascript:void(0)">Privacy Policy</a>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.step == 3 ? (
          <div className="popup-box box-xm login-popup">
            <span className="btn-close" onClick={this.props.click}>
              <i className="ion-ios-close-empty"></i>
            </span>
            {/* <span className="btn-back" onClick={() => this.setState({step: 1})}><i className="ion-arrow-left-c"></i></span> */}
            <h2>
              {this.props.stepType == "signup" ? "Sign up" : "Log in"} to your
              FOMO account
            </h2>
            {/* <p>Your name is how other people will see you.</p> */}
            <form autoComplete="off" onSubmit={this.submitHandler}>
              <div className="form-group">
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onBlur={this.blurHendler}
                    className="form-control"
                    name="email"
                    onChange={this.inputHandler}
                    value={this.state.email}
                    placeholder="Email"
                  />
                </div>
                {this.state.emailError ? (
                  <div className="error-input">
                    <p>{this.state.emailError}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button type="submit" className="btn btn-pink">
                CONTINUE
              </button>
              {this.state.formMessage ? (
                <div
                  className={
                    this.state.success
                      ? "form-message success"
                      : "form-message error"
                  }
                >
                  <p>{this.state.formMessage}</p>
                </div>
              ) : null}
            </form>
            {/* <form autoComplete="off" onSubmit={this.submitHandler}>
                            <div className="form-group">
                                <div className="input-group">
                                    <input autoComplete="off" type="text" onBlur={this.blurHendler} className="form-control" placeholder="Email" onChange={this.inputHandler} name="registerEmail" value={this.state.registerEmail}  />  
                                </div>
								{this.state.registerEmailError ? <div className="error-input"><p>{this.state.registerEmailError}</p></div> : ''}
                                 
                            </div>
                            <button type="submit" className="btn btn-pink">CONTINUE</button>
                        </form> */}
            <div className="text-box">
              <p>
                By continuing, you agree to our <a href="#">User Agreement</a>{" "}
                and <a href="#">Privacy Policy</a>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.step == "alias" ? (
          <div className="popup-box box-sm">
            {this.props.step != 9 ? (
              this.state.actionMode ? (
                <span className="btn-close" onClick={this.props.click}>
                  <i className="ion-ios-close-empty"></i>
                </span>
              ) : null
            ) : null}

            {/* {this.state.actionMode ? <span className="btn-close" onClick={this.props.click}><i className="ion-ios-close-empty"></i></span> : null

							} */}

            {this.state.actionMode &&
            this.state.step == this.state.stepOrder[0] ? null : (
              <span
                className="btn-back"
                onClick={() => this.backHandler("alias")}
              >
                <i className="ion-arrow-left-c"></i>
              </span>
            )}

            <h2>What’s your name?</h2>
            <p>Your name is how other people will see you.</p>
            <form
              autoComplete="off"
              onSubmit={(e) => this.saveDetailHandler(e, "registerName")}
            >
              <div className="form-group">
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    placeholder="Name"
                    onBlur={(e) => this.directBlurHendler(e, "alias")}
                    onChange={(e) => this.inputNameHandler(e, "alias")}
                    name="registerName"
                    value={
                      this.state.registerName
                        ? unicodeToChar(this.state.registerName)
                        : this.state.registerName
                    }
                  />
                </div>
                {this.state.registerNameError ? (
                  <div className="error-input">
                    <p>{this.state.registerNameError}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button type="submit" className="btn btn-pink">
                CONTINUE
              </button>
              {this.state.stepInfo
                ? this.state.stepInfo.map((step) => {
                    if (step.alias == "optional") {
                      return (
                        <button
                          type="button"
                          className="skip-button"
                          onClick={() => this.nextHandler("alias")}
                        >
                          Skip
                        </button>
                      );
                    }
                  })
                : null}
            </form>
          </div>
        ) : (
          ""
        )}
        {this.state.step == "profile_pic" ? (
          <div className="popup-box box-sm upload-main">
            {this.props.step != 9 ? (
              this.state.guestProggess ? null : (
                <span className="btn-close" onClick={this.closeHandler}>
                  <i className="ion-ios-close-empty"></i>
                </span>
              )
            ) : null}

            {/* {this.state.guestProggess ?
								null : <span className="btn-close" onClick={this.closeHandler}><i className="ion-ios-close-empty"></i></span>
							} */}

            {this.state.actionMode &&
            this.state.step == this.state.stepOrder[0] ? null : (
              <span
                className="btn-back"
                onClick={() => this.backHandler("profile_pic")}
              >
                <i className="ion-arrow-left-c"></i>
              </span>
            )}

            <h2>Add a photo</h2>
            <p>That way, people can recognize you</p>
            <div className="avtar-section">
              <div className="upload-box">
                <figure>
                  <img
                    style={{
                      width: this.state.uploadImage ? "100%" : "30px",
                      height: this.state.uploadImage ? "100%" : "auto",
                      objectFit: this.state.uploadImage ? "cover" : "unset",
                    }}
                    src={
                      this.state.uploadImage
                        ? this.state.uploadImage
                        : avtarIcon
                    }
                    alt="user"
                  />
                </figure>
                <span>
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
                <input
                  autoComplete="off"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={this.uploadHandler}
                />
              </div>
            </div>
            {this.state.imageUpload ? (
              ""
            ) : (
              <div className="error-input">
                <p>This field is required.</p>
              </div>
            )}
            <button
              type="submit"
              onClick={() => this.profilePicHandler()}
              className="btn btn-pink"
            >
              CONTINUE
            </button>

            {this.state.stepInfo
              ? this.state.stepInfo.map((step) => {
                  let showBtn = false;
                  if (step.profile_pic) {
                    step.profile_pic.map((profileStep) => {
                      if (profileStep.value == "optional") {
                        showBtn = true;
                      } else {
                        showBtn = false;
                      }
                    });
                    if (showBtn) {
                      return (
                        <button
                          type="button"
                          className="skip-button"
                          onClick={() => this.nextHandler("profile_pic")}
                        >
                          Skip
                        </button>
                      );
                    }
                  }
                })
              : null}
            {/* <button type="button" className="skip-button" onClick={() => this.nextHandler('profile_pic')}>Skip</button> */}
          </div>
        ) : (
          ""
        )}
        {this.state.step == "birthdate" ? (
          <div className="popup-box box-sm dob-form">
            {this.props.step != 9 ? (
              this.state.guestProggess ? null : (
                <span className="btn-close" onClick={this.closeHandler}>
                  <i className="ion-ios-close-empty"></i>
                </span>
              )
            ) : null}

            {/* {this.state.guestProggess ?
								null : <span className="btn-close" onClick={this.closeHandler}><i className="ion-ios-close-empty"></i></span>
							} */}

            {this.state.actionMode &&
            this.state.step == this.state.stepOrder[0] ? null : (
              <span
                className="btn-back"
                onClick={() => this.backHandler("birthdate")}
              >
                <i className="ion-arrow-left-c"></i>
              </span>
            )}

            <h2>What’s your birthday?</h2>
            <h4>You need to be at least 18.</h4>
            <p>Enter your date of birth</p>
            <form autoComplete="off" onSubmit={(e) => this.saveDobHandler(e)}>
              <div className="form-group">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="MM"
                    name="month"
                    onBlur={(e) =>
                      this.childrenBlurHandler(
                        e,
                        "birthdate",
                        "birthdate_month"
                      )
                    }
                    value={this.state.month}
                    onChange={(e) => this.numberHandler(e, 2, 0, 12)}
                  />
                  {this.state.monthError ? (
                    <div className="error-input">
                      <p>{this.state.monthError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="DD"
                    name="day"
                    onBlur={(e) =>
                      this.childrenBlurHandler(e, "birthdate", "birthdate_day")
                    }
                    value={this.state.day}
                    onChange={(e) => this.numberHandler(e, 2, 0, 31)}
                  />
                  {this.state.dayError ? (
                    <div className="error-input">
                      <p>{this.state.dayError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="YYYY"
                    name="year"
                    onBlur={(e) =>
                      this.childrenBlurHandler(e, "birthdate", "birthdate_year")
                    }
                    value={this.state.year}
                    onChange={(e) => this.numberHandler(e, 4, 0, 2002)}
                  />
                  {this.state.yearError ? (
                    <div className="error-input">
                      <p>{this.state.yearError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-pink">
                CONTINUE
              </button>
              {this.state.stepInfo
                ? this.state.stepInfo.map((step) => {
                    if (step.birthdate) {
                      if (
                        step.birthdate[0][Object.keys(step.birthdate[0])[0]] ==
                          "optional" &&
                        step.birthdate[1][Object.keys(step.birthdate[1])[0]] ==
                          "optional" &&
                        step.birthdate[2][Object.keys(step.birthdate[2])[0]] ==
                          "optional"
                      ) {
                        return (
                          <button
                            type="button"
                            className="skip-button"
                            onClick={() => this.nextHandler("birthdate")}
                          >
                            Skip
                          </button>
                        );
                      }
                    }
                  })
                : null}
              {/* <button type="button" className="skip-button" onClick={() => this.nextHandler('birthdate')}>Skip</button> */}
            </form>
          </div>
        ) : (
          ""
        )}
        {this.state.step == "profile_desc" ? (
          <div className="popup-box box-sm dob-form bio-form">
            {this.props.step != 9 ? (
              this.state.guestProggess ? null : (
                <span className="btn-close" onClick={this.closeHandler}>
                  <i className="ion-ios-close-empty"></i>
                </span>
              )
            ) : null}

            {/* {this.state.guestProggess ?
								null : <span className="btn-close" onClick={this.closeHandler}><i className="ion-ios-close-empty"></i></span>
							} */}
            {this.state.actionMode &&
            this.state.step == this.state.stepOrder[0] ? null : (
              <span
                className="btn-back"
                onClick={() => this.backHandler("profile_desc")}
              >
                <i className="ion-arrow-left-c"></i>
              </span>
            )}

            <h2>Write a short bio</h2>
            <h4>Tell us something that makes you, you</h4>
            <form autoComplete="off" onSubmit={this.saveBioHandler}>
              <div className="form-group">
                <div className="input-group">
                  <textarea
                    value={this.state.aboutMe}
                    onBlur={(e) => this.directBlurHendler(e, "profile_desc")}
                    onChange={this.inputHandler}
                    name="aboutMe"
                  ></textarea>
                  {this.state.aboutMeError ? (
                    <div className="error-input">
                      <p>{this.state.aboutMeError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-pink">
                CONTINUE
              </button>
              {this.state.stepInfo
                ? this.state.stepInfo.map((step) => {
                    if (step.profile_desc == "optional") {
                      return (
                        <button
                          type="button"
                          className="skip-button"
                          onClick={() => this.nextHandler("profile_desc")}
                        >
                          Skip
                        </button>
                      );
                    }
                  })
                : null}
            </form>
          </div>
        ) : (
          ""
        )}
        {this.state.step == "account_links" ? (
          <div className="popup-box box-sm dob-form link-form">
            {this.props.step != 9 ? (
              this.state.guestProggess ? null : (
                <span className="btn-close" onClick={this.closeHandler}>
                  <i className="ion-ios-close-empty"></i>
                </span>
              )
            ) : null}

            {/* {this.state.guestProggess ?
								null : <span className="btn-close" onClick={this.closeHandler}><i className="ion-ios-close-empty"></i></span>
							} */}

            {this.state.actionMode &&
            this.state.step == this.state.stepOrder[0] ? null : (
              <span
                className="btn-back"
                onClick={() => this.backHandler("account_links")}
              >
                <i className="ion-arrow-left-c"></i>
              </span>
            )}

            <h2>Add social links</h2>
            <form autoComplete="off" onSubmit={this.saveLinkHandler}>
              <div className="form-group">
                <label for="insta">Instagram</label>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Instagram"
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    name="instagram"
                    value={this.state.instagram}
                    onBlur={(e) =>
                      this.linkBlurHandler(e, "https://www.instagram.com/")
                    }
                    onChange={this.linkHandler}
                  />
                  {this.state.instagramError ? (
                    <div className="error-input">
                      <p>{this.state.instagramError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group">
                <label for="insta">Facebook</label>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    placeholder="Facebook"
                    type="text"
                    className="form-control"
                    name="facebook"
                    value={this.state.facebook}
                    onBlur={(e) =>
                      this.linkBlurHandler(e, "https://www.facebook.com/")
                    }
                    onChange={this.linkHandler}
                  />
                  {this.state.facebookError ? (
                    <div className="error-input">
                      <p>{this.state.facebookError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Twitter</label>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    placeholder="Twitter"
                    type="text"
                    className="form-control"
                    name="twitter"
                    value={this.state.twitter}
                    onBlur={(e) =>
                      this.linkBlurHandler(e, "https://twitter.com/")
                    }
                    onChange={this.linkHandler}
                  />
                  {this.state.twitterError ? (
                    <div className="error-input">
                      <p>{this.state.twitterError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Soundcloud</label>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    placeholder="Soundcloud"
                    type="text"
                    className="form-control"
                    name="soundcloud"
                    value={this.state.soundcloud}
                    onBlur={(e) =>
                      this.linkBlurHandler(e, "https://soundcloud.com/")
                    }
                    onChange={this.linkHandler}
                  />
                  {this.state.soundcloudError ? (
                    <div className="error-input">
                      <p>{this.state.soundcloudError}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-pink">
                CONTINUE
              </button>

              {this.state.stepInfo
                ? this.state.stepInfo.map((step) => {
                    if (step.account_links) {
                      if (
                        step.account_links[0][
                          Object.keys(step.account_links[0])[0]
                        ] == "optional" &&
                        step.account_links[1][
                          Object.keys(step.account_links[1])[0]
                        ] == "optional" &&
                        step.account_links[2][
                          Object.keys(step.account_links[2])[0]
                        ] == "optional" &&
                        step.account_links[3][
                          Object.keys(step.account_links[3])[0]
                        ] == "optional"
                      ) {
                        return (
                          <button
                            type="button"
                            className="skip-button"
                            onClick={() => this.nextHandler("account_links")}
                          >
                            Skip
                          </button>
                        );
                      }
                    }
                  })
                : null}
              {/* <button type="button" className="skip-button" onClick={() => this.nextHandler('account_links')}>Skip</button> */}
            </form>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.welcomeStep.step,
  stepType: state.welcomeStep.stepType,
  dataType: state.welcomeStep.fieldType,
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileHandler: (value, valueType) =>
      dispatch(actions.editProfile(value, valueType)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(welcomePopup);
