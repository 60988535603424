import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from "react-router-dom";
import { Switch } from "react-router";
import Login from './containers/login/login';
import groupDetail from './store/reducers/groupDetail';
import addChat from './store/reducers/addChat';
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import welcomeStep from './store/reducers/welcomeStep';
import editProfile from './store/reducers/editProfile';


const rootReducer = combineReducers({
	groupDetail: groupDetail,
	addChat: addChat,
	welcomeStep: welcomeStep,
	editProfile: editProfile
});
const composeEnhancers =
	typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		}) : compose;
const enhancer = composeEnhancers(
	applyMiddleware(thunk),
);
const makeStore = createStore(rootReducer, enhancer);

const app = (
	<Provider store={makeStore}>
		<BrowserRouter>
			<Switch>
				<Route path="/_login" exact component={Login} />
				<Route path="/" component={App} />
			</Switch>
		</BrowserRouter>
	</Provider>
)


ReactDOM.render(app, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
