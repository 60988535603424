import React, { Component } from "react";
import "./feeds.scss";
import Slider from "react-slick";
import { instance, baseURL } from "../../axios";
import avtarIcon from "../../assets/images/avtar.png";
import Popup from "../popup/popup";
import BMF from "browser-md5-file";
import laughingIcon from "../../assets/images/laughing.png";
import fireIcon from "../../assets/images/fire.png";
import facepalmIcon from "../../assets/images/facepalm.png";
import headExploding from "../../assets/images/head-exploding.png";
import { Picker } from "emoji-mart";
import checkIcon from "../../assets/images/check-icon.png";
import closeIcon from "../../assets/images/close-icon.png";
import { quote, unicodeToChar } from "../../shared/utility";
import uploadIcon from "../../assets/images/server.svg";
import loadingImg from "../../assets/images/loading-new.gif";
import checkIconSVG from "../../assets/images/check-icon.svg";
import errorIcon from "../../assets/images/error-icon.svg";
import { channelOne, channelTwo } from "../../pusher";
import { useRouteMatch } from "react-router-dom";
import viewIcon from "../../assets/images/view-icon.svg";
import questionIcon from "../../assets/images/question-icon.svg";
import webIcon from "../../assets/images/web-icon.svg";
import Loading from "../loading/loading";
import EditImage from "../editImage/editImage";
import { DokaModal, toURL } from "../../react-doka";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import Nofeeds from "../../assets/images/no-feeds.png";
import SendIcon from "../../assets/images/send-icon.svg";
import plusIcon from "../../assets/images/plus-icon.png";
import messageIcon from "../../assets/images/message-icon.png";
import CameraIconNew from "../../assets/images/camera-icon-new.png";
import VideoIconNew from "../../assets/images/video-icon.png";
import CameraIconPink from "../../assets/images/camera-icon-pink.png";
import CameraIconWhite from "../../assets/images/camera-icon-white.svg";
import ListView from "../../components/listView/listView";

import SendButton from "../../assets/images/send.svg";
import listIcon from "../../assets/images/list.svg";
import feedList from "../feedList/feedList";
class feed extends Component {
  state = {
    allUsersPhotoId: {},
    photoHistory: [],
    message: "",
    uploadStep: 0,
    uploadImage: "",
    imageFile: "",
    fileName: "",
    filePopup: false,
    showEmoji: false,
    showUploadOption: false,
    fileUpladed: false,
    fileNotUploaded: false,
    imageCaption: "",
    fileUploadLoading: false,
    activeSlide: 0,
    slide: null,
    new_slide: [],
    delete_slide: [],
    feed_start: false,
    delete_all: false,
    editImageResult: null,
    url: null,
    showImageEditor: false,
    add_emoji: true,
    showAlert: false,
    listViewToggle: false,
    selectedSlide: "",
    feed: {
      data: {
        new: [],
        all: [],
        main: [],
        all2: [],
        main2: [],
      },
      reaction: {
        all: [],
        all2nd: [],
      },
      setting: {
        rcv_content_ratio: 0,
        rcv_content_source: "",
        rcv_playmode: "",
        last_source: null,
        content_ratio_offset: 0,
      },
    },
  };

  shuffle = (arra1) => {
    var ctr = arra1.length,
      temp,
      index;

    // While there are elements in the array
    while (ctr > 0) {
      // Pick a random index
      index = Math.floor(Math.random() * ctr);
      // Decrease ctr by 1
      ctr--;
      // And swap the last element with it
      temp = arra1[ctr];
      arra1[ctr] = arra1[index];
      arra1[index] = temp;
    }
    return arra1;
  };

  showSlide = (type, mode) => {
    switch (type) {
      case "new":
    }
  };

  setContentSource = (type) => {
    let feed = { ...this.state.feed };
    let slide = this.state.slide;

    feed["data"]["new"] = [
      ...this.state.new_slide.filter((item) => {
        return (
          item.approval_status == "approved" &&
          item.group_id == window.location.pathname.split("/")[1]
        );
      }),
    ];

    for (let key in feed["data"]) {
      feed["data"][key] = feed["data"][key].filter((item) => {
        return this.state.delete_slide.indexOf(item.object_id) == -1
          ? true
          : false;
      });
    }

    if (this.state.delete_all) {
      if (this.state.delete_type == "approved") {
        feed["data"]["new"] = [];
        feed["data"]["all"] = [];
        feed["data"]["main"] = [];
      } else {
        feed["data"]["all2"] = [];
        feed["data"]["main2"] = [];
      }
    }

    this.state.new_slide.map((item) => {
      let item2 = { ...item };
      if (item.approval_status == "approved") {
        feed["data"]["all"].unshift(item2);
      } else if (
        item.approval_status == "favorite" &&
        this.props.groupDetail.rcv_content_source == "_favorite"
      ) {
        feed["data"]["all2"].unshift(item2);
        feed["data"]["main2"].unshift(item2);
      }
    });

    switch (type) {
      case "main":
        //check new first
        if (feed["data"]["main"].length == 0) {
          feed["data"]["main"] = [...feed["data"]["all"]];
        }
        if (feed["data"]["new"].length > 0) {
          slide = feed["data"]["new"][0];
          feed["data"]["new"].shift();
        } else if (feed["data"]["main"].length > 0) {
          if (feed["setting"]["rcv_playmode"] == "loop") {
            slide = feed["data"]["main"][0];
            feed["data"]["main"].shift();
          } else {
            let index = Math.floor(Math.random() * feed["data"]["main"].length);
            // console.log(index, 'extra Slider Index')
            slide = feed["data"]["main"][index];
            feed["data"]["main"].splice(index, 1);
          }
        } else {
          if (feed["setting"]["rcv_content_ratio"] != 20)
            if (feed["data"]["main2"].length > 0) {
              if (feed["setting"]["rcv_playmode"] == "loop") {
                slide = feed["data"]["main2"][0];
                feed["data"]["main2"].shift();
              } else {
                let index = Math.floor(
                  Math.random() * feed["data"]["main2"].length
                );
                slide = feed["data"]["main2"][index];
                feed["data"]["main2"].splice(index, 1);
              }
            }
        }
        break;
      case "main2":
        if (feed["data"]["main2"].length == 0) {
          feed["data"]["main2"] = [...feed["data"]["all2"]];
        }
        if (feed["data"]["main2"].length > 0) {
          if (feed["setting"]["rcv_playmode"] == "loop") {
            slide = feed["data"]["main2"][0];
            feed["data"]["main2"].shift();
          } else {
            let index = Math.floor(
              Math.random() * feed["data"]["main2"].length
            );
            slide = feed["data"]["main2"][index];
            feed["data"]["main2"].splice(index, 1);
          }
        } else {
          if (feed["setting"]["rcv_content_ratio"] != -20)
            if (feed["data"]["main"].length > 0) {
              if (feed["setting"]["rcv_playmode"] == "loop") {
                slide = feed["data"]["main"][0];
                feed["data"]["main"].shift();
              } else {
                let index = Math.floor(
                  Math.random() * feed["data"]["main"].length
                );
                console.log(index, "extra Slider Index");
                slide = feed["data"]["main"][index];
                feed["data"]["main"].splice(index, 1);
              }
            }
        }
        break;
    }
    // console.log('Slider Prop',slide)

    if (slide != null && slide.content_type == "photo") {
      this.startProgressbar();
    }

    if (slide != null && slide.content_type == "video") {
      this.resetProgressbar();
      setTimeout(() => {
        this.refs.vidRef.play();
      }, 1000);
    }

    this.setState({
      slide: slide,
      new_slide: [],
      delete_slide: [],
      delete_all: false,
      url: null,
    });
    feed["setting"]["last_source"] = type;
    return feed;
  };

  setNextSlide = () => {
    let feed = { ...this.state.feed };

    this.setState({
      showReactBox: true,
    });
    setTimeout(() => {
      this.setState({
        showReactBox: false,
      });
    }, 2000);

    if (feed["setting"]["rcv_content_ratio"] == 0) {
      if (
        feed["setting"]["last_source"] == "main" ||
        feed["setting"]["last_source"] == null
      ) {
        feed = this.setContentSource("main2");
      } else {
        feed = this.setContentSource("main");
      }
    } else if (feed["setting"]["rcv_content_ratio"] == 20) {
      feed = this.setContentSource("main");
    } else if (feed["setting"]["rcv_content_ratio"] > 0) {
      if (
        feed["setting"]["content_ratio_offset"] <=
          feed["setting"]["rcv_content_ratio"] &&
        this.state.feed_start == true &&
        (this.state.feed.data.all.length > 0 ||
          this.state.feed.data.new.length > 0)
      ) {
        feed = this.setContentSource("main");
        feed["setting"]["content_ratio_offset"] =
          feed["setting"]["content_ratio_offset"] + 1;
      } else {
        feed = this.setContentSource("main2");
        feed["setting"]["content_ratio_offset"] = 0;
      }
    } else if (feed["setting"]["rcv_content_ratio"] < 0) {
      if (
        feed["setting"]["content_ratio_offset"] <=
          Math.abs(feed["setting"]["rcv_content_ratio"]) &&
        this.state.feed_start == true
      ) {
        feed = this.setContentSource("main2");
        feed["setting"]["content_ratio_offset"] =
          feed["setting"]["content_ratio_offset"] + 1;
      } else {
        feed = this.setContentSource("main");
        feed["setting"]["content_ratio_offset"] = 0;
      }
    }

    this.setState({
      feed: feed,
      feed_start: true,
    });

    // console.log("%cExtra Large Yellow Text with Red Background", "background: red; color: yellow; font-size: x-large", feed)
  };

  fetchFeeds = async (photoID, userID, groupEvent) => {
    let reaction = {};
    let allUserphotoId = {};
    let favoritephotoId = {};
    let content_sourcephotoId = {};

    let all = await instance.get(
      `${groupEvent}/photohistory_v2.json?photo_id=${photoID}&userid=${userID}&offset&object_id&approval_type=approved&reaction_flag=true&ptr_dic_flag=true`
    );
    console.log(all, "all feeds");
    allUserphotoId = all.data.ptr_dic.photo_id;
    let feed = { ...this.state.feed };

    feed["data"]["all"] = all.data.results
      ? [...all.data.results]
      : [...all.data];
    feed["data"]["main"] = all.data.results
      ? [...all.data.results]
      : [...all.data];
    reaction = all.data.reactions_dic;

    if (this.props.groupDetail.rcv_content_source == "_favorite") {
      let all2nd = await instance.get(
        `${groupEvent}/photohistory_v2.json?photo_id=${photoID}&userid=${userID}&offset&object_id&approval_type=favorite&reaction_flag=true&ptr_dic_flag=true`
      );
      console.log(all2nd, "all2nd");
      favoritephotoId = all2nd.data.ptr_dic.photo_id;
      feed["data"]["all2"] = all2nd.data.results
        ? [...all2nd.data.results]
        : [...all2nd.data];
      feed["data"]["main2"] = all2nd.data.results
        ? [...all2nd.data.results]
        : [...all2nd.data];
      reaction = {
        ...all2nd.data.reactions_dic,
        ...reaction,
      };
    } else if (this.props.groupDetail.rcv_content_source != "") {
      let all2nd = await instance.get(
        `${this.props.groupDetail.rcv_content_source}/photohistory_v2.json?photo_id=${photoID}&userid=${userID}&offset&object_id&approval_type=approved&reaction_flag=true&ptr_dic_flag=true`
      );
      console.log(all2nd, "three");
      content_sourcephotoId = all2nd.data.ptr_dic.photo_id;
      feed["data"]["all2"] = all2nd.data.results
        ? [...all2nd.data.results]
        : [...all2nd.data];
      feed["data"]["main2"] = all2nd.data.results
        ? [...all2nd.data.results]
        : [...all2nd.data];
      reaction = {
        ...all2nd.data.reactions_dic,
        ...reaction,
      };
    }
    let mergeAllPhotoId = {
      ...allUserphotoId,
      ...favoritephotoId,
      ...content_sourcephotoId,
    };
    // console.log(mergeAllPhotoId, 'merged')
    this.setState({
      allUsersPhotoId: mergeAllPhotoId,
    });
    feed["setting"]["rcv_content_source"] =
      this.props.groupDetail.rcv_content_source;
    feed["setting"]["rcv_content_ratio"] =
      this.props.groupDetail.rcv_content_ratio;
    feed["setting"]["rcv_playmode"] = this.props.groupDetail.rcv_playmode;
    feed["setting"]["rcv_delay"] = this.props.groupDetail.rcv_delay;
    feed["reaction"]["all"] = reaction;

    this.setState({
      feed: feed,
    });

    // console.log(feed,'async')
    // instance.get(`${groupEvent}/photohistory_v2.json?photo_id=${photoID}&userid=${userID}&offset&object_id&approval_type=approved&adminpass=''`)
    // 	.then(res => {

    // 		console.log(res)
    // 		let feeds = res.data;
    // 		console.log(res.data , 'loop')
    // 		if(this.props.groupDetail.rcv_playmode == 'random'){
    // 			feeds = this.shuffle(res.data)
    // 			console.log(res.data , 'random')
    // 		}
    // 		this.setState({
    // 			allFeeds: feeds,
    // 			mainFeeds: feeds,
    // 			photoHistory: feeds,
    // 		})
    // 		if(this.props.groupDetail.rcv_content_source == '_favorite'){
    // 			instance.get(`${groupEvent}/photohistory_v2.json?photo_id=${photoID}&userid=${userID}&offset&object_id&approval_type=favorite&adminpass=''`)
    // 			.then(res => {

    // 				this.setState({
    // 					ALL2ndSource:res.data,
    // 					MAIN2ndSource:res.data,
    // 				})
    // 				if(this.props.groupDetail.rcv_content_ratio == '0'){
    // 					res.data.map(feed => {
    // 						feeds.push(feed)
    // 					})
    // 					let finelRamdomData = feeds
    // 					if(this.props.groupDetail.rcv_playmode == 'random'){
    // 						finelRamdomData = this.shuffle(feeds)

    // 					}

    // 					this.setState({
    // 						photoHistory:finelRamdomData
    // 					})
    // 				}
    // 			})
    // 		}

    this.setNextSlide();

    // 	})
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.groupDetail &&
      (nextProps.groupDetail.rcv_content_source !==
        this.props.groupDetail.rcv_content_source ||
        nextProps.groupDetail.rcv_content_ratio !==
          this.props.groupDetail.rcv_content_ratio ||
        nextProps.groupDetail.rcv_playmode !==
          this.props.groupDetail.rcv_playmode ||
        nextProps.groupDetail.rcv_delay !== this.props.groupDetail.rcv_delay)
    ) {
      //Perform some operation
      let feed = { ...this.state.feed };
      console.log("extra props", nextProps.groupDetail);
      feed["setting"]["rcv_content_source"] =
        nextProps.groupDetail.rcv_content_source;
      feed["setting"]["rcv_content_ratio"] =
        nextProps.groupDetail.rcv_content_ratio;
      feed["setting"]["rcv_playmode"] = nextProps.groupDetail.rcv_playmode;
      feed["setting"]["rcv_delay"] = nextProps.groupDetail.rcv_delay;
      this.setState({ feed: feed });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groupDetail !== this.props.groupDetail) {
      let userID = localStorage.getItem("user_id");
      let photoID = localStorage.getItem("photo_id");
      let groupTable = window.location.pathname.split("/")[3]
        ? window.location.pathname.split("/")[3].toUpperCase()
        : window.location.pathname.split("/")[3];
      let groupEvent = window.location.pathname.split("/")[1]
        ? window.location.pathname.split("/")[1].toUpperCase()
        : window.location.pathname.split("/")[1];
      this.setState({
        groupTable: groupTable,
        groupEvent: groupEvent,
      });

      this.fetchFeeds(userID, photoID, groupEvent);
      // let slideOptions = { ...this.state.sliderSettings }
      // // slideOptions.autoplay = res.data.rcv_paused_on == 'false' ? true : false;
      // slideOptions.autoplaySpeed = parseInt(this.props.groupDetail.rcv_delay) * 1000;
    }
  }
  componentDidMount() {
    let userID = localStorage.getItem("user_id");
    let photoID = localStorage.getItem("photo_id");
    let groupTable = window.location.pathname.split("/")[3].toUpperCase();
    let groupEvent = window.location.pathname.split("/")[1].toUpperCase();
    let onboardComplete = localStorage.getItem("onboardComplete")
      ? localStorage.getItem("onboardComplete")
      : false;
    this.setState({
      userID: userID,
      photoID: photoID,
      groupTable: groupTable,
      groupEvent: groupEvent,
      guestUser: JSON.parse(localStorage.getItem("guestUser")),
      showAlert: onboardComplete,
      // groupSettings: this.props.groupDetail,
    });

    channelOne.bind_global((event, data) => {
      // console.log(event, data, 'Feed Pusher')
      let feed_data = this.state.new_slide;
      if (data.approval_status == "approved") {
        let getallPhotoId = { ...this.state.allUsersPhotoId };
        getallPhotoId[Object.keys(data.ptr_dic.photo_id)[0]] =
          data.ptr_dic.photo_id[Object.keys(data.ptr_dic.photo_id)[0]];
        console.log(
          getallPhotoId[Object.keys(data.ptr_dic.photo_id)[0]],
          data.ptr_dic.photo_id[Object.keys(data.ptr_dic.photo_id)[0]],
          getallPhotoId
        );
        this.setState({
          allUsersPhotoId: getallPhotoId,
        });
      }
      switch (data.type) {
        case "photo":
          feed_data.unshift(data);
          this.setState({
            new_slide: feed_data,
          });

          if (this.state.slide == null) {
            this.setNextSlide();
          }

          break;
        case "video":
          feed_data.unshift(data);
          this.setState({
            new_slide: feed_data,
          });

          if (this.state.slide == null) {
            this.setNextSlide();
          }

          break;
        case "update-caption":
          break;
        case "photo-update-ptr":
          break;

        case "photo-delete-v2":
          let delete_slide = [...this.state.delete_slide];
          delete_slide.push(data.object_id);

          this.setState({
            delete_slide: delete_slide,
          });

          if (
            this.state.slide != null &&
            this.state.slide.object_id == data.object_id
          ) {
            this.setState({
              delete_slide: delete_slide,
              slide: null,
            });
            this.setNextSlide();
          } else {
            this.setState({
              delete_slide: delete_slide,
            });
          }

          break;

        case "photo-delete-all":
          if (
            this.state.slide.approval_status == data.approval_status &&
            this.state.slide.group_id == data.group_id
          ) {
            this.setState({
              delete_all: true,
              slide: null,
              delete_type: data.approval_status,
            });
            this.setNextSlide();
          } else {
            this.setState({
              delete_all: true,
              delete_type: data.approval_status,
            });
          }

          break;
      }
    });

    instance
      .get(`_getfomoonboard.json?user_id=${userID}&photo_id=${photoID}`)
      .then((res) => {
        let requiredDetail = res.data.onboard_dic.profile.actions;
        let getGuest = res.data.onboard_completed_dic.profile;
        let getKeys = Object.keys(requiredDetail);
        let completeObject = {};
        getKeys.map((opt) => {
          let getField = requiredDetail[opt];
          let checkFields = getField.filter((element) =>
            getGuest.includes(element)
          );
          if (checkFields.length == getField.length) {
            completeObject[opt] = true;
          } else {
            completeObject[opt] = false;
          }
        });
        console.log(completeObject, "working");
        this.setState({
          getAllFields: completeObject,
        });
      });
  }

  startProgressbar = () => {
    this.resetProgressbar();
    this.setState({
      percentTime: 0,
      isPause: false,
      tick: setInterval(this.interval, 10),
    });
  };

  interval = () => {
    if (this.state.isPause === false) {
      let delay = 2 * 100;
      if (this.state.feed.setting.rcv_delay > 0) {
        delay = this.state.feed.setting.rcv_delay * 100;
      }

      this.setState((prevState) => ({
        percentTime: prevState.percentTime + 100 / delay,
        barWidth: prevState.percentTime + 100 / delay + "%",
      }));

      // this.progressBar.style.width = this.state.percentTime + "%";

      if (this.state.percentTime >= 100) {
        // console.log('extra percent', this.state.percentTime)
        this.startProgressbar();
        this.setNextSlide();
      }
    }
  };

  resetProgressbar = () => {
    // this.progressBar.style.width = 0 + '%'
    this.setState({
      barWidth: 0 + "%",
    });
    clearTimeout(this.state.tick);
  };
  fileHandlar = (type = null) => {
    this.setState((prevState) => ({
      filePopup: !prevState.filePopup,
      showUploadOptionMobile: !prevState.showUploadOptionMobile,
      uploadStep: 0,
      uploadImage: "",
      imageFile: "",
      fileName: "",
      uploadType: type,
      imageCaption: "",
      fileNotUploaded: false,
    }));
  };
  uploadHandler = (e, type = null) => {
    if (type) {
      this.setState({
        filePopup: true,
        uploadImage: "",
        imageFile: "",
        fileName: "",
        uploadType: "image",
        imageCaption: "",
        fileNotUploaded: false,
      });
    }
    console.log(e.target.files.length, e.target.files, e.target.value);
    let imageFiles = e.target.files;
    let formatedFiles = [];

    for (let i = 0; i < imageFiles.length; i++) {
      let reader = new FileReader();
      let file = imageFiles[i];
      console.log(file);
      // let FileName = e.target.value.split("\\").pop();
      reader.onloadend = () => {
        formatedFiles.push({
          imageFile: file,
          name: file.name,
          imageUrl: reader.result,
        });
        this.setState({
          uploadImage: reader.result,
          imageFile: file,
          fileName: file.name,
          uploadStep: 1,
        });
      };
      reader.readAsDataURL(file);
    }

    this.setState({
      formatedFiles: formatedFiles,
    });
    console.log(imageFiles.length, type);
    if (imageFiles.length == 1 && type == null) {
      console.log("working");
      if (this.state.uploadType == "image") {
        this.setState({
          showImageEditor: true,
        });
      }
    }

    // let that = this;
    // let reader = new FileReader();
    // let file = e.target.files[0];
    // let FileName = e.target.value.split("\\").pop();
    // reader.onloadend = () => {
    //   that.setState({
    //     uploadImage: reader.result,
    //     imageFile: file,
    //     fileName: FileName,
    //     uploadStep: 1,
    //   });
    //   if (this.state.uploadType == "image") {
    //     this.setState({
    //       showImageEditor: true,
    //     });
    //   }
    // };

    // reader.readAsDataURL(file);
  };

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  emojiHandlar = () => {
    this.setState((prevState) => ({
      backDrop: !prevState.backDrop,
      showEmoji: !prevState.showEmoji,
    }));
  };
  addEmoji = (e) => {
    let emoji = e.native;
    this.setState({
      backDrop: true,
      message: this.state.message + emoji,
    });
  };

  submitHandler = (e, slideObject_id, photo_Id, name) => {
    // console.log(slideObject_id)
    e.preventDefault();
    let message = quote(this.state.message);
    if (message.trim() !== "") {
      instance
        .get(
          `_fomomsg_sendmsg?from_user_id=${this.state.userID}&from_photo_id=${this.state.photoID}&from_alias=${this.props.userDetail.alias}&msg_type=fomo_msg&object_id=@${slideObject_id}&msg_text=${message}&msg_content_type=text&group_id=${this.state.groupEvent}`
        )
        // console.log(this.state.photoID, this.state.userID, object_id)
        // 	instance.get(`_fomomsg_sendmsg?from_user_id=${this.state.userID}&from_photo_id=${this.state.photoID}&from_alias=${this.props.userDetail.alias}&msg_type=fomo_msg&object_id=@${slideObject_id}
        // &msg_type_class&msg_type_subclass&msg_text=${message}&msg_content_type=text&fomo_chat_from_alias`)
        .then((res) => {
          console.log(res, "done");
          this.setState({
            message: "",
            replyTo: name,
            isPause: false,
            showSendBox: false,
          });

          this.props.addChat(res.data);
          setTimeout(() => {
            this.setState({
              replyTo: "",
            });
          }, 10000);
        });
    }
  };

  uploadOption = () => {
    this.setState((prevState) => ({
      showUploadOption: !prevState.showUploadOption,
    }));
  };
  captionHandler = (e) => {
    // console.log(e.target.value)
    // this.setState({
    // 	[e.target.name]: e.target.value
    // })
    if (e.target.value.length <= 200) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  uploadImageHandler = (e) => {
    e.preventDefault();
    this.setState({
      fileUploadLoading: true,
      fileNotUploaded: false,
    });

    if (this.state.uploadType == "image") {
      if (this.state.formatedFiles.length != 1) {
        this.state.formatedFiles.map((mainFile) => {
          const bmf = new BMF();
          bmf.md5(mainFile.imageFile, (err, md5) => {
            this.setState({
              hash: md5,
            });

            let formData = new FormData();
            let extension = mainFile.name;
            let hashID = md5 + "." + extension.toLowerCase();
            formData.append("avatar", mainFile.imageFile);
            formData.append("hash", hashID);
            formData.append("uuid", this.state.groupEvent);

            instance
              .post(`_photo_upload?uuid=${this.state.groupEvent}`, formData)
              .then((res) => {
                // console.log(res.data)
                instance
                  .get(
                    `${this.state.groupEvent}/_photolead_v2?value=${
                      res.data
                    }&group_id=${this.state.groupEvent}&user_id=${
                      this.state.userID
                    }&photo_id=${this.state.photoID}&ledby=${
                      this.props.userDetail.alias
                    }&photo_hash=${md5}&thumb_value=../static/photos/${
                      this.state.groupEvent
                    }/thumbs/${hashID}&content_type=photo&caption=${quote(
                      this.state.imageCaption.replace(/\n/g, " ")
                    )}&os_type=reactJs`
                  )
                  .then((res) => {
                    // console.log(res)
                    instance
                      .get(
                        `${this.state.groupEvent}/photohistory_v2.json?limit=25&photo_id=${this.state.photoID}&userid=${this.state.userID}&offset&object_id&approval_type=approved`
                      )
                      .then((res) => {
                        this.setState({
                          photoHistory: res.data,
                          fileUpladed: true,
                          uploadStep: 2,
                          fileUploadLoading: false,
                          imageCaption: "",
                        });
                      });
                  })
                  .catch((res) => {
                    this.setState({
                      fileNotUploaded: true,
                      fileUploadLoading: false,
                      imageCaption: "",
                    });
                  });
              })
              .catch((err) => {
                this.setState({
                  fileNotUploaded: true,
                  fileUploadLoading: false,
                  imageCaption: "",
                });
              });
          });
        });
      } else {
        console.log("worked else");
        const bmf = new BMF();
        bmf.md5(this.state.imageFile, (err, md5) => {
          this.setState({
            hash: md5,
          });

          let formData = new FormData();
          let extension = this.state.fileName.split(".").pop();
          let hashID = md5 + "." + extension.toLowerCase();
          formData.append("avatar", this.state.imageFile);
          formData.append("hash", hashID);
          formData.append("uuid", this.state.groupEvent);

          instance
            .post(`_photo_upload?uuid=${this.state.groupEvent}`, formData)
            .then((res) => {
              // console.log(res.data)
              instance
                .get(
                  `${this.state.groupEvent}/_photolead_v2?value=${
                    res.data
                  }&group_id=${this.state.groupEvent}&user_id=${
                    this.state.userID
                  }&photo_id=${this.state.photoID}&ledby=${
                    this.props.userDetail.alias
                  }&photo_hash=${md5}&thumb_value=../static/photos/${
                    this.state.groupEvent
                  }/thumbs/${hashID}&content_type=photo&caption=${quote(
                    this.state.imageCaption.replace(/\n/g, " ")
                  )}&os_type=reactJs`
                )
                .then((res) => {
                  // console.log(res)
                  instance
                    .get(
                      `${this.state.groupEvent}/photohistory_v2.json?limit=25&photo_id=${this.state.photoID}&userid=${this.state.userID}&offset&object_id&approval_type=approved`
                    )
                    .then((res) => {
                      this.setState({
                        photoHistory: res.data,
                        fileUpladed: true,
                        uploadStep: 2,
                        fileUploadLoading: false,
                        imageCaption: "",
                      });
                    });
                })
                .catch((res) => {
                  this.setState({
                    fileNotUploaded: true,
                    fileUploadLoading: false,
                    imageCaption: "",
                  });
                });
            })
            .catch((err) => {
              this.setState({
                fileNotUploaded: true,
                fileUploadLoading: false,
                imageCaption: "",
              });
            });
        });
      }

      // const bmf = new BMF();
      // bmf.md5(this.state.imageFile, (err, md5) => {
      //   this.setState({
      //     hash: md5,
      //   });

      //   let formData = new FormData();
      //   let extension = this.state.fileName.split(".").pop();
      //   let hashID = md5 + "." + extension.toLowerCase();
      //   formData.append("avatar", this.state.imageFile);
      //   formData.append("hash", hashID);
      //   formData.append("uuid", this.state.groupEvent);

      //   instance
      //     .post(`_photo_upload?uuid=${this.state.groupEvent}`, formData)
      //     .then((res) => {
      //       // console.log(res.data)
      //       instance
      //         .get(
      //           `${this.state.groupEvent}/_photolead_v2?value=${
      //             res.data
      //           }&group_id=${this.state.groupEvent}&user_id=${
      //             this.state.userID
      //           }&photo_id=${this.state.photoID}&ledby=${
      //             this.props.userDetail.alias
      //           }&photo_hash=${md5}&thumb_value=../static/photos/${
      //             this.state.groupEvent
      //           }/thumbs/${hashID}&content_type=photo&caption=${quote(
      //             this.state.imageCaption.replace(/\n/g, " ")
      //           )}&os_type=reactJs`
      //         )
      //         .then((res) => {
      //           // console.log(res)
      //           instance
      //             .get(
      //               `${this.state.groupEvent}/photohistory_v2.json?limit=25&photo_id=${this.state.photoID}&userid=${this.state.userID}&offset&object_id&approval_type=approved`
      //             )
      //             .then((res) => {
      //               this.setState({
      //                 photoHistory: res.data,
      //                 fileUpladed: true,
      //                 uploadStep: 2,
      //                 fileUploadLoading: false,
      //                 imageCaption: "",
      //               });
      //             });
      //         })
      //         .catch((res) => {
      //           this.setState({
      //             fileNotUploaded: true,
      //             fileUploadLoading: false,
      //             imageCaption: "",
      //           });
      //         });
      //     })
      //     .catch((err) => {
      //       this.setState({
      //         fileNotUploaded: true,
      //         fileUploadLoading: false,
      //         imageCaption: "",
      //       });
      //     });
      // });
    }
    if (this.state.uploadType == "video") {
      const bmf = new BMF();
      bmf.md5(this.state.imageFile, (err, md5) => {
        this.setState({
          hash: md5,
        });

        let formData = new FormData();
        let extension = this.state.fileName.split(".").pop();
        let hashID = md5 + "." + extension.toLowerCase();
        formData.append("avatar", this.state.imageFile);
        formData.append("hash", hashID);
        formData.append("uuid", this.state.groupEvent);

        instance
          .post(`_video_upload?uuid=${this.state.groupEvent}`, formData)
          .then((res) => {
            console.log(res, "upload video");
            instance
              .get(
                `${this.state.groupEvent}/_photolead_v2?value=..${
                  res.data
                }&group_id=${this.state.groupEvent}&user_id=${
                  this.state.userID
                }&photo_id=${this.state.photoID}&ledby=${
                  this.props.userDetail.alias
                }&photo_hash=${md5}&thumb_value=../static/photos/${
                  this.state.groupEvent
                }/thumbs/${hashID}.jpg&content_type=video&caption=${quote(
                  this.state.imageCaption.replace(/\n/g, " ")
                )}&os_type=reactJs&user_type=reactjs`
              )
              .then((resMataData) => {
                console.log(resMataData, "MataData");
                if (resMataData.data.valid_response != "INVALID") {
                  instance
                    .get(
                      `${this.state.groupEvent}/photohistory_v2.json?limit=25&photo_id=${this.state.photoID}&userid=${this.state.userID}&offset&object_id&approval_type=approved`
                    )
                    .then((response) => {
                      // console.log(res,'all feeds')

                      this.setState({
                        photoHistory: response.data,
                        fileUpladed: true,
                        uploadStep: 2,
                        fileUploadLoading: false,
                        imageCaption: "",
                      });
                    });
                } else {
                  this.setState({
                    fileNotUploaded: true,
                    fileUploadLoading: false,
                    imageCaption: "",
                  });
                }
              })
              .catch((res) => {
                this.setState({
                  fileNotUploaded: true,
                  fileUploadLoading: false,
                  imageCaption: "",
                });
              });
          })
          .catch((err) => {
            this.setState({
              fileNotUploaded: true,
              fileUploadLoading: false,
              imageCaption: "",
            });
          });
      });
    }
  };
  progressBar = React.createRef();

  onVideoEnd = () => {
    let pause = this.state.isPause;

    if (pause) {
      if (this.refs.vidRef) {
        this.refs.vidRef.current_time = 0;
        this.refs.vidRef.play();
      }
    } else {
      this.setNextSlide();
    }
  };
  linkMobileHandler = () => {
    this.setState({
      fileUploadLoading: true,
    });
    instance
      .get(
        `_getprofile.json?user_id=${this.state.userID}&photo_id=${this.state.userID}`
      )
      .then((res) => {
        // console.log(res)
        this.setState({
          userEmail: res.data.user_email,
        });
        instance
          .get(
            `${this.state.groupEvent}/_linkdevice.json?user_email=${res.data.user_email}`
          )
          .then((response) => {
            // console.log(response, 'magin link')
            if (response.data.valid_response == "VALID") {
              this.setState({
                sendLink: true,
                fileUploadLoading: false,
              });
            }
            if (response.data.valid_response == "INVALID") {
              this.setState({
                sendLinkFaild: true,
                fileUploadLoading: false,
              });
            }
          });
      });
    // instance.get(`${this.state.groupEvent}_linkdevice.json?user_email=my@email.com`)
  };

  validURL(myURL) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + //port
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return pattern.test(myURL);
  }

  exitGroupHandler = (groupName) => {
    instance
      .get(
        `${this.state.groupEvent}/_exitgroup?user_id=${this.state.userID}&photo_id=${this.state.photoID}&alias=${this.props.userDetail.alias}`
      )
      .then((res) => {
        let urlPath = window.location.pathname.split("/");
        window.location.pathname =
          "/" + groupName + "/" + urlPath[2] + "/" + urlPath[3];

        // console.log(res, 'test group exit')
      });
  };

  addReaction = (objId, emojiCode) => {
    if (!this.state.add_emoji) return false;
    this.setState({
      add_emoji: false,
    });
    console.log(objId, emojiCode, "hhhh");
    let getReaction = this.state.feed.reaction.all[objId]
      ? this.state.feed.reaction.all[objId]
      : [];
    let liked = false;
    let reaction = getReaction.find((obj) => {
      if (obj.reaction == emojiCode && obj.photo_id == this.state.photoID) {
        liked = true;
        return obj;
      }
    });

    let reactionIndex = getReaction.findIndex(
      (obj) => obj.reaction == emojiCode && obj.photo_id == this.state.photoID
    );
    console.log(reactionIndex);

    console.log(getReaction, liked);
    if (reactionIndex >= 0) {
      instance
        .get(
          `_deletereaction?user_id=${this.state.userID}&photo_id=${this.state.photoID}&alias=${this.props.userDetail.alias}&target_id=${objId}&id_type=content_id&reaction=${emojiCode}&group_id=${this.state.groupEvent}`
        )
        .then((res) => {
          console.log(res, "working");
        });
      let getallReaction = { ...this.state.feed };
      let delete_i = [];
      getallReaction.reaction.all[objId].forEach((item, index) => {
        if (item.reaction == emojiCode && item.photo_id == this.state.photoID) {
          delete_i.push(index);
        }
      });
      delete_i.forEach((item) => {
        getallReaction.reaction.all[objId].splice(item, 1);
      });
      this.setState({
        feed: getallReaction,
        add_emoji: true,
      });
    } else {
      instance
        .get(
          `_addreaction?user_id=${this.state.userID}&photo_id=${this.state.photoID}&alias=${this.props.userDetail.alias}&target_id=${objId}&id_type=content_id&reaction=${emojiCode}&group_id=${this.state.groupEvent}`
        )
        .then((res) => {
          console.log(objId, emojiCode, "test reaction");
          console.log(res, "test reaction");
          let getallReaction = { ...this.state.feed };
          if (getallReaction.reaction.all[objId]) {
            getallReaction.reaction.all[objId].push(res.data.reaction);
            this.setState({
              feed: getallReaction,
              add_emoji: true,
            });
          } else {
            getallReaction.reaction.all[objId] = [];
            getallReaction.reaction.all[objId].push(res.data.reaction);
            this.setState({
              feed: getallReaction,
              add_emoji: true,
            });
          }
        });
    }
  };

  imageSaveHandler = (output) => {
    console.log(output.file);
    this.setState({
      showImageEditor: false,
      uploadImage: toURL(output.file),
      imageFile: output.file,
    });
  };
  welcomeStepHandler = (val, type, checkType = null) => {
    this.closeAlertHandler();
    this.props.stepHandler(val, type, checkType);
  };

  welcomeStepForInput = (e, val, type, mainType) => {
    e.preventDefault();
    this.props.stepHandler(val, type, mainType);
  };

  openSendHandler = () => {
    this.setState({ isPause: true, showSendBox: true });
    this.myInp.focus();
  };
  closeAlertHandler = () => {
    localStorage.removeItem("onboardComplete");
    this.setState({ showAlert: false });
  };

  sendMessageHandler = (data) => {
    this.setState({ isPause: true, showSendBox: true });
    this.myInp.focus();
    console.log(data);
    this.setState({
      selectedSlide: data,
    });
  };

  render() {
    let slide = this.state.slide;
    let url = this.state.url;
    if (slide && url == null) {
      // console.log(slide)
      if (slide.ptr_type == "group_id" && slide.ptr_value.length != 0) {
        if (!this.state.isPause) {
          this.setState({
            isPause: true,
          });
        }

        instance
          .get(
            `${slide.ptr_value}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.userID}`
          )
          .then((response) => {
            // console.log(response, 'test Table')
            if (response.data.user_access === "USER") {
              instance
                .get(
                  `${slide.ptr_value}/_groupuserlist.json?user_id=${this.state.userID}&photo_id=${this.state.photoID}&admin_password=`
                )
                .then((res) => {
                  let url2 = {
                    type: "internal",
                    group: response.data,
                    viewerCnt: res.data.results_cnt,
                  };
                  this.setState({
                    url: url2,
                    isPause: false,
                  });
                  // console.log(url, 'checkGroup')
                });
            }
          });
      }

      if (slide.ptr_type == "http" && slide.ptr_value.length != 0) {
        if (this.validURL(slide.ptr_value)) {
          url = {
            type: "external",
            url: slide.ptr_value,
          };
        }
      }
    }

    // let getReaction = this.state.feed.reaction.all.length !=0 ?   this.state.feed.reaction.all.find(objId => {
    // 	if(objId == slide.object_id){
    // 		return objId
    // 	}
    // }) : []
    let getReaction =
      this.state.feed.reaction.all.length != 0 && slide
        ? this.state.feed.reaction.all[slide.object_id]
          ? this.state.feed.reaction.all[slide.object_id]
          : []
        : [];
    // console.log(getReaction , 'react')

    let headExplodingReaction =
      getReaction.length != 0
        ? getReaction.filter((value) => {
            if (value.reaction == quote("🤯")) {
              return value;
            }
          })
        : [];
    let fireReaction =
      getReaction.length != 0
        ? getReaction.filter((value) => {
            if (value.reaction == quote("🔥")) {
              return value;
            }
          })
        : [];
    let facepalmReaction =
      getReaction.length != 0
        ? getReaction.filter((value) => {
            if (value.reaction == quote("🤦‍♂️")) {
              return value;
            }
          })
        : [];
    let laughingReaction =
      getReaction.length != 0
        ? getReaction.filter((value) => {
            if (value.reaction == quote("😂")) {
              return value;
            }
          })
        : [];

    // console.log(url)

    return (
      <div className="feed-box">
        <ListView
          myphotoID={this.state.photoID}
          // myphotoID={this.state.photoID}
          toggle={this.state.listViewToggle}
          groupSettings={this.props.groupDetail}
          hidelistView={() => this.setState({ listViewToggle: false })}
          feedsDetail={this.state.feed}
          allUsersPhotoId={this.state.allUsersPhotoId}
          openSendHandler={(data) => this.sendMessageHandler(data)}
          headExplodingReaction={headExplodingReaction}
          fireReaction={fireReaction}
          facepalmReaction={facepalmReaction}
          laughingReaction={laughingReaction}
          getAllFields={this.state.getAllFields}
          addReactionHandler={this.addReaction}
          welcomeStepHandler={this.welcomeStepHandler}
          onVideoEnd={this.onVideoEnd}
        />
        {this.state.listViewToggle ? (
          <div className="add-post-btn-box">
            <ul>
              <li className="add-post-btn">
                <button
                  onClick={
                    this.state.getAllFields
                      ? this.state.getAllFields.upload_content
                        ? () => {
                            this.setState({
                              showUploadOptionMobile: true,
                              showAlert: false,
                            });
                            localStorage.removeItem("onboardComplete");
                          }
                        : () =>
                            this.welcomeStepHandler(
                              7,
                              "signup",
                              "upload_content"
                            )
                      : ""
                  }
                >
                  <img src={CameraIconPink} />
                </button>
                {this.state.showUploadOptionMobile ? (
                  <ul>
                    {this.props.groupDetail.video_locked == "true" ? null : (
                      <li
                        className="video-btn"
                        onClick={() => this.fileHandlar("video")}
                      >
                        <p>Upload video</p>
                        <button>
                          <img src={VideoIconNew} />
                        </button>
                      </li>
                    )}

                    <li
                      className="directUploadBox"
                      // onClick={() => this.fileHandlar("image")}
                    >
                      <p>Upload photo</p>
                      <button>
                        <img src={CameraIconNew} />
                      </button>
                      {/* <input type="fsdfsdfsf" /> */}
                      {this.props.groupDetail ? (
                        <input
                          type="file"
                          onChange={(e) => this.uploadHandler(e, "directImage")}
                          name="status"
                          multiple={
                            this.props.groupDetail.user_access == "ADMIN"
                              ? true
                              : false ||
                                this.props.groupDetail.user_access ==
                                  "SUPER_ADMIN"
                              ? true
                              : false ||
                                this.props.groupDetail.user_access ==
                                  "FOMO_ADMIN"
                              ? true
                              : false ||
                                this.props.groupDetail.user_access == "OWNER"
                              ? true
                              : false
                          }
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                      ) : null}
                    </li>
                  </ul>
                ) : null}
              </li>
            </ul>
          </div>
        ) : null}

        {this.state.selectedSlide ? (
          this.state.selectedSlide.os_type != "HTML" ? (
            <div
              className={
                this.state.showSendBox
                  ? "listview-send-box active"
                  : "listview-send-box"
              }
            >
              {this.state.showSendBox ? (
                <div
                  className="backDrop"
                  onClick={() =>
                    this.setState({
                      isPause: false,
                      showSendBox: false,
                      message: "",
                    })
                  }
                ></div>
              ) : null}
              <div
                className={
                  this.state.showSendBox ? "send-box active" : "send-box"
                }
              >
                <div className="post-box">
                  <figure>
                    <img
                      src={
                        baseURL + this.state.selectedSlide.value.split("..")[1]
                      }
                    />
                  </figure>
                  <p>Reply to {this.state.selectedSlide.alias}'s post</p>
                </div>
                <form
                  autoComplete="off"
                  onSubmit={
                    this.state.getAllFields
                      ? this.state.getAllFields.fomomsg_chat
                        ? (e) =>
                            this.submitHandler(
                              e,
                              this.state.selectedSlide.object_id,
                              this.state.selectedSlide.photo_id,
                              this.state.selectedSlide.alias
                            )
                        : (e) =>
                            this.welcomeStepForInput(
                              e,
                              7,
                              "signup",
                              "fomomsg_chat"
                            )
                      : ""
                  }
                >
                  <input
                    ref={(ip) => (this.myInp = ip)}
                    type="text"
                    name="message"
                    placeholder={"Reply to " + slide.alias}
                    value={this.state.message}
                    onChange={this.inputHandler}
                  />
                  <ul className="btn-list">
                    <li>
                      <button type="submit">
                        <i className="ion-android-send" />
                      </button>
                    </li>
                    <li>
                      <i
                        onClick={this.emojiHandlar}
                        className="fa fa-smile-o"
                      ></i>
                      <Picker
                        style={{
                          display: this.state.showEmoji ? "block" : "none",
                        }}
                        onSelect={this.addEmoji}
                      />
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          ) : null
        ) : null}

        {/* <feedList /> */}
        <div className="main-box">
          {this.state.showImageEditor ? (
            <DokaModal
              // utils={['crop', 'filter', 'color', 'markup']}
              // cropResizeKeyCodes={[100, 0, 0, 0]}
              // cropAspectRatioOptions="1:1"
              cropAspectRatio="121167:60550"
              src={this.state.uploadImage}
              onConfirm={this.imageSaveHandler}
              onCancel={() => this.setState({ showImageEditor: false })}
            />
          ) : (
            ""
          )}

          {/* <EditImage /> */}

          <div
            className={
              this.state.replyTo ? "success-message active" : "success-message"
            }
          >
            <span>
              <img src={checkIcon} />
            </span>
            <p className="desktop-text">Reply sent to {this.state.replyTo}</p>
            <p className="mobile-text">reply sent!</p>
            <button
              onClick={() => this.setState({ replyTo: "" })}
              type="button"
            >
              <img src={closeIcon} />
            </button>
          </div>
          <Popup
            click={() => this.setState({ exitGroupPopup: false })}
            toggleHandler={this.state.exitGroupPopup}
            type="file-popup"
          >
            <div className="box">
              <div className="file-uploaded">
                <figure>
                  <img src={questionIcon} />
                </figure>
                <h6>Leave current event?</h6>
                <p>You’re about to go to a diferent event:</p>
                <ul>
                  <li>
                    <button
                      type="button"
                      onClick={() =>
                        this.setState({
                          exitGroupPopup: false,
                          exitGroupName: "",
                          exitGroupId: "",
                        })
                      }
                      className="btn btn-simple"
                    >
                      Stay
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn btn-pink"
                      onClick={() =>
                        this.exitGroupHandler(this.state.exitGroupId)
                      }
                    >
                      Leave
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Popup>
          <Popup
            click={() => this.setState({ sendLink: false })}
            toggleHandler={this.state.sendLink}
            type="file-popup"
          >
            <div className="box">
              <div className="file-uploaded">
                <figure>
                  <img src={checkIconSVG} />
                </figure>
                <h6>Nice!</h6>
                <p>
                  A magic link has been sent to the email "
                  {this.state.userEmail}". Please open the email on your mobile
                  device and follow the instructions to upload photos from your
                  mobile!
                </p>
                <button
                  type="button"
                  onClick={() => this.setState({ sendLink: false })}
                  className="btn btn-pink"
                >
                  Close
                </button>
              </div>
            </div>
          </Popup>
          <Popup
            click={() => this.setState({ sendLinkFaild: false })}
            toggleHandler={this.state.sendLinkFaild}
            type="file-popup"
          >
            <div className="box">
              <div className="file-uploaded">
                <figure>
                  <img src={errorIcon} />
                </figure>
                <h6>Oops!</h6>
                <p>
                  Something went wrong sending the mobile device magic-link
                  email. Please try again
                </p>
                <button
                  type="button"
                  onClick={() => this.setState({ sendLinkFaild: false })}
                  className="btn btn-pink"
                >
                  Close
                </button>
              </div>
            </div>
          </Popup>
          <Popup
            click={this.fileHandlar}
            toggleHandler={this.state.filePopup}
            type="file-popup"
          >
            <div className="box">
              {this.state.uploadStep == 0 ? (
                <div className="file-uploadBox">
                  {this.state.uploadType == "image" ? (
                    <>
                      {this.props.groupDetail ? (
                        <input
                          type="file"
                          onChange={(e) => this.uploadHandler(e)}
                          name="status"
                          multiple={
                            this.props.groupDetail.user_access == "ADMIN"
                              ? true
                              : false ||
                                this.props.groupDetail.user_access ==
                                  "SUPER_ADMIN"
                              ? true
                              : false ||
                                this.props.groupDetail.user_access ==
                                  "FOMO_ADMIN"
                              ? true
                              : false ||
                                this.props.groupDetail.user_access == "OWNER"
                              ? true
                              : false
                          }
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                      ) : null}
                      <div className="desktop-group">
                        <div className="upload-placeholder">
                          <img src={uploadIcon} />
                          <p>Choose a file or drag it here</p>
                        </div>
                      </div>
                      <div className="mobile-group">
                        <button type="button">
                          <img src={uploadIcon} /> Upload Files
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        onChange={(e) => this.uploadHandler(e)}
                        name="status"
                        accept="video/*,.mov"
                      />
                      <div className="upload-placeholder">
                        <img src={uploadIcon} />
                        <p>Choose a file or drag it here</p>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
              {this.state.uploadStep == 1 ? (
                <div className="image-preview">
                  <span
                    onClick={() =>
                      this.setState({
                        editImageResult: null,
                        uploadStep: 0,
                        uploadImage: "",
                        imageFile: "",
                        fileName: "",
                        imageCaption: "",
                        fileNotUploaded: false,
                      })
                    }
                    className="btn-back"
                  >
                    <i className="ion-arrow-left-c"></i>
                  </span>
                  <h6>Upload to FOMO Stream</h6>
                  {this.state.uploadType == "image" ? (
                    <div className="image-list">
                      {this.state.formatedFiles.length != 1 ? (
                        this.state.formatedFiles.map((file, key) => (
                          <figure key={key} className="multi">
                            <img src={file.imageUrl} />
                          </figure>
                        ))
                      ) : (
                        <figure>
                          <img src={this.state.uploadImage} />
                        </figure>
                      )}
                    </div>
                  ) : (
                    <figure>
                      <video
                        playsInline={true}
                        loop={true}
                        muted={true}
                        autoPlay={true}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <source src={this.state.uploadImage} type="video/mp4" />
                      </video>
                    </figure>
                  )}

                  <form onSubmit={this.uploadImageHandler}>
                    <div className="form-group">
                      <textarea
                        name="imageCaption"
                        value={this.state.imageCaption}
                        onChange={this.captionHandler}
                        placeholder="(Optional) Write a caption..."
                      ></textarea>
                    </div>
                    <div className="btn-box">
                      {this.state.fileNotUploaded ? (
                        <div className="fail-detail">
                          <img src={errorIcon} />
                          <p>Couldn’t upload your content. Try again</p>
                        </div>
                      ) : null}
                      {/* {this.state.fileUploadLoading ? <div className="file-loader">
											<img src={loadingImg} /><p>Uploading</p>
										</div> : null} */}

                      <p>{200 - this.state.imageCaption.length}</p>
                      <button
                        type="submit"
                        disabled={this.state.fileUploadLoading}
                        className="btn btn-pink"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}
              {this.state.uploadStep == 2 ? (
                <div className="file-uploaded">
                  <figure>
                    <img src={checkIconSVG} />
                  </figure>
                  <h6>Nice!</h6>
                  <p>Your content has been successfully uploaded</p>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        uploadStep: 0,
                        showUploadOptionMobile: false,
                        filePopup: false,
                        imageCaption: "",
                        uploadImage: "",
                        imageFile: "",
                        fileName: "",
                      })
                    }
                    className="btn btn-pink"
                  >
                    Close
                  </button>
                </div>
              ) : null}
            </div>
          </Popup>

          <Loading show={this.state.fileUploadLoading} />
          {this.state.slide ? (
            <div className="slider-box">
              <div
                style={{
                  display: this.state.showUploadOption ? "block" : "none",
                }}
                onClick={this.uploadOption}
                className="back-drop"
              ></div>
              <div
                className="add-post"
                onClick={
                  this.state.getAllFields
                    ? this.state.getAllFields.upload_content
                      ? this.uploadOption
                      : () =>
                          this.welcomeStepHandler(7, "signup", "upload_content")
                    : ""
                }
              >
                <span className="icon">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                <p>Add content</p>
                <ul
                  style={{
                    display: this.state.showUploadOption ? "block" : "none",
                  }}
                >
                  <li onClick={() => this.fileHandlar("image")}>
                    <i className="fa fa-upload"></i> Upload from computer
                  </li>
                  {this.props.groupDetail.video_locked == "true" ? null : (
                    <li onClick={() => this.fileHandlar("video")}>
                      <i className="fa fa-upload"></i> Upload a video
                    </li>
                  )}

                  <li onClick={this.linkMobileHandler}>
                    <i className="fa fa-mobile"></i> Upload with mobile device
                  </li>
                </ul>
              </div>

              {this.state.slide ? (
                <div
                  style={{ zIndex: this.state.showSendBox ? "9999" : "1" }}
                  className="image-slider"
                >
                  <div
                    className={"status-box active"}
                    onMouseEnter={() => this.setState({ isPause: true })}
                    onMouseLeave={() => this.setState({ isPause: false })}
                  >
                    {/* <div className="progress-bar">
											<span id="prog" ref={this.progressBar} style={{ 'width': this.state.barWidth }} className="active"></span>
										</div> */}
                    {/* {console.log(slide)} */}
                    {this.props.groupDetail ? (
                      <div className="user-detail">
                        {this.props.groupDetail.rcv_ledby_on == "true" ? (
                          <figure>
                            <img
                              src={
                                slide
                                  ? this.state.allUsersPhotoId[slide.photo_id]
                                    ? this.state.allUsersPhotoId[slide.photo_id]
                                        .thumb_value
                                      ? baseURL +
                                        this.state.allUsersPhotoId[
                                          slide.photo_id
                                        ].thumb_value.split("..")[1]
                                      : avtarIcon
                                    : avtarIcon
                                  : avtarIcon
                              }
                              alt="user"
                            />
                            {/* <img src={avtarIcon} alt='user' /> */}
                          </figure>
                        ) : null}
                        <div className="text-box">
                          {this.props.groupDetail.rcv_ledby_on == "true" ? (
                            <p>{slide.alias}</p>
                          ) : null}
                          {this.props.groupDetail.rcv_captions_on == "true" ? (
                            slide.caption ? (
                              <span>{unicodeToChar(slide.caption)}</span>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {url ? (
                      <div className="join-url">
                        {url.type == "internal" ? (
                          <>
                            <button
                              className="btn"
                              onClick={() =>
                                this.setState({
                                  exitGroupPopup: true,
                                  exitGroupName: url.group.group_name,
                                  exitGroupId: url.group.group_id,
                                })
                              }
                            >
                              Go to linked event
                            </button>
                            <div className="tooltip">
                              {url.group.group_name ? (
                                <h5>{url.group.group_name}</h5>
                              ) : null}
                              {url.group.event_location ? (
                                <span>{url.group.event_location}</span>
                              ) : null}
                              {url.group.livestream.ls_url ? (
                                <span className="live-btn">LIVE</span>
                              ) : null}
                              <span>
                                <img src={viewIcon} /> {url.viewerCnt} viewers
                              </span>
                              {url.group.group_desc ? (
                                <p>{url.group.group_desc}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <a
                              className="btn"
                              target="_blank"
                              href={`${url.url}?time=${new Date().getTime()}`}
                            >
                              <img src={webIcon} /> Open external link
                            </a>
                            <div className="tooltip">
                              {url.url ? (
                                <h5>{url.url.toLowerCase()}</h5>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="image-box">
                      <figure>
                        {slide.content_type == "video" ? (
                          <video
                            ref="vidRef"
                            playsInline={true}
                            muted={true}
                            autoPlay={true}
                            style={{ width: "100%", height: "100%" }}
                            onEnded={this.onVideoEnd}
                            poster={baseURL + slide.thumb_value.split("..")[1]}
                          >
                            <source
                              src={baseURL + slide.value.split("..")[1]}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            src={baseURL + slide.value.split("..")[1]}
                            alt="status"
                          />
                        )}
                      </figure>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.showReactBox ? "react-box active" : "react-box"
                    }
                  >
                    <div className="mobile-box">
                      <div className="inner-mobile-box">
                        <button
                          type="botton"
                          onClick={this.openSendHandler}
                          className="messsage-btn"
                        >
                          <img src={SendButton} /> message
                        </button>

                        <ul>
                          <li
                            className={
                              headExplodingReaction.find((obj) => {
                                if (obj.photo_id == this.state.photoID) {
                                  return "active";
                                }
                              })
                                ? "active"
                                : ""
                            }
                            onClick={
                              this.state.getAllFields
                                ? this.state.getAllFields.reactions
                                  ? () =>
                                      this.addReaction(
                                        slide.object_id,
                                        quote("🤯")
                                      )
                                  : () =>
                                      this.welcomeStepHandler(
                                        7,
                                        "signup",
                                        "reactions"
                                      )
                                : ""
                            }
                          >
                            <img src={headExploding} />
                            <span>{headExplodingReaction.length}</span>
                          </li>
                          <li
                            className={
                              fireReaction.find((obj) => {
                                if (obj.photo_id == this.state.photoID) {
                                  return "active";
                                }
                              })
                                ? "active"
                                : ""
                            }
                            onClick={
                              this.state.getAllFields
                                ? this.state.getAllFields.reactions
                                  ? () =>
                                      this.addReaction(
                                        slide.object_id,
                                        quote("🔥")
                                      )
                                  : () =>
                                      this.welcomeStepHandler(
                                        7,
                                        "signup",
                                        "reactions"
                                      )
                                : ""
                            }
                          >
                            <img src={fireIcon} />
                            <span>{fireReaction.length}</span>
                          </li>
                          <li
                            className={
                              facepalmReaction.find((obj) => {
                                if (obj.photo_id == this.state.photoID) {
                                  return "active";
                                }
                              })
                                ? "active"
                                : ""
                            }
                            onClick={
                              this.state.getAllFields
                                ? this.state.getAllFields.reactions
                                  ? () =>
                                      this.addReaction(
                                        slide.object_id,
                                        quote("🤦‍♂️")
                                      )
                                  : () =>
                                      this.welcomeStepHandler(
                                        7,
                                        "signup",
                                        "reactions"
                                      )
                                : ""
                            }
                          >
                            <img src={facepalmIcon} />
                            <span>{facepalmReaction.length}</span>
                          </li>
                          <li
                            className={
                              laughingReaction.find((obj) => {
                                if (obj.photo_id == this.state.photoID) {
                                  return "active";
                                }
                              })
                                ? "active"
                                : ""
                            }
                            onClick={
                              this.state.getAllFields
                                ? this.state.getAllFields.reactions
                                  ? () =>
                                      this.addReaction(
                                        slide.object_id,
                                        quote("😂")
                                      )
                                  : () =>
                                      this.welcomeStepHandler(
                                        7,
                                        "signup",
                                        "reactions"
                                      )
                                : ""
                            }
                          >
                            <img src={laughingIcon} />
                            <span>{laughingReaction.length}</span>
                          </li>
                        </ul>
                      </div>
                      <button
                        type="button"
                        onClick={() => this.setState({ listViewToggle: true })}
                        className="see-more-btn"
                      >
                        <img src={listIcon} />
                        See more
                      </button>
                    </div>
                    {this.state.slide.os_type != "HTML" ? (
                      <>
                        <div
                          onClick={this.openSendHandler}
                          className="open-send-box"
                        >
                          <img src={SendIcon} />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div
                      className={
                        this.state.showSendBox
                          ? "bottam-navigation active"
                          : "bottam-navigation"
                      }
                    >
                      {this.state.showAlert ? (
                        <div className="alert-popup">
                          <div className="inner-box">
                            <figure>
                              <img src={CameraIconWhite} />
                            </figure>
                            <h2>Get seen!</h2>
                            <p>
                              Wanna get seen on the big screen? Share your pics
                              with the audience!{" "}
                            </p>
                            <button
                              onClick={this.closeAlertHandler}
                              className="btn"
                            >
                              Cool, got it!
                            </button>
                          </div>
                        </div>
                      ) : null}

                      <div
                        className={
                          this.state.showUploadOptionMobile
                            ? "back-drop-black active"
                            : "back-drop-black"
                        }
                        onClick={() =>
                          this.setState({ showUploadOptionMobile: false })
                        }
                      ></div>
                      <ul className="nav">
                        {this.state.showAlert ? null : (
                          <li
                            style={{
                              opacity: this.state.showUploadOptionMobile
                                ? 0.2
                                : 1,
                            }}
                          >
                            {this.props.unSeenConcat > 0 ? (
                              <span className="unseenMsg">
                                {this.props.unSeenConcat}
                              </span>
                            ) : null}
                            <button onClick={this.props.openChatHandler}>
                              <img src={messageIcon} />
                            </button>
                          </li>
                        )}
                        <li className="add-post-btn">
                          <button
                            onClick={
                              this.state.getAllFields
                                ? this.state.getAllFields.upload_content
                                  ? () => {
                                      this.setState({
                                        showUploadOptionMobile: true,
                                        showAlert: false,
                                      });
                                      localStorage.removeItem(
                                        "onboardComplete"
                                      );
                                    }
                                  : () =>
                                      this.welcomeStepHandler(
                                        7,
                                        "signup",
                                        "upload_content"
                                      )
                                : ""
                            }
                          >
                            <img src={CameraIconPink} />
                          </button>
                          {this.state.showUploadOptionMobile ? (
                            <ul>
                              {this.props.groupDetail.video_locked ==
                              "true" ? null : (
                                <li
                                  className="video-btn"
                                  onClick={() => this.fileHandlar("video")}
                                >
                                  <p>Upload video</p>
                                  <button>
                                    <img src={VideoIconNew} />
                                  </button>
                                </li>
                              )}

                              <li
                                className="directUploadBox"
                                // onClick={() => this.fileHandlar("image")}
                              >
                                <p>Upload photo</p>
                                <button>
                                  <img src={CameraIconNew} />
                                </button>
                                {/* <input type="fsdfsdfsf" /> */}
                                {this.props.groupDetail ? (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      this.uploadHandler(e, "directImage")
                                    }
                                    name="status"
                                    multiple={
                                      this.props.groupDetail.user_access ==
                                      "ADMIN"
                                        ? true
                                        : false ||
                                          this.props.groupDetail.user_access ==
                                            "SUPER_ADMIN"
                                        ? true
                                        : false ||
                                          this.props.groupDetail.user_access ==
                                            "FOMO_ADMIN"
                                        ? true
                                        : false ||
                                          this.props.groupDetail.user_access ==
                                            "OWNER"
                                        ? true
                                        : false
                                    }
                                    accept="image/x-png,image/gif,image/jpeg"
                                  />
                                ) : null}
                              </li>
                            </ul>
                          ) : null}
                        </li>
                      </ul>
                      {this.state.slide.os_type != "HTML" ? (
                        <>
                          {this.state.showSendBox ? (
                            <div
                              className="backDrop"
                              onClick={() =>
                                this.setState({
                                  isPause: false,
                                  showSendBox: false,
                                  message: "",
                                })
                              }
                            ></div>
                          ) : null}
                          <div
                            className={
                              this.state.showSendBox
                                ? "send-box active"
                                : "send-box"
                            }
                          >
                            <div className="post-box">
                              <figure>
                                <img
                                  src={baseURL + slide.value.split("..")[1]}
                                />
                              </figure>
                              <p>Reply to {slide.alias}'s post</p>
                            </div>
                            <form
                              autoComplete="off"
                              onSubmit={
                                this.state.getAllFields
                                  ? this.state.getAllFields.fomomsg_chat
                                    ? (e) =>
                                        this.submitHandler(
                                          e,
                                          slide.object_id,
                                          slide.photo_id,
                                          slide.alias
                                        )
                                    : (e) =>
                                        this.welcomeStepForInput(
                                          e,
                                          7,
                                          "signup",
                                          "fomomsg_chat"
                                        )
                                  : ""
                              }
                            >
                              <input
                                ref={(ip) => (this.myInp = ip)}
                                type="text"
                                name="message"
                                placeholder={"Reply to " + slide.alias}
                                value={this.state.message}
                                onChange={this.inputHandler}
                              />
                              <ul className="btn-list">
                                <li>
                                  <button type="submit">
                                    <i className="ion-android-send" />
                                  </button>
                                </li>
                                <li>
                                  <i
                                    onClick={this.emojiHandlar}
                                    className="fa fa-smile-o"
                                  ></i>
                                  <Picker
                                    style={{
                                      display: this.state.showEmoji
                                        ? "block"
                                        : "none",
                                    }}
                                    onSelect={this.addEmoji}
                                  />
                                </li>
                              </ul>
                            </form>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="no-slide">
              <figure>
                <img src={Nofeeds} />
              </figure>
              <div
                style={{
                  display: this.state.showUploadOption ? "block" : "none",
                }}
                onClick={this.uploadOption}
                className="back-drop"
              ></div>
              <div
                className="add-post"
                onClick={
                  this.state.getAllFields
                    ? this.state.getAllFields.upload_content
                      ? this.uploadOption
                      : () =>
                          this.welcomeStepHandler(7, "signup", "upload_content")
                    : ""
                }
              >
                <span className="icon">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                <p>Add content</p>
                <ul
                  style={{
                    display: this.state.showUploadOption ? "block" : "none",
                  }}
                >
                  <li onClick={() => this.fileHandlar("image")}>
                    <i className="fa fa-upload"></i> Upload from computer
                  </li>
                  {this.props.groupDetail.video_locked == "true" ? null : (
                    <li onClick={() => this.fileHandlar("video")}>
                      <i className="fa fa-upload"></i> Upload a video
                    </li>
                  )}

                  <li onClick={this.linkMobileHandler}>
                    <i className="fa fa-mobile"></i> Upload with mobile device
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    stepHandler: (step, type, checkType) =>
      dispatch(actions.stepHandler(step, type, checkType)),
    addChat: (user) => dispatch(actions.addChat(user)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(feed);
