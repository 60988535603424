import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import AdminLayout from '../../hoc/adminLayout/adminLayout';
import loginImg from '../../assets/images/login-img.png';
import { Link } from 'react-router-dom';
import { instance } from '../../axios.js';
import Loading from '../../components/loading/loading';


class Registration extends Component {
	state = {
		email: '',
		confirm_email:'',
		formError: '',
		success: false,
		loading: false,
	}
	inputHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	componentDidMount () {
			 let email = localStorage.getItem('not_email')
			 localStorage.removeItem('not_email')
			 if(email){
				this.setState({
					email:email
				})

			 }
             
	}


	submitHandler = (e) => {
		e.preventDefault();

		this.setState({
			loading: true,
			formError: ''
		})


		if(this.state.email != this.state.confirm_email){
			this.setState({
				formError: "The emails you entered do not match",
				loading: false,
			})
		}else{
            instance.get(`_generateuuids?idstr=FRJS&alias=MyName`)
			.then(res => {
				localStorage.setItem('tempUser_id', res.data.user_id);
				localStorage.setItem('tempPhoto_id', res.data.photo_id);
				instance.get(`_updateprofile.json?user_id=${res.data.user_id}&photo_id=${res.data.photo_id}&user_email=${this.state.email}`)
					.then(res2 => {
						if (res2.data.valid_response === 'VALID') {

							localStorage.setItem('photo_id', res.data.photo_id)
							localStorage.setItem('user_email', this.state.email)
							localStorage.setItem('user_id', res.data.user_id);
							this.setState(
								{
									success: true,
									loading: false,
								}
							)


						}

						if (res2.data.valid_response === 'EXISTS') {
							this.setState({
								formError: 'This is email already exists',
								loading: false,
							})

						}
					})
			})

		}
		
		


	}


	render() {

		return (
			<AdminLayout>
				<Loading show={this.state.loading}/>
				{this.state.success ? <Redirect to='/_profile' /> : ''}
				<div className="authentication-group sign-up">
					<h1>{window.location.hostname=='theplaya.org'?'The Playa':'FOMO'}</h1>
					<h2>Create your account</h2>
					<h3>Make sure your email below is correct to create an account</h3>

					<form autoComplete="off" onSubmit={this.submitHandler}>
						<div className="form-group">
							<input autoComplete="off" type="text" className="form-control" name="email" onChange={this.inputHandler} value={this.state.email} placeholder="Email" />
						</div>

						<div className="form-group">
							<input autoComplete="off" type="text" className="form-control" name="confirm_email" onChange={this.inputHandler} value={this.state.confirm_email} placeholder="Confirm Email" />
						</div>

						<div className="form-group">
							<button type="submit" className="btn">Create an Account </button>
						</div>
					</form>
					<ul>
						<li><Link to="/_login"><><span>Already have a {window.location.hostname=='theplaya.org'?'The Playa':'FOMO'} account?  </span> Log in</></Link></li>
					</ul>
					{this.state.formError ? <div className="form-message"><p>{this.state.formError}</p></div> : null}

				</div>
			</AdminLayout>
		)
	}
}
export default Registration;
