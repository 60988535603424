import React, { Component } from "react";
import "./conversation.scss";
import avtarIcon from "../../assets/images/avtar.png";
import { instance, baseURL } from "../../axios";
import { channelOne, channelTwo, privateChat } from "../../pusher";
import Moment from "moment";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { unicodeToChar, quote } from "../../shared/utility";
import ViewProfile from "../viewProfile/viewProfile";
import { connect } from "react-redux";
import hideIcon from "../../assets/images/hide-icon.svg";
import * as actions from "../../store/actions";
import CloseIcon from "../../assets/images/close-icon-mobile.png";
import BackIcon from "../../assets/images/back-icon-mobile.png";

class conversation extends Component {
  state = {
    getAllFields: "",
    invalidSingleChat: [],
    message: "",
    userList: [],
    tableUnseenMessage: 0,
    eventUnseenMessage: 0,
    showGroupDetail: false,
    viewDetail: false,
    profileDetail: "",
    sendMessage: true,
    chat: [],
    eventChat: [],
    linkRegexp: new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ),
  };
  outerConversation = React.createRef();
  innerConversation = React.createRef();

  // componentDidUpdate(prevProps, prevState) {
  // 	if (prevProps.chatDetail !== this.props.chatDetail) {

  // 		let userList = [...this.state.userList];
  // 		let findUser = userList.findIndex(user => user.from_photo_id == this.props.chatDetail.photo_id)
  // 		console.log(this.props.chatDetail)
  // 		console.log(findUser)
  // 		if (findUser == -1) {

  // 			instance.get(`_fomomsg_getprofile?user_id=${userID}&photo_id=${this.props.chatDetail.from_photo_id}&notification_flag=true`)
  // 				.then(res => {
  // 					let getNewUser = this.props.chatDetail
  // 					getNewUser.userAlias = this.props.chatDetail.alias
  // 					getNewUser.userPhotoId = this.props.chatDetail.from_photo_id
  // 					getNewUser.userAvtar = res.data.thumb_value
  // 					getNewUser.msg_text =
  // 					userList.unshift(getNewUser);
  // 					this.setState({
  // 						userList: userList,
  // 					});
  // 				})
  // 			if (this.state.activeChat == this.props.chatDetail.from_photo_id) {
  // 				instance.get(`_fomomsg_fetchmsgs?user_id=${this.state.userID}&photo_id=${this.state.photoID}&object_id=${this.state.photoID}@${this.props.chatDetail.from_photo_id}&msg_type=fomo_chat&search_struct[limit]=50&search_struct[offset]=0&search_struct[last_object_id]=&search_struct[search_dir]=$gte&ptr_dic_flag=true&search_struct[include_flag]`)
  // 					.then(res => {
  // 						this.setState({
  // 							singlechat: res.data.results,
  // 						})
  // 					})
  // 			}

  // 		}

  // 		else {
  // 			userList[findUser].msg_text = this.props.chatDetail.msg_text;
  // 			if (this.state.activeChat == this.props.chatDetail.from_photo_id) {
  // 				instance.get(`_fomomsg_fetchmsgs?user_id=${this.state.userID}&photo_id=${this.state.photoID}&object_id=${this.state.photoID}@${this.props.chatDetail.from_photo_id}&msg_type=fomo_chat&search_struct[limit]=50&search_struct[offset]=0&search_struct[last_object_id]=&search_struct[search_dir]=$gte&ptr_dic_flag=true&search_struct[include_flag]`)
  // 					.then(res => {
  // 						this.setState({
  // 							singlechat: res.data.results,
  // 						})
  // 					})
  // 			}
  // 			this.setState({
  // 				userList: userList,
  // 			});
  // 		}

  // 	}
  // }

  componentDidMount() {
    this.setState({
      guestUser: JSON.parse(localStorage.getItem("guestUser")),
    });
    channelTwo.bind_global((event, data) => {
      if (
        data.type == "groupchat-msg" ||
        data.type == "groupchat-delete" ||
        data.type == "groupchat-clear"
      )
        if (data.alias) {
          let chatHistory = [...this.state.chat];
          let chatMsg = data;
          if (
            chatMsg.msg_text.includes("dev.fomospaces.com") ||
            chatMsg.msg_text.includes("staging.fomospaces.com")
          ) {
            let showUrl = chatMsg.msg_text.split("/")[3];
            instance
              .get(
                `${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
              )
              .then((res) => {
                if (res.data.userstatus == "INVALID") {
                  chatMsg.groupStatusFailed = true;
                  chatHistory.push(chatMsg);
                  this.setState({
                    chat: chatHistory,
                  });
                } else {
                  chatMsg.groupName = res.data.group_name;
                  chatHistory.push(chatMsg);
                  this.setState({
                    chat: chatHistory,
                  });
                }
              });
          } else {
            chatHistory.push(chatMsg);
            this.setState({
              chat: chatHistory,
            });
          }

          if (
            data.photo_id !== this.state.photoID &&
            this.state.activeGroup !== this.state.groupTable
          ) {
            let totalUnseenMsg = this.state.tableUnseenMessage + 1;
            this.setState((prevState) => ({
              tableUnseenMessage: prevState.tableUnseenMessage + 1,
            }));
            this.props.tableUnseenMessage(totalUnseenMsg);
          }
          try {
            this.outerConversation.current.scrollTop =
              this.innerConversation.current.clientHeight;
          } catch (error) {}
        }
    });

    channelOne.bind_global((event, data) => {
      if (data.approval_status == "group_logo") {
        this.setState({
          eventImage: baseURL + data.thumb_value.split("..")[1],
        });
      }
      if (
        data.type == "groupchat-msg" ||
        data.type == "groupchat-delete" ||
        data.type == "groupchat-clear"
      ) {
        if (data.alias) {
          let chatHistory = [...this.state.eventChat];
          let chatMsg = data;
          if (
            chatMsg.msg_text.includes("dev.fomospaces.com") ||
            chatMsg.msg_text.includes("staging.fomospaces.com")
          ) {
            let showUrl = chatMsg.msg_text.split("/")[3];
            instance
              .get(
                `${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
              )
              .then((res) => {
                if (res.data.userstatus == "INVALID") {
                  chatMsg.groupStatusFailed = true;
                  chatHistory.push(chatMsg);
                  this.setState({
                    eventChat: chatHistory,
                  });
                } else {
                  chatMsg.groupName = res.data.group_name;
                  chatHistory.push(chatMsg);
                  this.setState({
                    eventChat: chatHistory,
                  });
                }
              });
          } else {
            chatHistory.push(chatMsg);
            this.setState({
              eventChat: chatHistory,
            });
          }

          if (
            data.photo_id !== this.state.photoID &&
            this.state.activeGroup !== this.state.groupEvent
          ) {
            let totalUnseenMsg = this.state.eventUnseenMessage + 1;
            this.setState((prevState) => ({
              eventUnseenMessage: prevState.eventUnseenMessage + 1,
            }));
            this.props.eventUnseenMessage(totalUnseenMsg);
          }
          try {
            this.outerConversation.current.scrollTop =
              this.innerConversation.current.clientHeight;
          } catch (error) {}
        }
      }
    });
    let userID = localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : "";
    let photoID = localStorage.getItem("photo_id")
      ? localStorage.getItem("photo_id")
      : "";
    let groupTable = window.location.pathname.split("/")[3];
    let groupEvent = window.location.pathname.split("/")[1];
    this.setState({
      userID: userID,
      photoID: photoID,
      groupTable: groupTable,
      groupEvent: groupEvent,
      activeGroup: groupTable,
    });
    if (window.innerWidth < 767) {
      this.setState({
        activeGroup: "",
      });
    }

    privateChat.bind_global((event, data) => {
      if (event == "fomo_msg-fast") {
        let userList = [...this.state.userList];
        let getPhotoIds = data.convo_id.split("@");
        let userPhotoId = getPhotoIds.find((photoId) => {
          if (photoId != photoID) {
            return photoId;
          }
        });
        let findUser = userList.findIndex(
          (user) => user.userPhotoId == userPhotoId
        );
        if (findUser == -1) {
          instance
            .get(
              `_fomomsg_getprofile?user_id=${userID}&photo_id=${userPhotoId}&notification_flag=true`
            )
            .then((res) => {
              let getNewUser = data;
              getNewUser.userAlias = res.data.alias;
              getNewUser.userPhotoId = res.data.photo_id;
              getNewUser.userAvtar = res.data.thumb_value;
              getNewUser.msg_text = data.msg_text;
              getNewUser.msgBy =
                data.from_photo_id == this.state.photoID
                  ? "you"
                  : res.data.alias;

              userList.unshift(getNewUser);
              this.setState({
                userList: userList,
              });
            });

          // instance.get(`_fomomsg_fetchmsgs?user_id=${this.state.userID}&photo_id=${this.state.photoID}&object_id=${this.state.photoID}@${userPhotoId}&msg_type=fomo_chat&search_struct[limit]=50&search_struct[offset]=0&search_struct[last_object_id]=&search_struct[search_dir]=$gte&ptr_dic_flag=true&search_struct[include_flag]`)
          // 	.then(res => {
          // 		this.setState({
          // 			singlechat: res.data.results,
          // 		})
          // 	})
        } else {
          let chatNotification = [...this.state.userList];
          if (data.convo_id) {
            chatNotification.find((user) => {
              let getPhotoIds = data.convo_id.split("@");
              let userPhotoId = getPhotoIds.find((photoId) => {
                if (photoId != photoID) {
                  return photoId;
                }
              });
              if (userPhotoId == user.userPhotoId) {
                user.msg_text = data.msg_text;
                user.msgBy =
                  data.from_photo_id == this.state.photoID
                    ? "you"
                    : user.userAlias;
              }
            });
            this.setState({
              userList: chatNotification,
            });
            let totalUnseenMsg = 0;
            if (this.state.activeChat != data.from_photo_id) {
              chatNotification.map((user) => {
                if (user.convo_id == data.convo_id) {
                  if (this.state.photoID !== data.from_photo_id) {
                    totalUnseenMsg =
                      totalUnseenMsg +
                      (user.unseenMsg == undefined
                        ? 0 + 1
                        : user.unseenMsg + 1);
                    user.unseenMsg =
                      data.reply.msg_type == "fomo_msg"
                        ? user.unseenMsg
                        : user.unseenMsg == undefined
                        ? 0 + 1
                        : user.unseenMsg + 1;
                    this.props.userUnseenMsg(1);
                  }
                }
              });

              this.setState({
                userList: chatNotification,
              });
            }
            if (this.state.activeChat) {
              if (this.state.activeUser.photo_id == userPhotoId) {
                let chatHistory = [...this.state.singlechat];
                // chatHistory.push(data);
                let chatdetail = data;
                if (
                  chatdetail.msg_text.includes("dev.fomospaces.com") ||
                  chatdetail.msg_text.includes("staging.fomospaces.com")
                ) {
                  let showUrl = chatdetail.msg_text.split("/")[3];
                  instance
                    .get(
                      `${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
                    )
                    .then((res) => {
                      if (res.data.userstatus == "INVALID") {
                        chatdetail.groupName = "";
                        chatHistory.push(chatdetail);
                        let invalids = [...this.state.invalidSingleChat];
                        let getInvalidIndex = chatHistory.length - 1;
                        invalids.push(getInvalidIndex);

                        this.setState({
                          singlechat: chatHistory,
                          invalidSingleChat: invalids,
                        });
                      } else {
                        chatdetail.groupName = res.data.group_name;
                        chatHistory.push(chatdetail);
                        this.setState({
                          singlechat: chatHistory,
                        });
                      }
                    });
                } else {
                  chatHistory.push(chatdetail);
                  this.setState({
                    singlechat: chatHistory,
                  });
                }

                // this.setState({
                // 	singlechat: chatHistory,
                // });
                try {
                  this.outerConversation.current.scrollTop =
                    this.innerConversation.current.clientHeight;
                } catch (error) {}
              }
            }
          }
        }
      }
    });
    this.fetchMessage(userID, photoID);

    // instance
    // 	.get(`_fomomsg_fetchmsglist?user_id=${userID}&photo_id=${photoID}&msg_type=fomo_chat&ptr_dic_flag=true`)
    // 	.then((response) => {
    // 		let users = response.data.results.filter((user) => {
    // 			if (user.from_alias !== "") {
    // 				return user;
    // 			}
    // 		});
    // 		users.map((user) =>
    // 			instance
    // 				.get(
    // 					`_fomomsg_getprofile?user_id=${userID}&photo_id=${user.from_photo_id}&notification_flag=true`
    // 				)
    // 				.then((usersAvatar) => {
    // 					user.thumb_value = usersAvatar.data.thumb_value;
    // 				})
    // 		);
    // 		let removeUsersDuplicacy = (users) =>
    // 			users.filter((v, i) => users.indexOf(v) === i);
    // 		let storedUser = removeUsersDuplicacy(users);
    // 		// this.setState({
    // 		// 	userList: storedUser,
    // 		// });
    // 	});

    instance
      .get(
        `${groupTable}/_groupstatus.json?userid=${userID}&photoid=${photoID}`
      )
      .then((res) => {
        console.log(res);
        if (
          res.data.user_access === "USER" ||
          res.data.user_access === "OWNER"
        ) {
          instance
            .get(
              `_fomomsg_getprofile?user_id=${userID}&photo_id=${photoID}&notification_flag=true`
            )
            .then((res) => {
              this.setState({
                userDetail: res.data,
              });

              instance
                .get(
                  `${groupTable}/_joingroup?user_id=${userID}&photo_id=${photoID}&alias=${res.data.alias}&group_type=current_group&group_id=${groupTable}`
                )
                .then((res) => {
                  instance
                    .get(
                      `${groupTable}/_groupuserlist.json?user_id=${userID}&photo_id=${photoID}`
                    )
                    .then((res) => {
                      let chated = res.data.results.map((user) => {
                        if (this.state.userList) {
                          this.state.userList.map((blankUser) => {
                            if (user.photo_id == blankUser.from_photo_id) {
                              user.chated = true;
                            }
                          });
                        }
                        return user;
                      });
                      this.setState({
                        groupUsers: chated,
                      });
                    });
                });

              instance
                .get(
                  `${groupEvent}/_groupuserlist.json?user_id=${userID}&photo_id=${photoID}`
                )
                .then((res) => {
                  let chated = res.data.results.map((user) => {
                    this.state.userList.map((blankUser) => {
                      if (user.photo_id == blankUser.from_photo_id) {
                        user.chated = true;
                      }
                    });
                    return user;
                  });
                  this.setState({
                    eventGroupUsers: chated,
                  });
                });
            });
        }
      });

    instance
      .get(
        `${groupEvent}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.group_logo_id) {
          instance
            .get(
              `_getphotolead.json?user_id=${userID}&photo_id=${photoID}&admin_pass=&object_id=${res.data.group_logo_id}`
            )
            .then((res) => {
              console.log(res);
              this.setState({
                eventImage: baseURL + res.data.thumb_value.split("..")[1],
              });
            });
        }
      });

    // chat history
    instance
      .get(
        `${groupTable}/_groupchathistory.json?user_id=${userID}&photo_id=${photoID}&admin_password=''&limit=25&offset=&object_id=`
      )
      .then((res) => {
        console.log(res);
        let chatSummery = res.data.results.reverse();
        let chatHistoryDetail = chatSummery.map((chat) => {
          if (
            chat.msg_text.includes("dev.fomospaces.com") ||
            chat.msg_text.includes("staging.fomospaces.com")
          ) {
            let showUrl = chat.msg_text.split("/")[3];
            instance
              .get(
                `${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
              )
              .then((res) => {
                if (res.data.userstatus == "INVALID") {
                  chat.groupStatusFailed = true;
                } else {
                  chat.groupName = res.data.group_name;
                }
              });
          }
          return chat;
        });
        this.setState({
          chat: chatHistoryDetail,
        });
      });
    instance
      .get(
        `${groupEvent}/_groupchathistory.json?user_id=${userID}&photo_id=${photoID}&admin_password=''&limit=25&offset=&object_id=`
      )
      .then((res) => {
        let chatSummery = res.data.results.reverse();
        let chatHistoryDetail = chatSummery.map((chat) => {
          if (
            chat.msg_text.includes("dev.fomospaces.com") ||
            chat.msg_text.includes("staging.fomospaces.com")
          ) {
            let showUrl = chat.msg_text.split("/")[3];
            instance
              .get(
                `${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
              )
              .then((res) => {
                if (res.data.userstatus == "INVALID") {
                  chat.groupStatusFailed = true;
                } else {
                  chat.groupName = res.data.group_name;
                }
              });
          }
          return chat;
        });
        this.setState({
          eventChat: chatHistoryDetail,
        });
      });
    instance
      .get(`_getfomoonboard.json?user_id=${userID}&photo_id=${photoID}`)
      .then((res) => {
        // console.log(res, "sfdsfsdfssdfs");
        let requiredDetail = res.data.onboard_dic.profile.actions;
        let getGuest = res.data.onboard_completed_dic.profile;
        let getKeys = Object.keys(requiredDetail);

        let completeObject = {};
        getKeys.map((opt) => {
          let getField = requiredDetail[opt];
          let checkFields = getField.filter((element) =>
            getGuest.includes(element)
          );

          if (checkFields.length == getField.length) {
            completeObject[opt] = true;
          } else {
            completeObject[opt] = false;
          }
        });
        this.setState({
          getAllFields: completeObject,
        });
      });
  }

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  addEmoji = (e) => {
    let emoji = e.native;
    this.setState({
      backDrop: true,
      message: this.state.message + emoji,
    });
  };
  emojiHandlar = () => {
    this.setState((prevState) => ({
      backDrop: !prevState.backDrop,
      showEmoji: !prevState.showEmoji,
    }));
  };
  // 5b15ee-f7a-7af5-ae8-5be60c02a31

  fetchMessage = async (userID, photoID) => {
    let mainAllData = await instance.get(
      `_fomomsg_fetchmsglist?user_id=${userID}&photo_id=${photoID}&msg_type=fomo_convo&ptr_dic_flag=true`
    );

    // new code and logics

    let allUserDetail = mainAllData.data.ptr_dic.photo_id;
    let getallMessage = [];
    getallMessage = mainAllData.data.results;

    getallMessage.map((msg) => {
      let getPhotoIds = msg.convo_id.split("@");
      let userPhotoId = getPhotoIds.find((photoId) => {
        if (photoId != photoID) {
          return photoId;
        }
      });
      if (msg.from_photo_id == photoID) {
        msg.msgBy = "you";
      }
      msg.userPhotoId = userPhotoId;
      msg.userAvtar = allUserDetail[userPhotoId].thumb_value;
      msg.userAlias = allUserDetail[userPhotoId].alias;
      msg.unseenMsg = msg.reply.msg_type == "fomo_msg" ? 0 : msg.new_msg_cnt;
    });

    let storedUser = [];

    await getallMessage.map((user) => {
      let ind = storedUser.findIndex(
        (obj) => obj.userPhotoId == user.userPhotoId
      );
      if (ind > -1) {
        if (new Date(storedUser[ind].created_at) < new Date(user.created_at)) {
          storedUser[ind] = user;
        }
      } else {
        storedUser.push(user);
      }
    });

    storedUser
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .reverse();
    this.setState({
      userList: await storedUser,
    });
  };

  // groupSendMessage

  submitHandler = (e, type) => {
    e.preventDefault();

    if (this.state.getAllFields[type] == false) {
      this.props.stepHandler(7, "signup", type);
    } else {
      let message = quote(this.state.message);
      if (message.trim() !== "") {
        if (this.state.sendMessage) {
          this.setState({
            showEmoji: false,
            backDrop: false,
            sendMessage: false,
          });
          instance
            .get(
              `${this.state.activeGroup}/_sendgroupchat?user_id=${this.state.userID}&photo_id=${this.state.photoID}&alias=${this.state.userDetail.alias}&admin_password=''&msg_type=text&msg_text=${message}&ptr_type=group_id&ptr_value=111`
            )
            .then((res) => {
              this.setState({
                message: "",
                sendMessage: true,
              });
            });
        }
      }
    }
  };

  singleUserSendMessage = (e) => {
    e.preventDefault();
    if (this.state.getAllFields.fomomsg_chat == false) {
      this.props.stepHandler(7, "signup", "fomomsg_chat");
    } else {
      let message = quote(this.state.message);
      if (message.trim() !== "") {
        if (this.state.sendMessage) {
          this.setState({
            sendMessage: false,
          });

          let fomomsg_source = "";
          let getGroupId = "";
          if (this.state.activeUser.replyObj.msg_type == "fomo_msg") {
            getGroupId = this.state.activeUser.replyObj.group_id;
            fomomsg_source = this.state.activeUser.replyObj.fomomsg_source;
          }
          instance
            .get(
              `_fomomsg_sendmsg?from_user_id=${this.state.userID}&from_photo_id=${this.state.photoID}&from_alias=${this.state.userDetail.alias}&msg_type=${this.state.activeUser.replyObj.msg_type}&object_id=${this.state.activeUser.replyObj.object_id}&msg_type_class&msg_type_subclass&msg_text=${message}&msg_content_type=text&fomo_chat_from_alias&fomomsg_source=${fomomsg_source}&group_id=${getGroupId}`
            )
            .then((res) => {
              this.setState({
                message: "",
                sendMessage: true,
              });
              this.outerConversation.current.scrollTop =
                this.innerConversation.current.clientHeight;
            });
        }
      }
    }
  };

  activeChat = async (user) => {
    let userDetail = {
      alias: user.alias,
      photo_id: user.photo_id,
      thumb_value: user.thumb_value,
      replyObj: user.replyObj,
    };
    let messagesChat = await instance.get(
      `_fomomsg_fetchmsgs?user_id=${this.state.userID}&photo_id=${this.state.photoID}&object_id=${this.state.photoID}@${user.photo_id}&msg_type=fomo_chat&search_struct[limit]=50&search_struct[offset]=0&search_struct[last_object_id]=&search_struct[search_dir]=$gte&ptr_dic_flag=true&search_struct[include_flag]`
    );

    let getUser = [...this.state.userList];
    getUser.map((users) => {
      if (users.userPhotoId == user.photo_id) {
        users.unseenMsg = 0;
      }
    });
    this.setState({
      invalidSingleChat: [],
    });
    let chatSummery = messagesChat.data.results;
    let chatHistoryDetail = await chatSummery.map((chat, key) => {
      if (
        chat.msg_text.includes("dev.fomospaces.com") ||
        chat.msg_text.includes("staging.fomospaces.com")
      ) {
        let showUrl = chat.msg_text.split("/")[3];
        instance
          .get(
            `${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`
          )
          .then((res) => {
            if (res.data.userstatus == "INVALID") {
              chat.groupName = "";
              let getMsg = [...this.state.invalidSingleChat];
              getMsg.push(key);
              this.setState({
                invalidSingleChat: getMsg,
              });
            } else {
              chat.groupName = res.data.group_name;
            }
          });
      }
      return chat;
    });
    this.setState({
      userList: getUser,
      activeUser: userDetail,
      singlechat: chatHistoryDetail,
      activeChat: user.photo_id,
      message: "",
      activeGroup: "",
    });
    this.outerConversation.current.scrollTop =
      this.innerConversation.current.clientHeight;

    // .then((res) => {
    // 	let getUser = [...this.state.userList];
    // 	getUser.map((users) => {
    // 		if (users.userPhotoId == user.photo_id) {
    // 			users.unseenMsg = 0;
    // 		}
    // 	});
    // 	let chatSummery = res.data.results;
    // 	let chatHistoryDetail = await chatSummery.map(chat => {
    // 		if (chat.msg_text.includes('dev.fomospaces.com') || chat.msg_text.includes('dev.fomospaces.com')) {
    // 			let showUrl = chat.msg_text.split('/')[3]
    // 			instance.get(`${showUrl}/_groupstatus.json?userid=${this.state.userID}&photoid=${this.state.photoID}`)
    // 				.then(res => {
    // 					console.log(res, 'url')
    // 					if (res.data.userstatus == 'INVALID') {
    // 						chat.groupStatusFailed = true

    // 					}
    // 				})
    // 		}
    // 		return chat
    // 	})

    // 	this.setState({
    // 		userList: getUser,
    // 		activeUser: userDetail,
    // 		singlechat: chatHistoryDetail,
    // 		activeChat: user.photo_id,
    // 		message: "",
    // 		activeGroup: "",
    // 	});
    // 	this.outerConversation.current.scrollTop = this.innerConversation.current.clientHeight;
    // });
  };

  viewDetailHandler = () => {
    this.setState((prevState) => ({
      showGroupDetail: !prevState.showGroupDetail,
    }));
  };

  showPopup = (user = null) => {
    if (user) {
      instance
        .get(
          `_fomomsg_getprofile?user_id=${this.state.userID}&photo_id=${user.photo_id}&notification_flag=true`
        )
        .then((res) => {
          this.setState((prevState) => ({
            profileDetail: res.data,
            viewDetail: true,
          }));
        });
    } else {
      this.setState({
        viewDetail: false,
      });
    }
  };

  openWindow = (url) => {
    window.open(url, "Video", "width=1000,height=500");
  };

  welcomeStepHandler = (val, type, checkType = null) => {
    this.props.stepHandler(val, type, checkType);
  };

  urlify = (text, chatObj) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      let getUrl = url;
      let showUrl = url;
      let targeturl = "_blank";
      let internalink = "";
      if (
        getUrl.includes("dev.fomospaces.com") ||
        getUrl.includes("staging.fomospaces.com")
      ) {
        internalink = "internal-link";
        targeturl = "";
        showUrl = "Join: ";
        showUrl += chatObj.groupName
          ? unicodeToChar(chatObj.groupName)
          : getUrl.split("/")[3];
      }

      return (
        '<a class="' +
        internalink +
        '" href="' +
        url +
        '" target="' +
        targeturl +
        '">' +
        showUrl +
        "</a>"
      );
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  };

  closeHandler = () => {
    this.setState({});
  };

  render() {
    let groupChat = [];
    let groupUsers = [];
    setTimeout(() => {
      if (this.outerConversation.current && this.innerConversation.current) {
        this.outerConversation.current.scrollTop =
          this.innerConversation.current.clientHeight;
      }
    }, 100);
    if (this.state.activeGroup == this.state.groupEvent) {
      groupChat = this.state.eventChat;
      groupUsers = this.state.eventGroupUsers;

      if (this.outerConversation.current && this.innerConversation.current) {
        this.outerConversation.current.scrollTop =
          this.innerConversation.current.clientHeight;
      }
    }
    if (this.state.activeGroup == this.state.groupTable) {
      groupChat = this.state.chat;
      groupUsers = this.state.groupUsers;
      if (this.outerConversation.current && this.innerConversation.current) {
        this.outerConversation.current.scrollTop =
          this.innerConversation.current.clientHeight;
      }
    }

    return (
      <div
        className={
          this.props.chatOpen
            ? this.state.activeGroup || this.state.activeChat
              ? "conversation-box active open"
              : "conversation-box open"
            : this.state.activeGroup || this.state.activeChat
            ? "conversation-box active"
            : "conversation-box"
        }
        style={{ display: this.props.showToggle ? "none" : "flex" }}
      >
        <div className="main-box">
          <ViewProfile
            userDetail={this.props.userDetail}
            toggleHandler={this.state.viewDetail}
            popupDetail={this.state.profileDetail}
            click={this.showPopup}
          />
          <div className="user-list">
            <div className="head-box">
              <h3>Conversations</h3>
              <button type="button" onClick={this.props.openChatHandler}>
                <img src={CloseIcon} />
              </button>
            </div>
            <ul>
              <li
                className={
                  this.state.activeGroup == this.state.groupTable
                    ? "active"
                    : ""
                }
                onClick={() =>
                  this.setState({
                    activeGroup: this.state.groupTable,
                    activeChat: "",
                    tableUnseenMessage: 0,
                    message: "",
                  })
                }
              >
                <div className="detail-box">
                  <figure>
                    <img src={avtarIcon} />
                  </figure>
                  <div className="text-box">
                    <h4>My Group</h4>
                    <p>
                      {this.state.chat.length > 0
                        ? (this.state.chat[this.state.chat.length - 1]
                            .photo_id == this.state.photoID
                            ? "You"
                            : this.state.chat[this.state.chat.length - 1]
                                .alias) +
                          ": " +
                          unicodeToChar(
                            this.state.chat[this.state.chat.length - 1].msg_text
                          )
                        : ""}
                    </p>
                    <span>
                      {this.state.chat.length > 0
                        ? Moment(
                            this.state.chat[this.state.chat.length - 1]
                              .created_at
                          ).format("LT")
                        : ""}
                    </span>
                    {this.state.tableUnseenMessage ? (
                      <span className="unseen-msg">
                        {this.state.tableUnseenMessage}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </li>
              <li
                className={
                  this.state.activeGroup == this.state.groupEvent
                    ? "active"
                    : ""
                }
                onClick={() =>
                  this.setState({
                    activeGroup: this.state.groupEvent,
                    activeChat: "",
                    eventUnseenMessage: 0,
                    message: "",
                  })
                }
              >
                <div className="detail-box">
                  <figure>
                    <img
                      src={
                        this.state.eventImage
                          ? this.state.eventImage
                          : avtarIcon
                      }
                    />
                  </figure>
                  <div className="text-box">
                    <h4>Event</h4>
                    <p>
                      {this.state.eventChat.length > 0
                        ? (this.state.eventChat[this.state.eventChat.length - 1]
                            .photo_id == this.state.photoID
                            ? "You"
                            : this.state.eventChat[
                                this.state.eventChat.length - 1
                              ].alias) +
                          ": " +
                          unicodeToChar(
                            this.state.eventChat[
                              this.state.eventChat.length - 1
                            ].msg_text
                          )
                        : ""}
                    </p>
                    <span>
                      {this.state.eventChat.length > 0
                        ? Moment(
                            this.state.eventChat[
                              this.state.eventChat.length - 1
                            ].created_at
                          ).format("LT")
                        : ""}
                    </span>
                    {this.state.eventUnseenMessage ? (
                      <span className="unseen-msg">
                        {this.state.eventUnseenMessage}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </li>
              {this.state.userList
                ? this.state.userList.map((user, key) => (
                    <li
                      key={key}
                      className={
                        this.state.activeChat == user.userPhotoId
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        this.activeChat({
                          alias: user.userAlias,
                          photo_id: user.userPhotoId,
                          thumb_value: user.userAvtar,
                          replyObj: user.reply,
                        })
                      }
                    >
                      <div className="detail-box">
                        <figure>
                          <img
                            src={
                              user.userAvtar
                                ? baseURL + user.userAvtar.split("..")[1]
                                : avtarIcon
                            }
                          />
                        </figure>
                        <div className="text-box">
                          <h4>{user.userAlias}</h4>
                          <p>
                            {user.msgBy == "you"
                              ? "You:"
                              : user.userAlias + ": "}{" "}
                            {user.msg_text ? unicodeToChar(user.msg_text) : ""}
                          </p>
                          <span>
                            {Moment(user.created_at).format("L") ==
                            Moment(new Date()).format("L")
                              ? Moment(user.created_at).format("LT")
                              : Moment(user.created_at).format("MMM Do")}
                          </span>
                          {user.unseenMsg ? (
                            <span className="unseen-msg">{user.unseenMsg}</span>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  ))
                : ""}
            </ul>
          </div>

          <div
            className={
              this.state.activeGroup
                ? this.state.showGroupDetail
                  ? "chat-box active open"
                  : "chat-box open"
                : "chat-box"
            }
          >
            {this.state.activeGroup && groupChat && groupUsers ? (
              <>
                <div className="chat-header">
                  <button
                    type="button"
                    onClick={() => this.setState({ activeGroup: "" })}
                    className="back-btn"
                  >
                    <img src={BackIcon} />
                  </button>
                  <div className="detail-box" onClick={this.viewDetailHandler}>
                    <figure>
                      <img
                        src={
                          this.state.activeGroup == this.state.groupEvent
                            ? this.state.eventImage
                              ? this.state.eventImage
                              : avtarIcon
                            : avtarIcon
                        }
                      />
                    </figure>
                    <div className="text-box">
                      <h4>
                        {this.state.activeGroup == this.state.groupEvent
                          ? "Event"
                          : "My Group"}{" "}
                      </h4>
                      <p>{groupUsers.length} members</p>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <a
                        style={{ color: "white" }}
                        target="popup"
                        onClick={
                          this.state.getAllFields
                            ? (
                                this.state.activeGroup == this.state.groupEvent
                                  ? this.state.getAllFields.event_vchat
                                  : this.state.getAllFields.group_vchat
                              )
                              ? () =>
                                  this.openWindow(
                                    `https://live.fomospaces.com/?conference_id=${this.state.activeGroup}&user_name=${this.state.userDetail.alias}&photo_id=${this.state.photoID}`
                                  )
                              : () =>
                                  this.welcomeStepHandler(
                                    7,
                                    "signup",
                                    this.state.activeGroup ==
                                      this.state.groupEvent
                                      ? "event_vchat"
                                      : "group_vchat"
                                  )
                            : ""
                        }
                      >
                        <i
                          className="fa fa-video-camera"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    {this.props.click ? (
                      <li onClick={this.props.click}>
                        <img src={hideIcon} /> <p>Hide</p>
                      </li>
                    ) : (
                      <li>
                        <i className="fa fa-expand" aria-hidden="true"></i>
                      </li>
                    )}
                    {/* <li><i className="fa fa-expand" aria-hidden="true"></i></li> */}
                  </ul>
                </div>
                <div className="chat-summary" ref={this.outerConversation}>
                  <ul ref={this.innerConversation}>
                    {groupChat.map((chat, key) => {
                      if (chat.photo_id == this.state.photoID) {
                        return (
                          <li key={key} className="my-message">
                            <div className="detail-box">
                              <figure>
                                <img
                                  src={
                                    this.props.userDetail.thumb_value
                                      ? baseURL +
                                        this.props.userDetail.thumb_value.split(
                                          ".."
                                        )[1]
                                      : avtarIcon
                                  }
                                />
                              </figure>
                              <div className="text-box">
                                <p
                                  style={{
                                    paddingRight: chat.groupStatusFailed
                                      ? "20px"
                                      : 0,
                                  }}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: this.urlify(
                                        unicodeToChar(chat.msg_text),
                                        chat
                                      ),
                                    }}
                                  ></p>{" "}
                                  {chat.groupStatusFailed ? (
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                    ></i>
                                  ) : null}
                                </p>
                                <span>
                                  {Moment(chat.created_at).format("LT")}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      } else {
                        return (
                          <li key={key}>
                            <div className="detail-box">
                              <figure>
                                <img
                                  src={
                                    chat.profile_info.thumb_value
                                      ? baseURL +
                                        chat.profile_info.thumb_value.split(
                                          ".."
                                        )[1]
                                      : avtarIcon
                                  }
                                />
                              </figure>
                              <div className="text-box">
                                <h4>{chat.alias}</h4>
                                <p
                                  style={{
                                    paddingRight: chat.groupStatusFailed
                                      ? "20px"
                                      : 0,
                                  }}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: this.urlify(
                                        unicodeToChar(chat.msg_text),
                                        chat
                                      ),
                                    }}
                                  ></p>{" "}
                                  {chat.groupStatusFailed ? (
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                    ></i>
                                  ) : null}
                                </p>

                                <span>
                                  {Moment(chat.created_at).format("LT")}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    })}
                    <div id="scroll" />
                  </ul>
                </div>
                <div className="send-message">
                  <div
                    className="back-drop"
                    style={{ display: this.state.backDrop ? "block" : "none" }}
                    onClick={this.emojiHandlar}
                  ></div>
                  <form
                    autoComplete="off"
                    onSubmit={(e) =>
                      this.submitHandler(
                        e,
                        this.state.activeGroup == this.state.groupEvent
                          ? "event_chat"
                          : "group_chat"
                      )
                    }
                  >
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Write a message"
                      name="message"
                      value={this.state.message}
                      onChange={this.inputHandler}
                    />
                    {console.log(
                      this.state.getAllFields.group_chat,
                      this.state.getAllFields.event_chat
                    )}
                    {this.state.getAllFields ? (
                      (
                        this.state.activeGroup == this.state.groupEvent
                          ? this.state.getAllFields.event_chat
                          : this.state.getAllFields.group_chat
                      ) ? (
                        <ul className="btn-list">
                          <li>
                            <button type="submit">
                              <i className="ion-android-send" />
                            </button>
                          </li>
                          <li>
                            <i
                              onClick={this.emojiHandlar}
                              className="fa fa-smile-o"
                            ></i>
                            <Picker
                              style={{
                                display: this.state.showEmoji
                                  ? "block"
                                  : "none",
                              }}
                              onSelect={this.addEmoji}
                            />
                          </li>
                        </ul>
                      ) : (
                        <ul className="login-btn-group">
                          <li>
                            <button
                              onClick={() =>
                                this.welcomeStepHandler(3, "login")
                              }
                              className="login-btn"
                              type="button"
                            >
                              Log in
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() =>
                                this.welcomeStepHandler(
                                  7,
                                  "signup",
                                  this.state.activeGroup ==
                                    this.state.groupEvent
                                    ? "event_chat"
                                    : "group_chat"
                                )
                              }
                              className="signUp-btn"
                              type="button"
                            >
                              Sign up
                            </button>
                          </li>
                        </ul>
                      )
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </>
            ) : null}
          </div>

          <div className={this.state.activeChat ? "chat-box open" : "chat-box"}>
            {this.state.activeChat ? (
              <>
                <div className="chat-header">
                  <button
                    type="button"
                    onClick={() => this.setState({ activeChat: "" })}
                    className="back-btn"
                  >
                    <img src={BackIcon} />
                  </button>
                  <div className="detail-box">
                    <figure>
                      <img
                        src={
                          this.state.activeUser.thumb_value
                            ? baseURL +
                              this.state.activeUser.thumb_value.split("..")[1]
                            : avtarIcon
                        }
                      />
                    </figure>
                    <div className="text-box">
                      <h4>{this.state.activeUser.alias} </h4>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <a
                        style={{ color: "white" }}
                        target="popup"
                        onClick={
                          this.state.getAllFields
                            ? this.state.getAllFields.fomomsg_vchat
                              ? () =>
                                  this.openWindow(
                                    `https://live.fomospaces.com/?conference_id=${this.state.photoID}@${this.state.activeChat}&user_name=${this.state.userDetail.alias}&photo_id=${this.state.photoID}`
                                  )
                              : () =>
                                  this.welcomeStepHandler(
                                    7,
                                    "signup",
                                    "fomomsg_vchat"
                                  )
                            : ""
                        }
                      >
                        <i
                          className="fa fa-video-camera"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    {this.props.click ? (
                      <li onClick={this.props.click}>
                        <img src={hideIcon} /> <p>Hide</p>
                      </li>
                    ) : (
                      <li>
                        <i className="fa fa-expand" aria-hidden="true"></i>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="chat-summary" ref={this.outerConversation}>
                  <ul ref={this.innerConversation}>
                    {this.state.singlechat.map((chat, key) => {
                      if (chat.from_photo_id == this.state.photoID) {
                        return (
                          <li key={key} className="my-message">
                            <div className="detail-box">
                              <figure>
                                <img
                                  src={
                                    this.props.userDetail.thumb_value
                                      ? baseURL +
                                        this.props.userDetail.thumb_value.split(
                                          ".."
                                        )[1]
                                      : avtarIcon
                                  }
                                />
                              </figure>
                              <div className="text-box">
                                <p style={{ paddingRight: "20px" }}>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: this.urlify(
                                        unicodeToChar(chat.msg_text),
                                        chat
                                      ),
                                    }}
                                  ></p>
                                  {this.state.invalidSingleChat.length > 0
                                    ? this.state.invalidSingleChat.map(
                                        (msgg) => {
                                          if (msgg == key) {
                                            return (
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>
                                            );
                                          }
                                        }
                                      )
                                    : null}
                                </p>

                                {/* <p dangerouslySetInnerHTML={{ __html: this.urlify(unicodeToChar(chat.msg_text), this.state.userID, this.state.photoID) }}></p> */}
                                {/* <p>{chat.msg_text}</p> */}
                                <span>
                                  {Moment(chat.created_at).format("LT")}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      } else {
                        return (
                          <li key={key}>
                            <div className="detail-box">
                              <figure>
                                <img
                                  src={
                                    this.state.activeUser.thumb_value
                                      ? baseURL +
                                        this.state.activeUser.thumb_value.split(
                                          ".."
                                        )[1]
                                      : avtarIcon
                                  }
                                />
                              </figure>
                              <div className="text-box">
                                <p style={{ paddingRight: "20px" }}>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: this.urlify(
                                        unicodeToChar(chat.msg_text),
                                        chat
                                      ),
                                    }}
                                  ></p>
                                  {this.state.invalidSingleChat.length > 0
                                    ? this.state.invalidSingleChat.map(
                                        (msgg) => {
                                          if (msgg == key) {
                                            return (
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>
                                            );
                                          }
                                        }
                                      )
                                    : null}
                                </p>

                                {/* <p dangerouslySetInnerHTML={{ __html: this.urlify(unicodeToChar(chat.msg_text), this.state.userID, this.state.photoID) }}></p> */}
                                {/* <p>{chat.msg_text}</p> */}
                                <span>
                                  {Moment(chat.created_at).format("LT")}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                <div className="send-message">
                  <div
                    className="back-drop"
                    style={{ display: this.state.backDrop ? "block" : "none" }}
                    onClick={this.emojiHandlar}
                  ></div>
                  <form
                    autoComplete="off"
                    onSubmit={this.singleUserSendMessage}
                  >
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Write a message"
                      name="message"
                      value={this.state.message}
                      onChange={this.inputHandler}
                    />
                    {this.state.getAllFields ? (
                      this.state.getAllFields.fomomsg_chat ? (
                        <ul className="btn-list">
                          <li>
                            <button type="submit">
                              <i className="ion-android-send" />
                            </button>
                          </li>
                          <li>
                            <i
                              onClick={this.emojiHandlar}
                              className="fa fa-smile-o"
                            ></i>
                            <Picker
                              style={{
                                display: this.state.showEmoji
                                  ? "block"
                                  : "none",
                              }}
                              onSelect={this.addEmoji}
                            />
                          </li>
                        </ul>
                      ) : (
                        <ul className="login-btn-group">
                          <li>
                            <button
                              onClick={() =>
                                this.welcomeStepHandler(3, "login")
                              }
                              className="login-btn"
                              type="button"
                            >
                              Log in
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() =>
                                this.welcomeStepHandler(
                                  7,
                                  "signup",
                                  "fomomsg_chat"
                                )
                              }
                              className="signUp-btn"
                              type="button"
                            >
                              Sign up
                            </button>
                          </li>
                        </ul>
                      )
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </>
            ) : null}
          </div>
          <div
            className={
              this.state.showGroupDetail
                ? "group-detail active"
                : "group-detail"
            }
          >
            <div className="head-box">
              <span onClick={this.viewDetailHandler}>
                <i className="fa fa-times"></i>
              </span>
              <h4>Group info</h4>
            </div>
            <div className="detail-box">
              <div className="group-box">
                <figure>
                  <img src={avtarIcon} />
                </figure>
                <h3>
                  {this.state.activeGroup == this.state.groupEvent
                    ? "Event"
                    : "My Group"}
                </h3>
                <span>{groupUsers ? groupUsers.length + " Members" : ""} </span>
              </div>
              <ul className="user-listing">
                {groupUsers
                  ? groupUsers.map((user, key) => (
                      <li key={key} onClick={() => this.showPopup(user)}>
                        <figure>
                          <img
                            src={
                              user.profile_info.thumb_value
                                ? baseURL +
                                  user.profile_info.thumb_value.split("..")[1]
                                : avtarIcon
                            }
                          />
                        </figure>
                        <p>{user.alias}</p>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  chatDetail: state.addChat.chatDetail,
  step: state.welcomeStep.step,
});
const mapDispatchToProps = (dispatch) => {
  return {
    stepHandler: (step, type, checkType) =>
      dispatch(actions.stepHandler(step, type, checkType)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(conversation);
