import React, { Component } from "react";
import {
	TwitchEmbed,
	TwitchChat,
	TwitchClip,
	TwitchPlayer,
} from "react-twitch-embed";
import "./streaming.scss";
import streamingImg from "../../assets/images/streaming.png";
import { channelOne, channelTwo, privateChat } from "../../pusher";
localStorage.setItem("volume", -1);
class Streaming extends Component {
	state = {
		channelDetail: "",
		stream: React.createRef(),
		volume: -1,
	};
	componentDidUpdate(prevProps, prevState) {
		console.log("strea", this.state.stream);
		if (prevProps !== this.props) {
			this.setState({
				groupDetail: this.props.groupDetail,
			});
		}
	}



	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.stream != nextState.stream || this.props != nextProps) {
			if (this.props.groupDetail && nextProps.groupDetail && this.props.groupDetail != "" && nextProps.groupDetail != "" && (this.props.groupDetail.livestream && nextProps.groupDetail.livestream && (this.props.groupDetail.livestream.ls_active != nextProps.groupDetail.livestream.ls_active || this.props.groupDetail.livestream.ls_url != nextProps.groupDetail.livestream.ls_url))) {
				return true;
			} else if (!this.props.groupDetail) {
				return true;
			}

		}
		return false;
	}
	componentDidMount() {
		privateChat.bind_global((event, data) => {
			console.log("twitch_pusher", data);

			if (data.type == "conference-action") {
				let volume = null;
				switch (data.action) {
					case "talk-start":
						volume = data.livestream_volume / 100;
						if (volume < this.getVolume()) {
							localStorage.setItem("volume", this.getVolume());
							this.setVolume(volume);
						} else {
							localStorage.setItem("volume", -1);
						}
						break;
					case "talk-stop":
						volume = data.livestream_volume / 100;
						if (localStorage.getItem("volume") > -1) {
							if (this.getVolume() == volume) {
								this.setVolume(parseFloat(localStorage.getItem("volume")));
								localStorage.setItem("volume", -1);
							}
						}

						console.log("talk-stop");
						break;
				}
			}
		});
	}

	getVolume = () => {
		if (this.state.stream.current) {
			return this.state.stream.current.player.getVolume();
		} else {
			return 0;
		}
	};

	setVolume = (volume) => {
		if (this.state.stream.current) {
			return this.state.stream.current.player.setVolume(volume);
		}
	};

	play = () => {
		if (this.state.stream.current) {
			console.log(this.state.stream.current.player);
		}
		// console.log('player',this.state.stream.getVolume())
	};

	render() {
		return (
			this.props.groupDetail ? <div className={this.props.groupDetail.livestream.ls_url && this.props.groupDetail.livestream.ls_active == 'active' ? "stream-box" : "stream-box active"}>
			<div className="main-box">
					{this.props.groupDetail.livestream.ls_url && this.props.groupDetail.livestream.ls_active == 'active' ? (
						<TwitchPlayer
							onPlay={this.play()}
							volume="10%"
							style={{ width: "100%", height: "100%" }}
							ref={this.state.stream}
							autoplay
							layout="video"
							channel={this.props.groupDetail.livestream.ls_url}
						// channel="TEBtv"
						/>
					) : (
							<figure>
								<p>No active livestream</p> <img src={streamingImg} />
							</figure>
						)
				} 
			</div>
		</div> : null
			
		);
	}
}

export default Streaming;
