import React, { Component } from "react";
import Layout from "../../hoc/layout/layout";
import { baseURL, instance } from "../../axios";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import "./map.scss";
import mapPoint from "../../assets/images/google-place.svg";
import chatIcon from "../../assets/images/chat-icon.png";
import Conversation from "../conversation/conversation";
import barIcon from "../../assets/images/bars.svg";
import houseIcon from "../../assets/images/houses.svg";
import eateriesIcon from "../../assets/images/eateries.svg";
import artIcon from "../../assets/images/art.png";
import talkIcon from "../../assets/images/talk.png";
import performanceIcon from "../../assets/images/performance.png";
import viewIcon from "../../assets/images/view-icon.svg";
import { Redirect } from "react-router-dom";
import Home from "../../containers/home/home";
import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import WelcomePopup from '../welcomePopup/welcomePopup';
import _ from 'lodash';
// import { google } from 'googleapis';


// ES5





import { quote, unicodeToChar } from '../../shared/utility';
const Map = ReactMapboxGl({
	accessToken:
		'pk.eyJ1Ijoibmlja3ppbW1lciIsImEiOiJja2VrN2s1d3UyZGI0MnpsdDJ2d3h6cmUwIn0.qmBS_A6uXXZ8x-6dpYfR8Q'
});
let replaceUrl = true;




class MapView extends Component {
	state = {
		showWelcomePopup: false,
		mapDetail: "",
		userDetail: "",
		mapCoord: "",
		homeView: false,
		homeUrl: "",
		map_size: null,
		tableUnseenMessage: null,
		eventUnseenMessage: null,
		tick: null,
		mapType: 'mapbox',
		centerPoint: '',
		allPoints: [],
		zoom: [5],
		showDetailPoints: false,
		symbols: {
			'bar': {
				icon: barIcon,
				status: false
			},
			'house': {
				icon: houseIcon,
				status: false
			},
			'restaurant': {
				icon: eateriesIcon,
				status: false
			},
			'art': {
				icon: artIcon,
				status: false
			},
			'performance': {
				icon: performanceIcon,
				status: false
			},
			'talk': {
				icon: talkIcon,
				status: false
			},
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.step !== this.props.step) {
			this.setState({
				showWelcomePopup: this.props.step ? true : false,
			});
		}
	}
	componentDidMount() {

		this.setState({
			tick: setTimeout(() => {
				this.setState({
					showChat: true
				})
			}, 3000)
		})


		// const map = new mapboxgl.Map({
		//   container: this.mapContainer,
		//   style: 'mapbox://styles/mapbox/streets-v11',
		//   center: [-119.855267, 34.413178],
		//   zoom: 10
		// });

		//  var makers = new mapboxgl.Marker()
		//               .setLngLat([-119.855267, 34.413178])
		//               .addTo(map);

		let userID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : null;
		let photoID = localStorage.getItem("photo_id") ? localStorage.getItem("photo_id") : null;
		let groupTable = window.location.pathname.split("/")[3] ? window.location.pathname.split("/")[3].toUpperCase() : window.location.pathname.split("/")[3];
		let groupEvent = window.location.pathname.split("/")[1] ? window.location.pathname.split("/")[1].toUpperCase() : window.location.pathname.split("/")[1];
		let inviteId = window.location.pathname.split("/")[2] ? window.location.pathname.split("/")[2] : 0;
		let groupMapId = window.location.pathname.split("/")[4];
		if (userID) {
			instance.get(`_fomomsg_getprofile?user_id=${userID}&photo_id=${photoID}&notification_flag=true`)
				.then((res) => {

					if (inviteId != res.data.invited_by_id) {
						if (groupMapId) {
							window.location.pathname = "/" + groupEvent + "/" + res.data.invited_by_id + "/" + groupTable + "/" + groupMapId
						}
						else {
							window.location.pathname = "/" + groupEvent + "/" + res.data.invited_by_id + "/" + groupTable + "/"
						}

					}

				});
		}
		this.setState({
			userID: userID,
			photoID: photoID,
			groupTable: groupTable,
			groupEvent: groupEvent,
			showWelcomePopup: userID ? false : true
		});
		let protected_url = ["_login", "_signup", "_profile"];
		if (protected_url.includes(groupEvent) || protected_url.includes(groupTable)) {
			this.setState({
				notFound: true,
			});
		} else {
			instance.get(`${groupEvent}/_groupstatus.json?userid=${userID}&photoid=${photoID}`)
				.then((response) => {
					console.log(response)
					if (response.data.user_access === "USER") {

						this.props.groupDetail(response.data);
						console.log("work");
						let mapId = window.location.pathname.split("/")[4]
							? window.location.pathname.split("/")[4]
							: response.data.mapdata_id;
						this.setState({
							groupDetail: response.data,
						});

						instance
							.get(
								`_fomomsg_getprofile?user_id=${userID}&photo_id=${photoID}&notification_flag=true`
							)
							.then((res) => {
								console.log(res, 'profile')
								this.setState({
									userDetail: res.data,
								});
								instance
									.get(
										`${groupTable}/_joingroup?user_id=${userID}&photo_id=${photoID}&alias=${res.data.alias}&group_type=current_group&group_id=${groupTable}`
									)
									.then((res) => {
										// console.log(res, 'joined')
									});
							});
						instance
							.get(
								`${groupEvent}/_getmapdataparent.json?user_id=${userID}&photo_id=${photoID}&admin_pass={{admin_password}}&alias=jhon&map_type=&ptr_dic_flag=true&mapdata_id=${mapId}`
							)
							.then((res) => {
								console.log(res, "test");

								let parentid = Object.keys(res.data.mapdata_dic[mapId])[0];

								let getMapType = Object.keys(res.data.mapdata_dic[mapId][parentid])[0];
								console.log(res.data.mapdata_dic[mapId][parentid][getMapType][groupEvent].map_id, 'check this')
								if (getMapType == 'virtual') {
									let getObjectKey = res.data.mapdata_dic[mapId][parentid][getMapType][groupEvent].map_id;
									this.setState({
										mapImage: res.data.ptr_dic.content_id[getObjectKey].value,
									})
								}



								let GetParentCoord = Object.keys(res.data.mapdata_dic[mapId][parentid][getMapType]);
								let urlPath = window.location.pathname.split("/");
								this.setState({
									mapType: getMapType,
									mapDetail: res.data.ptr_dic,
									parentMapCoord:
										res.data.mapdata_dic[mapId][parentid][getMapType][
										GetParentCoord
										],
									parentMapDetail: res.data.ptr_dic.group_id[GetParentCoord],
									parentMapUrl: "/" + res.data.mapdata_dic[mapId][parentid][getMapType][GetParentCoord].parent_id + "/" + urlPath[2] + "/" + urlPath[3] + "/" + urlPath[4],
									map_size: res.data.mapdata_dic[mapId][parentid][getMapType][groupEvent]["coords"],
								});
							});
						instance.get(`${groupEvent}/_getmapdata.json?user_id=${userID}&photo_id=${photoID}&admin_pass={{admin_password}}&alias=jhon&map_type=&mapdata_id=${mapId}&ptr_dic_flag=true`)
							.then((res) => {
								console.log(res, "map");
								// console.log(res.data.ptr_dic.group_id, "testing");
								if (!res.data.ptr_dic.group_id) {
									this.setState({
										homeView: true,
									});
									// let pageUrl = window.location.pathname.split('/')
									// window.location.pathname = pageUrl[1] + '/' + pageUrl[2] + '/' + pageUrl[3]
								}

								if (res.data.ptr_dic.group_id) {
									let pointsDetails = res.data.ptr_dic.group_id;
									let getKeys = Object.keys(pointsDetails);
									// let getFullDetail = getKeys.map(coords => {
									// 	pointsDetails.coords.coords =
									// })
									// console.log(pointsDetails, 'working');
									let symbols = { ...this.state.symbols }
									let filteredName = getKeys.filter((names) => {
										if (names != groupEvent) {
											if (res.data.ptr_dic.group_id[names].event_venue in symbols) {
												symbols[res.data.ptr_dic.group_id[names].event_venue].status = true
											}
											return names;
										}
									});
									let getMapType = Object.keys(res.data.mapdata_dic[mapId][groupEvent])[0];
									let mapCoord = res.data.mapdata_dic[mapId][groupEvent][getMapType]
									let getallKeys = Object.keys(mapCoord)

									let ceateArray = []

									getallKeys.splice(getallKeys.indexOf(groupEvent), 1)
									getallKeys.map(groupName => {
										ceateArray.push({ lng: mapCoord[groupName].coords.x_axis, lat: mapCoord[groupName].coords.y_axis })
									})





									console.log(ceateArray)
									this.setState({
										mapType: getMapType,
										mapPointsName: filteredName,
										coordsDetail: res.data.ptr_dic.group_id,
										mapCoord: mapCoord,
										symbols: symbols,
										centerPoint: [ceateArray[0].lng, ceateArray[0].lat],
										allPoints: ceateArray,
										filBoundValue: this.getBounds(ceateArray)
									});
									// console.log(this.getBounds(ceateArray))


								}
								// if (response.data.user_access != "USER") {
								// 	this.setState({
								// 		showWelcomePopup: true,
								// 	})
								// }
							});
					} else {
						this.setState({
							notFound: true,
							// showWelcomePopup: true,
						});
					}
				});
			instance.get(`${groupTable}/_groupstatus.json?userid=${userID}&photoid=${photoID}`)
				.then((response) => {
					console.log(response, "test Table");
					if (response.data.user_access === "USER") {
					} else {
						this.setState({
							notFound: true,
							// showWelcomePopup: true,
						});
					}
				});

			if (groupTable != "0" && groupTable != "") {
				// console.log(groupTable)
				instance.get(`${groupTable}/_groupstatus.json?userid=${userID}&photoid=${photoID}`)
					.then((response) => {
						console.log(response, "test Table");
						if (response.data.user_access === "USER") {
						} else {
							this.setState({
								notFound: true,
								// showWelcomePopup: true,
							});
						}
					});
			}
			if (groupTable == "0" || groupTable == "") {
				instance
					.get(
						`_newrandgroupid.json?userid=${userID}&photoid=${photoID}&event_id=${groupEvent}&mapdata_id=''`
					)
					.then((res) => {
						console.log(res, "_newrandgroupid");
						let urlPath = window.location.pathname.split("/");
						console.log(res.data.groupid);
						window.location.pathname =
							"/" +
							groupEvent +
							"/" +
							urlPath[2] +
							"/" +
							res.data.groupid +
							"/" +
							urlPath[4];
					});
			}
		}
	}
	getMinOrMax = (markersObj, minOrMax, latOrLng) => {
		if (minOrMax == "max") {
			return _.maxBy(markersObj, function (value) {
				return value[latOrLng]
			})[latOrLng];
		} else {
			return _.minBy(markersObj, function (value) {
				return value[latOrLng]
			})[latOrLng];
		}
	}

	getBounds = (markersObj) => {
		var maxLat = this.getMinOrMax(markersObj, "max", "lat");
		var minLat = this.getMinOrMax(markersObj, "min", "lat");
		var maxLng = this.getMinOrMax(markersObj, "max", "lng");
		var minLng = this.getMinOrMax(markersObj, "min", "lng");
		var width = maxLng - minLng; // longitude
		var height = maxLat - minLat; // latitude
		if (((180 - maxLng) + (180 + minLng)) < width) {
			width = ((180 - maxLng) + (180 + minLng));
			var temp_minLng = minLng;
			minLng = maxLng;
			maxLng = temp_minLng;
		}
		var scale = 0.2;
		var scale_w = scale * width; // longitude
		var scale_h = scale * height; // latitude
		var southWest = [(minLng - scale_w), (minLat - scale_h)];
		var northEast = [(maxLng + scale_w), (maxLat + scale_h)];
		console.log(minLng, minLat, maxLng, maxLat, 'minLng, minLat, maxLng, maxLag')
		console.log(southWest, northEast, 'southWest, northEast')
		return [southWest, northEast];
	}
	showHandler = () => {
		clearTimeout(this.state.tick)
		this.setState((prevState) => ({
			showChat: !prevState.showChat,
		}));
	};

	onTableUnseen = (data) => {
		this.setState({
			tableUnseenMessage: data,
		});
	};
	onEventUnseen = (data) => {
		this.setState({
			eventUnseenMessage: data,
		});
	};

	exitGroupHandler = (groupName) => {
		instance
			.get(
				`${this.state.groupEvent}/_exitgroup?user_id=${this.state.userID}&photo_id=${this.state.photoID}&alias=${this.state.userDetail.alias}`
			)
			.then((res) => {
				localStorage.setItem("backUrl", window.location.pathname);
				let urlPath = window.location.pathname.split("/");
				window.location.pathname =
					"/" +
					groupName +
					"/" +
					urlPath[2] +
					"/" +
					urlPath[3] +
					"/" +
					urlPath[4];

				console.log(res, "test group exit");
			});
	};

	popupCloseHandler = () => {
		if (this.props.step) {
			this.setState({
				showWelcomePopup: false
			})
			this.props.stepHandler('')

		}
	}

	activeToolpitHandler = (key, x_axis, y_axis) => {
		this.setState({ activeTooltip: key })
	}
	onZoom = (e) => {
		console.log(e)
		this.setState({
			filBoundValue: ''
		})
	}
	render() {
		let symbols = []
		for (var obj in this.state.symbols) {
			if (this.state.symbols[obj]['status']) {
				symbols.push({
					icon: this.state.symbols[obj].icon,
					name: obj[0].toUpperCase() + obj.slice(1)

				})
			}
		}

		let marker_array = this.state.mapPointsName && this.state.mapPointsName.length > 0 && this.state.mapCoord ? this.state.mapPointsName.map((mapPointName) => {
			return [this.state.mapCoord[mapPointName].coords.x_axis, this.state.mapCoord[mapPointName].coords.y_axis]
		}) : null;
		console.log('marktsr', marker_array)
		let unSeenConcat =
			this.state.tableUnseenMessage + this.state.eventUnseenMessage;
		let pageView = (
			<Layout headerClass="map-header" userDetail={this.state.userDetail}>
				<WelcomePopup click={this.popupCloseHandler} toggleHandler={this.state.showWelcomePopup} />
				{this.state.notFound ? <Redirect to="/" /> : ""}
				<div className="map-section">
					<div className="chat-section">
						<button
							onClick={this.showHandler}
							style={{ display: this.state.showChat ? "block" : "none" }}
							className="chat-open-btn"
						>
							{/* {unSeenConcat ? (
                <span className="unseen-msg">{unSeenConcat}</span>
              ) : null} */}
							{<span className="unseen-msg">{unSeenConcat}</span>}

							<img src={chatIcon} />
						</button>
						<Conversation
							showToggle={this.state.showChat}
							click={this.showHandler}
							userDetail={this.state.userDetail}
							tableUnseenMessage={this.onTableUnseen}
							eventUnseenMessage={this.onEventUnseen}
						/>
					</div>
					{symbols.length > 0 ?
						<div className="navigation-group">
							<div className="map-navigation">
								<button type="button" onClick={() => this.setState(prevState => ({ showDetailPoints: !prevState.showDetailPoints }))} className={this.state.showDetailPoints ? 'active' : ''}>
									{this.state.showDetailPoints ? 'Hide legend' : 'Show legend'}
									<i class="fa fa-angle-up" aria-hidden="true"></i>
								</button>
								<ul className={this.state.showDetailPoints ? 'active' : ''}>
									{symbols.map((ins, key) => (
										<li key={key}>
											<img src={ins.icon} />
											<p>{ins.name}</p>
										</li>
									))}




								</ul>
							</div>
						</div>
						: ''}
					{this.state.mapType == 'virtual' ? <div className="map-box">
						{this.state.mapDetail ? (
							<img
								style={{ width: "100%" }}
								src={
									baseURL +
									(this.state.mapImage
										? this.state.mapImage.split("..")[1]
										: "")
								}
							/>
						) : (
								""
							)}

						<ul className="map-points">
							{/* {this.state.parentMapCoord ?
						<li style={{ 'left': this.state.parentMapCoord.coords.x_axis / 78.61 + '%', top: this.state.parentMapCoord.coords.y_axis / 38.28 + '%' }}><p>{this.state.parentMapDetail.group_name.toLowerCase()}</p>
							<span>
								{this.state.parentMapDetail ? this.state.parentMapDetail.event_venue == 'bar' ? <img src={barIcon} /> : <img src={mapPoint} /> : <img src={mapPoint} />}
							</span>
							{this.state.parentMapDetail ?
								<div className="tooltip">
									{this.state.parentMapDetail.group_name ? <h5 onClick={() => this.exitGroupHandler(this.state.parentMapDetail.group_id)}>{this.state.parentMapDetail.group_name.toLowerCase()}</h5> : ''}
									{this.state.parentMapDetail.event_location ? <span>{this.state.parentMapDetail.event_location}</span> : ''}
									{this.state.parentMapDetail.livestream.ls_url ? <span className="live-btn">LIVE</span> : ''}

									{this.state.parentMapDetail.group_desc ? <p>{this.state.parentMapDetail.group_desc}</p> : ''}

								</div> : ''
							}
						</li> : ''
					} */}
							{this.state.mapPointsName && this.state.map_size
								? this.state.mapPointsName.map((mapPointName, key) => (
									<li
										key={key}
										style={{
											left:
												(this.state.mapCoord[mapPointName].coords.x_axis *
													100) /
												this.state.map_size.x_axis -
												1 +
												"%",
											top:
												(this.state.mapCoord[mapPointName].coords.y_axis *
													100) /
												this.state.map_size.y_axis -
												3 +
												"%",
											position: "abo",
										}}
									>
										<div style={{ cursor: 'pointer' }} onClick={() =>
											this.exitGroupHandler(
												this.state.coordsDetail[mapPointName].group_id
											)
										}>
											<p>{unicodeToChar(this.state.coordsDetail[mapPointName].group_name)}</p>
											<span>
												{this.state.coordsDetail ? (
													this.state.coordsDetail[mapPointName].event_venue in this.state.symbols
														? (
															<img src={this.state.symbols[this.state.coordsDetail[mapPointName].event_venue].icon} />
														) : (
															<img src={mapPoint} />
														)
												) : (
														<img src={mapPoint} />
													)}
											</span>

										</div>

										{this.state.coordsDetail ? (
											<div
												onClick={() =>
													this.exitGroupHandler(
														this.state.coordsDetail[mapPointName].group_id
													)
												}
												className="tooltip">
												{this.state.coordsDetail[mapPointName].group_name ? (
													<h5
														onClick={() =>
															this.exitGroupHandler(
																this.state.coordsDetail[mapPointName].group_id
															)
														}
													>
														{unicodeToChar(this.state.coordsDetail[mapPointName].group_name)}
													</h5>
												) : (
														""
													)}
												{this.state.coordsDetail[mapPointName].livestream
													.ls_url && this.state.coordsDetail[mapPointName].livestream.ls_active == 'active' ? (
														<span className="live-btn">LIVE</span>
													) : (
														""
													)}
												{this.state.coordsDetail[mapPointName].event_date ? <p className="time-date">
													{unicodeToChar(this.state.coordsDetail[mapPointName].event_date)} <br /> {this.state.coordsDetail[mapPointName].event_time ? unicodeToChar(this.state.coordsDetail[mapPointName].event_time) : ''}
												</p> : ""}
												{this.state.coordsDetail[mapPointName]
													.event_location ? (
														<span style={{ marginBottom: '15px' }}>
															{
																unicodeToChar(this.state.coordsDetail[mapPointName]
																	.event_location)
															}
														</span>
													) : (
														""
													)}

												{/* <span className="live-btn">LIVE</span> */}
												{/* <span><img src={viewIcon} /> 341 viewers</span> */}
												{this.state.coordsDetail[mapPointName].group_desc ? (
													<span>
														{unicodeToChar(this.state.coordsDetail[mapPointName].group_desc)}
													</span>
												) : (
														""
													)}
											</div>
										) : (
												""
											)}
									</li>
								))
								: ""}
						</ul>
					</div> : ''}
					{this.state.mapType == 'mapbox' && this.state.mapPointsName ? <Map
						style="mapbox://styles/mapbox/streets-v9"
						containerStyle={{
							height: '95vh',
							width: '100vw'
						}}
						fitBounds={this.state.filBoundValue}
						onZoom={this.onZoom}
						key={10000}
						center={this.state.centerPoint}
					>
						{this.state.mapPointsName ? this.state.mapPointsName.map((mapPointName, key) => (
							<Marker
								onMouseEnter={() => this.activeToolpitHandler(key, this.state.mapCoord[mapPointName].coords.x_axis, this.state.mapCoord[mapPointName].coords.y_axis)}
								style={{ zIndex: 10 - key }}
								key={key}
								coordinates={[this.state.mapCoord[mapPointName].coords.x_axis, this.state.mapCoord[mapPointName].coords.y_axis]}
								anchor="bottom">
								<div style={{ cursor: 'pointer' }} onClick={() =>
									this.exitGroupHandler(
										this.state.coordsDetail[mapPointName].group_id
									)
								}>
									<p>{unicodeToChar(this.state.coordsDetail[mapPointName].group_name)}</p>
									<span>
										{this.state.coordsDetail ? (
											this.state.coordsDetail[mapPointName].event_venue in this.state.symbols
												? (
													<img src={this.state.symbols[this.state.coordsDetail[mapPointName].event_venue].icon} />
												) : (
													<img src={mapPoint} />
												)
										) : (
												<img src={mapPoint} />
											)}
									</span>
								</div>

								{/* <img src={talkIcon}/> */}

							</Marker>
						)) : ''}


						{this.state.mapPointsName ? this.state.mapPointsName.map((mapPointName, key) => (
							<Marker
								style={{ zIndex: 9999 }}

								key={key}
								coordinates={[this.state.mapCoord[mapPointName].coords.x_axis, this.state.mapCoord[mapPointName].coords.y_axis]}
								anchor="bottom">

								{/* <img src={talkIcon}/> */}
								{this.state.coordsDetail ? (
									<div onClick={() =>
										this.exitGroupHandler(
											this.state.coordsDetail[mapPointName].group_id
										)
									} className={this.state.activeTooltip == key ? "tooltip saperated active" : "tooltip saperated"}>
										{this.state.coordsDetail[mapPointName].group_name ? (
											<h5
												onClick={() =>
													this.exitGroupHandler(
														this.state.coordsDetail[mapPointName].group_id
													)
												}
											>
												{unicodeToChar(this.state.coordsDetail[mapPointName].group_name)}
											</h5>
										) : (
												""
											)}
										{this.state.coordsDetail[mapPointName].livestream
											.ls_url && this.state.coordsDetail[mapPointName].livestream.ls_active == 'active' ? (
												<span className="live-btn">LIVE</span>
											) : (
												""
											)}
										{this.state.coordsDetail[mapPointName].event_date ? <p className="time-date">
											{unicodeToChar(this.state.coordsDetail[mapPointName].event_date)} <br /> {this.state.coordsDetail[mapPointName].event_time ? unicodeToChar(this.state.coordsDetail[mapPointName].event_time) : ''}
										</p> : ""}
										{this.state.coordsDetail[mapPointName]
											.event_location ? (
												<span style={{ marginBottom: '15px' }}>
													{
														unicodeToChar(this.state.coordsDetail[mapPointName]
															.event_location)
													}
												</span>
											) : (
												""
											)}

										{/* <span className="live-btn">LIVE</span> */}
										{/* <span><img src={viewIcon} /> 341 viewers</span> */}
										{this.state.coordsDetail[mapPointName].group_desc ? (
											<span>
												{unicodeToChar(this.state.coordsDetail[mapPointName].group_desc)}
											</span>
										) : (
												""
											)}
									</div>
								) : (
										""
									)}
							</Marker>
						)) : ''}

					</Map> : ''}

				</div>
			</Layout>
		);
		if (this.state.homeView) {
			pageView = <Home backBtn={true} parentMapUrl={this.state.parentMapUrl} />;
		}
		return pageView;
	}
}

const mapStateToProps = (state) => ({
	step: state.welcomeStep.step
});
const mapDispatchToProps = (dispatch) => {
	return {
		groupDetail: (details) => dispatch(actions.groupDetail(details)),
		stepHandler: (step) => dispatch(actions.stepHandler(step)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MapView);

// export default MapView;
