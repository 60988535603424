import { isString } from "lodash";

export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties
	};
};

// export const checkValidity = (value, rules) => {
// 	let isValid = true;
// 	if (!rules) {
// 		return true;
// 	}

// 	if (rules.required) {
// 		isValid = value.trim() !== '' && isValid;
// 	}

// 	if (rules.minLength) {
// 		isValid = value.length >= rules.minLength && isValid
// 	}

// 	if (rules.maxLength) {
// 		isValid = value.length <= rules.maxLength && isValid
// 	}

// 	if (rules.isEmail) {
// 		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
// 		isValid = pattern.test(value) && isValid
// 	}

// 	if (rules.isNumeric) {
// 		const pattern = /^\d+$/;
// 		isValid = pattern.test(value) && isValid
// 	}

// 	return isValid;
// }

export const checkValidity = (value, rules) => {
	let isvalid = null;

	rules.forEach(rule => {
		if (rule['type'] == 'required') {
			if (isString(value)) {
				value = value.trim()
			}
			if (value.length == 0) {
				isvalid = { 'error': rule['message'] }
			}
		}
		if (rule['type'] == 'isEmail') {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) == false) {
				isvalid = { 'error': rule['message'] }
			}

		}
		if (rule['type'] == 'minLength') {
			if (value.length < rule['length']) {
				isvalid = { 'error': rule['message'] }
			}
		}
	})
	return isvalid;
}


export const unicodeToChar = (text) => {
	if (text == undefined)
		text = ""
	return text.replace(/\\u[\dA-F]{4}/gi,
		function (match) {
			return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
		});
}

export const quote = (string) => {
	// sequences.
	var escapable = /[\\\"\x00-\x1f\x7f-\uffff]/g,
		meta = {    // table of character substitutions
			'\b': '\\b',
			'\t': '\\t',
			'\n': '\\n',
			'\f': '\\f',
			'\r': '\\r',
			'"': '\\"',
			'\\': '\\\\'
		};

	escapable.lastIndex = 0;
	return escapable.test(string) ?
		string.replace(escapable, function (a) {
			var c = meta[a];
			return typeof c === 'string' ? c :
				'\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
		}) :
		string;
}


