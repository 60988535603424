import React, { Component } from 'react';
import { instance, baseURL } from "../../axios";
import BMF from 'browser-md5-file';
import avtarIcon from '../../assets/images/camera-icon.png';
import ImagePlaceholeder from '../../assets/images/event-placeholder.png';
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import WelcomePopup from '../welcomePopup/welcomePopup';
import './createEventBox.scss';
import { unicodeToChar, quote } from "../../shared/utility";
import { channelOne } from "../../pusher";

class CreateEventBox extends Component {
	state = {
		group_id: '',
		userDetail: '',
		userImg: ImagePlaceholeder,
		eventName: '',
		eventDescription: '',
		streamLink: '',
		lineStatus: true,
		mainFile: '',
	}
	// componentDidUpdate(prevProps, prevState) {

	// 	if (prevProps.editMode !== this.props.editMode) {
	// 		let userID = localStorage.getItem("user_id");
	// 		let photoID = localStorage.getItem("photo_id");
	// 		this.setState({
	// 			group_id: this.props.groupDetail.group_id,
	// 			// groupDetail: 
	// 			eventName: this.props.groupDetail.group_name ? unicodeToChar(this.props.groupDetail.group_name) : this.props.groupDetail.group_name,
	// 			eventDescription: this.props.groupDetail.group_desc ? unicodeToChar(this.props.groupDetail.group_desc) : this.props.groupDetail.group_desc,
	// 			streamLink: this.props.groupDetail.livestream.ls_service == 'twitch' ? 'https://www.twitch.tv/' + this.props.groupDetail.livestream.ls_name : '',
	// 			lineStatus: this.props.groupDetail.livestream.ls_active == 'active' ? true : false

	// 		})
	// 		if (this.props.groupDetail.group_logo_id) {
	// 			instance.get(`_getphotolead.json?user_id=${userID}&photo_id=${photoID}&admin_pass=&object_id=${this.props.groupDetail.group_logo_id}`)
	// 				.then(res => {
	// 					console.log(res)
	// 					this.setState({
	// 						userImg: baseURL + res.data.thumb_value.split("..")[1]
	// 					})
	// 				})
	// 		}

	// 	}
	// }

	componentDidMount() {
		let userID = localStorage.getItem("user_id");
		let photoID = localStorage.getItem("photo_id");

		this.setState({
			showWelcomePopup: userID ? false : true
		})
		if (userID) {
			this.setState({
				userID: userID,
				photoID: photoID
			})
			if (this.props.editMode) {
				let userID = localStorage.getItem("user_id");
				let photoID = localStorage.getItem("photo_id");
				this.setState({
					group_id: this.props.groupDetail.group_id,
					// groupDetail: 
					eventName: this.props.groupDetail.group_name ? unicodeToChar(this.props.groupDetail.group_name) : this.props.groupDetail.group_name,
					eventDescription: this.props.groupDetail.group_desc ? unicodeToChar(this.props.groupDetail.group_desc) : this.props.groupDetail.group_desc,
					streamLink: this.props.groupDetail.livestream.ls_service == 'twitch' ? 'https://www.twitch.tv/' + this.props.groupDetail.livestream.ls_name : '',
					lineStatus: this.props.groupDetail.livestream.ls_active == 'active' ? true : false

				})
				if (this.props.groupDetail.group_logo_id) {
					instance.get(`_getphotolead.json?user_id=${userID}&photo_id=${photoID}&admin_pass=&object_id=${this.props.groupDetail.group_logo_id}`)
						.then(res => {
							console.log(res)
							this.setState({
								userImg: baseURL + res.data.thumb_value.split("..")[1],
								group_logo_id: this.props.groupDetail.group_logo_id,
							})
						})
				}
			}
			if (this.props.editMode != true) {

				instance.get(`_getfomoonboard.json?user_id=${userID}&photo_id=${photoID}`)
					.then(res => {
						let requiredDetail = res.data.onboard_dic.profile.actions.new_event;
						let getGuest = res.data.onboard_completed_dic.profile
						let checkFields = requiredDetail.filter(element => getGuest.includes(element))
						console.log(checkFields, requiredDetail)
						if (checkFields.length != requiredDetail.length) {
							console.log('work')
							this.props.stepHandler(9, 'signup', 'new_event')
							this.setState({
								showWelcomePopup: true
							})
						}
						console.log(res)
					})
			}


			instance.get(`_fomomsg_getprofile?user_id=${userID}&photo_id=${photoID}`)
				.then(res => {
					console.log(res)
					this.setState({
						userDetail: res.data
					})

				})



		}

		// channelOne.bind_global((event, data) => {
		// 	if (data.approval_status == 'group_logo') {
		// 		this.setState({
		// 			userImg: baseURL + data.thumb_value
		// 		})

		// 	}

		// })


	}


	uploadHandler = (e) => {
		e.preventDefault();
		const bmf = new BMF();
		let that = this;
		let reader = new FileReader();
		let file = e.target.files[0];
		let FileName = e.target.value.split('\\').pop();
		if (e.target.files[0]) {
			this.setState({
				mainFile: file
			})

			reader.onloadend = () => {
				that.setState({
					userImg: reader.result,
				});

				// localStorage.setItem('changeImage', reader.result)
			}
			reader.readAsDataURL(file)
		}


	}

	submitHandler = async () => {
		console.log(this.state.lineStatus)
		let lineStatus = this.state.lineStatus ? 'active' : "";
		let eventDescription = this.state.eventDescription
		console.log('working');
		let userID = this.state.userID;
		let photoID = this.state.photoID;
		let streamLink = this.state.streamLink ? this.state.streamLink.split('/') : [];
		let chennalName = streamLink.filter(name => {
			if (name != '') {
				return name
			}
		})
		const bmf = new BMF();
		let groupId = '';
		let groupType = '';
		let groupName = '';
		let groupDesc = '';
		let groupLink = '';

		let editName = false;
		let editDesc = false;
		let editLink = false;
		if (this.props.editMode) {
			let getLiveCHanges = this.state.lineStatus ? 'Active' : 'Inactive'
			if (this.state.eventName != this.props.groupDetail.group_name) {
				editName = true
			}
			if (eventDescription != this.props.groupDetail.group_desc) {
				editDesc = true
			}
			if (chennalName[chennalName.length - 1] != this.props.groupDetail.livestream.ls_name || getLiveCHanges != this.props.groupDetail.livestream.ls_active) {
				editLink = true
			}

		}
		if (this.props.editMode != true) {
			editName = true;
			editDesc = true;
			editLink = true;
			groupId = await instance.get(`_newrandgroupid.json?userid=${userID}&photoid=${photoID}`)
			groupType = await instance.get(`${groupId ? groupId.data.groupid : this.state.group_id}/_updategroupsettings.json?user_id=${userID}&photo_id=${photoID}&adminpass=&update_field=event_type&update_value=event`)

		}

		if (this.state.mainFile) {

			bmf.md5(this.state.mainFile, (err, md5) => {

				this.setState({
					hash: md5
				})
				let photo_id = this.state.photoID;
				let user_id = this.state.userID;
				let formData = new FormData();
				formData.append('avatar', this.state.mainFile)
				formData.append('hash', md5)
				formData.append('uuid', this.state.group_id)
				instance.post(`_photo_upload`, formData)
					.then(res => {
						let splitRes = res.data.split('/')
						let fileName = splitRes.pop()
						let joinSplit = splitRes.join('/')
						console.log(res)
						instance.get(`${groupId ? groupId.data.groupid : this.state.group_id}/_photolead_v2?value=${res.data}&thumb_value=${joinSplit}/thumbs/${fileName}&group_id=${groupId ? groupId.data.groupid : this.state.group_id}&user_id=${user_id}&photo_id=${photo_id}&approval_status=group_logo&ledby=${this.state.userDetail.alias}&photo_hash=${md5}&content_type=photo&os_type=iPhone&admin_password=&user_type=reactjs&caption=`)
							.then(respo => {
								console.log(respo)
								let getTableId = localStorage.getItem('groupTable') ? localStorage.getItem('groupTable') : ''
								if (this.props.editMode) {
									this.props.click();

								}
								else {
									window.location.pathname = '/' + groupId.data.groupid + '/' + this.state.userDetail.invited_by_id + '/' + getTableId
								}
							})


					})

			});

		}




		if (editName) {
			groupName = await instance.get(`${groupId ? groupId.data.groupid : this.state.group_id}/_updategroupsettings.json?user_id=${userID}&photo_id=${photoID}&adminpass=&update_field=group_name&update_value=${quote(this.state.eventName)}`)
		}

		if (editDesc) {
			groupDesc = await instance.get(`${groupId ? groupId.data.groupid : this.state.group_id}/_updategroupsettings.json?user_id=${userID}&photo_id=${photoID}&adminpass=&update_field=group_desc&update_value=${quote(eventDescription)}`)
		}

		if (editLink) {
			groupLink = await instance.get(`${groupId ? groupId.data.groupid : this.state.group_id}/_updategroupmainlivestream.json?user_id=${userID}&photo_id=${photoID}&admin_pass=&update_struct[ls_url]=${chennalName[chennalName.length - 1]}&update_struct[ls_type]=video&update_struct[ls_protocol]=channel&update_struct[ls_service]=twitch&update_struct[ls_name]=${chennalName[chennalName.length - 1]}&update_struct[ls_active]=${lineStatus}`)
		}






		console.log(groupName, 'name')
		console.log(groupDesc, 'desc')
		console.log(groupLink, 'Link')
		console.log('working')

		if (this.state.mainFile == '') {
			let getTableId = localStorage.getItem('groupTable') ? localStorage.getItem('groupTable') : ''

			if (this.props.editMode) {
				this.props.click();


			}
			else {
				window.location.pathname = '/' + groupId.data.groupid + '/' + this.state.userDetail.invited_by_id + '/' + getTableId
			}

		}


	}
	inputHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	linkBlurHandler = (e, type) => {
		let linkRegexp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

		if (this.state[e.target.name].trim()) {
			if (linkRegexp.test(e.target.value)) {
			}
			else {
				this.setState({
					[e.target.name]: type + e.target.value,

				})
			}
		}
	}
	linkHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value.trim(),
		})
	}
	checkHandler = (e) => {
		// console.log(e.target.checked)
		this.setState({
			lineStatus: e.target.checked
		})

	}


	render() {

		return (
			<div className="create-event-box">
				<WelcomePopup click={this.popupCloseHandler} toggleHandler={this.state.showWelcomePopup} />
				<div className="field-group">
					<figure>
						<img src={this.state.userImg ? this.state.userImg : avtarIcon} alt="user" />
						<div className="upload-box">
							<input autoComplete="off" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.uploadHandler} />
							<label className="btn"><i className="ion-edit" /></label>
						</div>
					</figure>
					<div className="form-group">
						<input type="text" value={unicodeToChar(this.state.eventName)} name="eventName" onChange={this.inputHandler} placeholder="Event name" className="name-control" />
					</div>
				</div>
				<div className="field-group">
					<div className="form-group">
						<label>About</label>
						<textarea value={unicodeToChar(this.state.eventDescription)} name="eventDescription" onChange={this.inputHandler} placeholder="A short description"></textarea>
					</div>
				</div>
				<div className="field-group">
					<div className="form-group">
						<div className="flex">
							<label>Stream link</label>
							{this.props.editMode ?
								<div className="switch-box">
									<h5>{this.state.lineStatus ? 'Active' : 'Inactive'} </h5>
									<label class="switch">
										<input type="checkbox" onChange={this.checkHandler} checked={this.state.lineStatus} />
										<span class="slider round"></span>
									</label> </div> : null


							}


						</div>
						<input type="text" value={this.state.streamLink} name="streamLink" onBlur={e => this.linkBlurHandler(e, 'https://www.twitch.tv/')} onChange={this.linkHandler} className="form-control" placeholder="https://www.twitch.tv/joinfomo" />
					</div>
				</div>
				<div className="field-group btn-group">
					<button type="button" onClick={this.submitHandler} disabled={this.state.streamLink == '' || this.state.eventDescription == '' || this.state.eventName == '' ? true : false} className="btn btn-pink">{this.props.editMode ? 'SAVE' : 'CONTINUE'} </button>
				</div>
			</div>
		)
	}
}




const mapStateToProps = (state) => ({
	step: state.welcomeStep.step
});
const mapDispatchToProps = (dispatch) => {
	return {
		stepHandler: (step, type, checkType) => dispatch(actions.stepHandler(step, type, checkType)),

	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateEventBox);

