import React from 'react'
import './signOut.scss';
import Popup from '../popup/popup';
import informationIcon from '../../assets/images/information.png';

const signOut = (props) => {
	return (
		<Popup type={props.type == 'without-email' ? "sign-out without-email" : "sign-out"} click={props.cancelHandler} toggleHandler={props.showPopup}>

			<h2>{props.type == 'without-email' ?
				<img src={informationIcon} /> : null
			} Are you sure you want to sign out?</h2>
			{props.type == 'without-email' ? <p>You will not be able to log back in without signing up with email</p> : null}
			<ul>
				{props.type == 'without-email' ? <li><button type="button" onClick={props.signInHandler} className="btn btn-pink">SIGN UP</button></li> : <li><button onClick={props.signOutHandler} type="button" className="btn btn-pink">YES, SIGN OUT</button></li>}
				{props.type == 'without-email' ? <li><button type="button" onClick={props.signOutHandler} className="btn btn-outline">Sign out anyway</button></li> : null}
				<li><button type="button" onClick={props.cancelHandler} className="btn btn-simple">Cancel</button></li>
			</ul>
		</Popup>
	)
}


export default signOut
