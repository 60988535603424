import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import './profile.scss';
import avtarIcon from '../../assets/images/avtar.png';
import { instance } from '../../axios';
import BMF from 'browser-md5-file';
import ProfileLayout from '../../hoc/profileLayout/profileLayout'
import Loading from '../../components/loading/loading';
import Popup from '../../components/popup/popup';
import errorIcon from '../../assets/images/error-icon.svg';



class Profile extends Component {
	state = {
		name: '',
		showDOB: false,
		month: '',
		day: '',
		year: '',
		looking: '',
		gender: { value: '', label: '' },
		instagram: '',
		facebook: '',
		twitter: '',
		soundcloud: '',
		uploadImage: '',
		imageFile: '',
		imageName: '',
		success: false,
		regexp: /^[0-9\b]+$/,
		hash: null,
		interest: '',
		aboutMe: '',
		linkRegexp: /^(https?:\/\/)?([a-z\d]([a-z\d-.]?[a-z\d])*\.[a-z]([a-z\.]?[a-z])*){2,255}(\/[\w-\.~!$&'()*+,;=:@%]*)*$/i,
		letters: /^[A-Z a-z]+$/


	}
	componentDidMount() {
		this.setState({
			userID: localStorage.getItem('tempUser_id'),
			photoID: localStorage.getItem('tempPhoto_id')
		})
	}
	inputHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	nameHandler = (e) => {
		if(e.target.value == ''||this.state.letters.test(e.target.value)){
			console.log(e.target.value)
			this.setState({
				[e.target.name]: e.target.value,
				showError: false,
			})
			let uersName =  e.target.value.split(' ')
			let removeBlank = uersName.filter(name => {
				if(name !== ""){
					return name
				}
			})

			if(removeBlank.length == 1){
				this.setState({
					firstName: removeBlank[0],
					lastName: '',
					middleName: ''
				})
			}
			if(removeBlank.length == 2){
				this.setState({
					firstName: removeBlank[0],
					lastName: removeBlank[1],
					middleName: ''
				})
			}
			if(removeBlank.length == 3){
				this.setState({
					firstName: removeBlank[0],
					middleName: removeBlank[1],
					lastName: removeBlank[2],
				})
			}
		}
	}
	linkHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})

	}
	linkBlurHandler = (e, type) => {
		if (this.state.linkRegexp.test(e.target.value)) {
			this.setState({
				[e.target.name]: e.target.value
			})
		}
		else {
			this.setState({
				[e.target.name]: type + e.target.value
			})
		}
	}
	// numberHandler = (e, valueLength, maxVal) => {

	// 	if (e.target.value === '' || (this.state.regexp.test(e.target.value) && e.target.value.length <= valueLength && e.target.value <= maxVal)) {
	// 		this.setState({
	// 			[e.target.name]: e.target.value
	// 		})
	// 	}
	// }
	numberHandler = (e, valueLength, minVal, maxVal) => {
		console.log(e.target.value)
		if (e.target.value === '' || (this.state.regexp.test(e.target.value) && e.target.value.length <= valueLength && e.target.value > minVal && e.target.value <= maxVal)) {
			this.setState({
				[e.target.name]: e.target.value
			})
		}
	}
	checkHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.checked
		})
	}
	ganderHandler = (value) => {
		this.setState({
			gender: value,
		})
	}
	lookHaldner = (value) => {
		this.setState({
			looking: value,
		})
	}
	uploadHandler = (e) => {
		e.preventDefault();
		const bmf = new BMF();
		let that = this;
		let reader = new FileReader();
		let file = e.target.files[0];


		bmf.md5(
			file,
			(err, md5) => {
				this.setState({
					hash: md5
				})
				let photo_id = localStorage.getItem('tempPhoto_id');
				let user_id = localStorage.getItem('tempUser_id');
				let formData = new FormData();
				formData.append('avatar', file)
				formData.append('hash', md5)
				formData.append('uuid', '@' + photo_id)
				instance.post(`_photo_upload?uuid=@${photo_id}&hash=${md5}`, formData)
					.then(res => {
						instance.get(`@${photo_id}/_photolead_v2?value=${res.data}&group_id=@${photo_id}&user_id=${user_id}&photo_id=${photo_id}&ledby=${that.state.name}&photo_hash=${md5}&thumb_value=${res.data}&content_type=photo&caption=${that.state.name}&approval_status=profile&os_type=iPhone`)
							.then(res => {
							})

					})

			},
		);
		reader.onloadend = () => {
			that.setState({
				uploadImage: reader.result,
			});
		}
		reader.readAsDataURL(file)

	}

	submitHandler = (e) => {
		e.preventDefault();
		if(this.state.name != ""){
			var today = new Date();
			var birthDate = new Date(this.state.month + '/' + this.state.day + '/' + this.state.year);
			var age = birthDate.getFullYear() ?  today.getFullYear() - birthDate.getFullYear(): '';
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age = age - 1;
			}

			this.setState({
				loading: true,
			})
			console.log(age ,'age')
			instance.get(`_fomomsg_updateprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}&alias=${this.state.firstName}&update_struct[name_first]=${this.state.firstName}&update_struct[name_middle]=${this.state.middleName}&update_struct[name_last]=${this.state.lastName}&update_struct[profile_desc]=${this.state.aboutMe}&update_struct[profile_interests]=${this.state.interest}&update_struct[profile_age]=${age.toString() ? age.toString() : ''}&update_struct[link_instagram]=${this.state.instagram}&update_struct[link_facebook]=${this.state.facebook}&update_struct[link_twitter]=${this.state.twitter}&update_struct[link_soundcloud]=${this.state.soundcloud}`)
				.then(res => {
					this.setState({
						loading: false,
					})

					if (res.data.valid_response === 'VALID') {
						this.setState({
							success: true,
						})
						localStorage.removeItem('tempPhoto_id');
						localStorage.removeItem('tempUser_id');

					}
				})
		}
		else{
			this.setState({
				showError: true
			})
		}

	}
	render() {
		return (
			<ProfileLayout>
				{/* <Popup click={() => this.setState({showError: false})} toggleHandler={this.state.showError} type="file-popup">
					<div className="box">
						<div className="file-uploaded">
								<figure>
									<img src={errorIcon} />
								</figure>
								<h6>Name is required</h6>
								<p>Please enter a name to continue (you can skip the rest if you like).</p>
								<button type="button" onClick={() => this.setState({showError: false})} className="btn btn-pink">Close</button>
						</div>
					</div>
				</Popup> */}
				<Loading show={this.state.loading}/>
				{this.state.success ? <Redirect to={localStorage.getItem('confirmLink')} /> : ''}
				<div className="profile-group">

					<div className="flex space-between">
						<div className="form-section">
							<form autoComplete="off" onSubmit={this.submitHandler}>
								<div className={this.state.showError ? "form-group error-group" : "form-group"}>
									<label>Your Name</label>
									<input autoComplete="off" type="text" className="form-control" name="name" onChange={this.nameHandler} value={this.state.name} placeholder="Enter your name" />
									{this.state.showError ? <div className="input-error"><p>Please enter your name</p></div>: null}
								</div>
								<div className="field-group">
									<label>Birthdate</label>
									<div className="form-group month">
										<input autoComplete="off" type="text" className="form-control" name="month" value={this.state.month} onChange={(e) => this.numberHandler(e, 2, 0, 12)} placeholder="MM" />
									</div>
									<div className="form-group day">
										<input autoComplete="off" type="text" className="form-control" name="day" value={this.state.day} onChange={(e) => this.numberHandler(e, 2, 0, 31)} placeholder="DD" />
									</div>
									<div className="form-group year">
										<input autoComplete="off" type="text" className="form-control" name="year" value={this.state.year} onChange={(e) => this.numberHandler(e, 4, 0, 2010)} placeholder="YYYY" />
									</div>
									<div className="check-group">
										<input autoComplete="off" type="checkbox" name="showDOB" checked={this.state.showDOB} onChange={this.checkHandler} />
										<label>Show my age on my profile</label>
									</div>
								</div>
								{/* <div className="form-group">
									<label>Looking for<span> Will not be displayed publicly</span></label>
									<Select className="select-control" placeholder="Please select one" onChange={this.lookHaldner} options={lookingOptions} />
								</div> */}
								<div className="form-group">
									<label>A fact about me that surprises people</label>
									<textarea className="form-control" value={this.state.aboutMe} onChange={this.inputHandler} name="aboutMe" placeholder="Write something..."></textarea>
								</div>
								<div className="form-group">
									<label>My favorite things to do are...</label>
									<textarea className="form-control" value={this.state.interest} onChange={this.inputHandler} name="interest" placeholder="Write something..."></textarea>
								</div>
								{/* <div className="form-group">
									<label>Gender</label>
									<Select className="select-control" placeholder="Please select one" onChange={this.ganderHandler} options={genderOptions} />
								</div> */}
								<div className="link-group">
									<label>Links<span> Optional</span></label>
									<div className="form-group">
										<label>
											Instagram
										</label>
										<input autoComplete="off" type="text" className="form-control" name="instagram" value={this.state.instagram} onBlur={e => this.linkBlurHandler(e, 'https://www.instagram.com/')} onChange={this.linkHandler} />
									</div>
									<div className="form-group">
										<label>
											Facebook
										</label>
										<input autoComplete="off" type="text" className="form-control" name="facebook" value={this.state.facebook} onBlur={e => this.linkBlurHandler(e, 'https://www.facebook.com/')} onChange={this.linkHandler} />
									</div>
									<div className="form-group">
										<label>
											Twitter
										</label>
										<input autoComplete="off" type="text" className="form-control" name="twitter" value={this.state.twitter} onBlur={e => this.linkBlurHandler(e, 'https://twitter.com/')} onChange={this.linkHandler} />
									</div>
									<div className="form-group">
										<label>
											Soundcloud
										</label>
										<input autoComplete="off" type="text" className="form-control" name="soundcloud" value={this.state.soundcloud} onBlur={e => this.linkBlurHandler(e, 'https://soundcloud.com/')} onChange={this.linkHandler} />
									</div>
								</div>
								<ul className="btn-box">
									<li><button type="submit" className="btn btn-sm">Continue</button></li>
									
									{/* <li><Link to={localStorage.getItem('confirmLink')} className="btn btn-gray">Skip for now</Link></li> */}
								</ul>
							</form>
						</div>
						<div className="avtar-section">
							<div className="upload-box">
								<figure>
									<img src={this.state.uploadImage ? this.state.uploadImage : avtarIcon} alt="user" />
								</figure>
								<input autoComplete="off" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.uploadHandler} />
								<label className="btn btn-sm">Upload a picture</label>
							</div>
						</div>
					</div>
				</div>
			</ProfileLayout>
		)
	}
}
export default Profile;
