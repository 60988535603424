import React from 'react';
import './loading.scss';
import loadingImg from '../../assets/images/pinwheel.gif'

const loading = (props) => {
	// const [userDropdown, setuserDropdown] = useState(false);
	return (
		<div className={props.show ? "loading-box active": 'loading-box'}>
            <div className="inner-box">
                <figure>
                    <img src={loadingImg}/>
                </figure>
                <p>Please wait</p>
            </div>
        </div>
	)
}

export default loading;
