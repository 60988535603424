import React, { Component } from "react";
import "./header.scss";
import avtarIcon from "../../assets/images/avtar.png";
import { connect } from "react-redux";
import { baseURL, instance } from "../../axios";
import EditProfile from "../editProfile/editProfile";
import { Redirect } from "react-router-dom";
import DetailPopup from "../detailPopup/detailPopup";
import backIcon from "../../assets/images/back-icon.svg";
import { quote, unicodeToChar } from "../../shared/utility";
import * as actions from "../../store/actions";
import EditEvent from "../editEvent/editEvent";
import { Link } from "react-router-dom";
import SignOut from "../signOut/signOut";

class Header extends Component {
  state = {
    linkDropdown: false,
    groupDetail: "",
    userDropdown: false,
    showPopup: false,
    inviteLink: "",
    showDetail: false,
    guestUser: false,
    showUpdateImage: false,
    showEditPopup: false,
    emailContent: "",
    signPopup: false,
    onboardCompleted: "",
  };
  dropdownHandler = (type) => {
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };

  profileHandlar = () => {
    instance
      .get(
        `_fomomsg_getprofile?user_id=${this.state.userID}&photo_id=${this.state.photoID}&notification_flag=true`
      )
      .then((res) => {
        this.setState((prevState) => ({
          showPopup: true,
          userDetail: res.data,
        }));
      });
  };

  componentDidMount() {
    let userID = localStorage.getItem("user_id");
    let photoID = localStorage.getItem("photo_id");
    let groupTable = window.location.pathname.split("/")[3]
      ? window.location.pathname.split("/")[3].toUpperCase()
      : window.location.pathname.split("/")[3];
    let groupEvent = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1].toUpperCase()
      : window.location.pathname.split("/")[1];
    this.setState({
      userID: userID,
      photoID: photoID,
    });
    // this.setState({
    // 	guestUser: JSON.parse(localStorage.getItem('guestUser'))
    // })
    instance
      .get(`_getfomoonboard.json?user_id=${userID}&photo_id=${photoID}`)
      .then((res) => {
        console.log(res);
        let onboadring = res.data.onboard_dic.profile.onboarding;
        let onboardComplete = res.data.onboard_completed_dic.profile;

        this.setState({
          onboardCompleted: onboardComplete,
        });
        let checkFields = onboardComplete.filter((element) =>
          onboadring.includes(element)
        );
        console.log(checkFields, onboadring, onboardComplete);
        if (checkFields.length !== onboadring.length) {
          this.setState({
            guestUser: true,
          });
        }
        this.setState({
          emailContent: res.data.onboard_completed_dic.profile.find((val) => {
            if (val == "email") {
              return val;
            }
          }),
        });
        // if (!res.data.onboard_completed_dic.profile.includes('onboarding')) {
        // 	this.setState({
        // 		guestUser: true
        // 	})
        // }
        // else {
        // 	let onboadring = res.data.onboard_dic.profile.onboarding
        // 	let onboardComplete = res.data.onboard_completed_dic.profile

        // 	let checkFields = onboardComplete.filter(element => onboadring.includes(element))
        // 	console.log(checkFields, onboadring)
        // 	if (checkFields.length !== onboadring.length) {
        // 		this.setState({
        // 			guestUser: true
        // 		})
        // 	}

        // }
      });
    if (groupTable != "_login" && groupEvent != "_login") {
      instance
        .get(
          `${groupEvent}/_getsharelink?user_id=${userID}&photo_id=${photoID}&app_type=RJS-web&subgroup_id=${groupTable}`
        )
        .then((res) => {
          console.log(res.data.web_share_link);
          this.setState({
            inviteLink: res.data.web_share_link,
          });
        });
      let mapId = window.location.pathname.split("/")[4]
        ? window.location.pathname.split("/")[4]
        : null;
      if (mapId != null) {
        instance
          .get(
            `${groupEvent}/_getmapdataparent.json?user_id=${userID}&photo_id=${photoID}&admin_pass={{admin_password}}&alias=jhon&ptr_dic_flag=true&mapdata_id=${mapId}`
          )
          .then((res) => {
            console.log(res, "woking");
            if (mapId in res.data.mapdata_dic) {
              console.log("working new");
              let parentid_list = Object.keys(res.data.mapdata_dic[mapId]);

              if (parentid_list.indexOf(groupEvent) == -1) {
                this.setState({
                  backBtn:
                    parentid_list[0] +
                    "/" +
                    window.location.pathname.split("/")[2] +
                    "/" +
                    groupTable +
                    "/" +
                    mapId,
                });
              }
            }
          });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        groupDetail: this.props.groupDetail,
      });
    }
    if (prevProps.updateProfileImg !== this.props.updateProfileImg) {
      this.setState({
        updateImage: this.props.updateProfileImg,
        showUpdateImage: true,
      });
    }
  }

  detailPopupHandler = () => {
    this.setState((prevState) => ({
      showDetail: !prevState.showDetail,
    }));
  };

  backBtn = () => {
    window.location.pathname = "/" + this.state.backBtn;
  };

  welcomeStepHandler = (val, type) => {
    this.props.stepHandler(val, type);
  };

  editEventPopup = () => {
    this.setState((prevState) => ({
      showEditPopup: !prevState.showEditPopup,
    }));
  };
  cancelsignOutHandler = () => {
    this.setState((prevState) => ({
      userDropdown: false,
      signPopup: !prevState.signPopup,
    }));
  };
  signOutHandler = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("photo_id");
    localStorage.removeItem("user_registerEmail");
    window.location.reload();
  };
  signInHandler = () => {
    this.setState({
      signPopup: false,
    });
    this.welcomeStepHandler(8, "signup");
  };
  render() {
    let fullName = this.props.userDetail ? this.props.userDetail.alias : "";
    return (
      <header className={this.props.headerClass ? this.props.headerClass : ""}>
        {this.state.showEditPopup ? (
          <EditEvent
            groupDetail={this.props.groupDetail}
            showPopup={this.state.showEditPopup}
            click={this.editEventPopup}
          />
        ) : null}
        <SignOut
          type={this.state.emailContent ? "with-email" : "without-email"}
          showPopup={this.state.signPopup}
          signInHandler={this.signInHandler}
          signOutHandler={this.signOutHandler}
          cancelHandler={this.cancelsignOutHandler}
        />
        <DetailPopup
          onboardCompleted={this.state.onboardCompleted}
          groupDetail={this.props.groupDetail}
          click={this.detailPopupHandler}
          toggleHandler={this.state.showDetail}
        />
        {this.state.success ? <Redirect to="/login" /> : null}
        {this.state.showPopup ? (
          <EditProfile
            onboardCompleted={this.state.onboardCompleted}
            userDetail={this.state.userDetail}
            click={() => this.setState({ showPopup: false })}
            toggleHandler={this.state.showPopup}
          />
        ) : null}
        <div className="flex space-between items-align-center">
          {this.state.backBtn ? (
            <button type="button" onClick={this.backBtn} className="back-btn">
              <img src={backIcon} /> Back to Map View
            </button>
          ) : (
            <div className="nothing">Nothing to show</div>
          )}
          <div className="event-title">
            <p>
              {this.state.groupDetail
                ? this.state.groupDetail.group_name
                  ? unicodeToChar(this.state.groupDetail.group_name)
                  : unicodeToChar(this.state.groupDetail.group_id)
                : null}
            </p>
            {/* <p>{this.state.groupDetail ? this.state.groupDetail.group_id : ''} </p> */}
          </div>

          <ul className="navbar">
            {this.props.groupDetail ? (
              this.props.groupDetail.user_access == "USER" ? (
                <li>
                  <button
                    className="detail-btn"
                    onClick={this.detailPopupHandler}
                    type="button"
                  >
                    Detail
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    className="edit-btn"
                    onClick={this.editEventPopup}
                    type="button"
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </li>
              )
            ) : null}
            {this.state.guestUser ? (
              <>
                <li>
                  <button
                    onClick={() => this.welcomeStepHandler(3, "login")}
                    className="login-btn"
                    type="button"
                  >
                    Log in
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => this.welcomeStepHandler(8, "signup")}
                    className="signUp-btn"
                    type="button"
                  >
                    Sign up
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <span onClick={() => this.dropdownHandler("linkDropdown")}>
                    <i className="fa fa-link" aria-hidden="true"></i>
                  </span>
                  <div
                    className="dropdown"
                    style={{
                      display: this.state.linkDropdown ? "block" : "none",
                    }}
                  >
                    <div
                      onClick={() => this.setState({ linkDropdown: false })}
                      style={{
                        display: this.state.linkDropdown ? "block" : "none",
                      }}
                      className="back-drop"
                    ></div>
                    <ul>
                      <li>
                        <div className="link-box">
                          <p>Invite link</p>
                          <div className="form-group">
                            <input
                              type="text"
                              name="inviteLink"
                              value={this.state.inviteLink}
                              disabled={true}
                            />
                            <button type="button">
                              <i className="fa fa-clone" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}
            <li className="user-options">
              <span onClick={() => this.dropdownHandler("userDropdown")}>
                <figure>
                  {this.state.showUpdateImage ? (
                    <img src={this.state.updateImage} />
                  ) : (
                    <img
                      src={
                        this.props.userDetail.thumb_value
                          ? baseURL +
                            this.props.userDetail.thumb_value.split("..")[1]
                          : avtarIcon
                      }
                    />
                  )}
                </figure>
              </span>
              <div
                className="dropdown user-dropdown"
                style={{ display: this.state.userDropdown ? "block" : "none" }}
              >
                <div
                  onClick={() => this.setState({ userDropdown: false })}
                  style={{
                    display: this.state.userDropdown ? "block" : "none",
                  }}
                  className="back-drop"
                ></div>
                <ul>
                  <li>
                    <div className="user-box">
                      <figure>
                        {this.state.showUpdateImage ? (
                          <img src={this.state.updateImage} />
                        ) : (
                          <img
                            src={
                              this.props.userDetail.thumb_value
                                ? baseURL +
                                  this.props.userDetail.thumb_value.split(
                                    ".."
                                  )[1]
                                : avtarIcon
                            }
                          />
                        )}
                        {/* <img src={this.props.userDetail.thumb_value ? baseURL + this.props.userDetail.thumb_value.split('..')[1] : avtarIcon} alt="user" /> */}
                      </figure>
                      <div className="text">
                        <h6>
                          {this.props.updateProfileName
                            ? unicodeToChar(this.props.updateProfileName)
                            : unicodeToChar(fullName)}
                        </h6>
                        <p onClick={this.profileHandlar}> View/edit profile</p>
                      </div>
                    </div>
                  </li>
                  <li className="create-link">
                    <Link to="/_newevent">Create an event</Link>
                  </li>
                  <li onClick={this.cancelsignOutHandler}>Sign out</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  groupDetail: state.groupDetail.detail,
  updateProfileImg: state.editProfile.updateImage,
  updateProfileName: state.editProfile.updateName,
});
const mapDispatchToProps = (dispatch) => {
  return {
    stepHandler: (step, type) => dispatch(actions.stepHandler(step, type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
