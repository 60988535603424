import React from 'react';
import './popup.scss';

const popup = (props) => {
	// const [userDropdown, setuserDropdown] = useState(false);
	return (
		<div style={{
			display: props.toggleHandler ? 'block' : 'none'

		}} className={props.type ? "popup " + props.type : 'popup'}>
			<div className="backDrop" onClick={props.click}></div>
			<div className="popup-box">
				<span className="btn-close" onClick={props.click}><i className="ion-ios-close-empty"></i></span>
				{props.children}
			</div>
		</div>
	)
}

export default popup;
