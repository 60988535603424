import React from 'react';
import { DokaModal, toURL } from '../../react-doka';
import newImage from '../../assets/images/about-img.png';

class EditImage extends React.Component {
    state = {
        enabled: false,
        result: null
    };

    handleToggle = () => {

        console.log('Toggle Doka modal');

        this.setState({
            enabled: !this.state.enabled
        });
    }

    handleDokaConfirm = (output) => {

        console.log('Confirmed Doka Modal', output);

        this.setState({
            enabled: false,
            result: output.file
        });
    }

    handleDokaCancel = () => {

        console.log('Cancelled Doka Modal');

        this.setState({
            enabled: false
        });
    }

    render() {
        const { enabled, src, result } = this.state;
        
        return <div>

            <p><button onClick={ this.handleToggle }>Toggle Modal</button></p>

            { enabled && <DokaModal 
                // utils={['crop', 'filter', 'color', 'markup']}
                src={ newImage }
                onConfirm={ this.handleDokaConfirm }
                onCancel={ this.handleDokaCancel }/> }

            { result && <img src={ toURL(result) } alt="" />}
            
        </div>
    }
}

export default EditImage;