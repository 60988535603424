import React, { Component } from "react";
import "./createEvent.scss";

import CreateEventBox from '../../components/createEventBox/createEventBox'


class createEvent extends Component {

	render() {
		return (
			<div className="create-event">

				<h2>Create new event</h2>
				<CreateEventBox groupDetail={this.props.groupDetail} />
			</div>
		);
	}
}

export default createEvent
