import React, { Component } from "react";
import "./feedList.scss";
import Slider from "react-slick";
import { instance, baseURL } from "../../axios";
import avtarIcon from "../../assets/images/avtar.png";
import Popup from "../popup/popup";
import BMF from "browser-md5-file";
import laughingIcon from "../../assets/images/laughing.png";
import fireIcon from "../../assets/images/fire.png";
import facepalmIcon from "../../assets/images/facepalm.png";
import headExploding from "../../assets/images/head-exploding.png";
import { Picker } from "emoji-mart";
import checkIcon from "../../assets/images/check-icon.png";
import closeIcon from "../../assets/images/close-icon.png";
import { quote, unicodeToChar } from "../../shared/utility";
import uploadIcon from "../../assets/images/server.svg";
import loadingImg from "../../assets/images/loading-new.gif";
import checkIconSVG from "../../assets/images/check-icon.svg";
import errorIcon from "../../assets/images/error-icon.svg";
import { channelOne, channelTwo } from "../../pusher";
import { useRouteMatch } from "react-router-dom";
import viewIcon from "../../assets/images/view-icon.svg";
import questionIcon from "../../assets/images/question-icon.svg";
import webIcon from "../../assets/images/web-icon.svg";
import Loading from "../loading/loading";
import EditImage from "../editImage/editImage";
import { DokaModal, toURL } from "../../react-doka";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import Nofeeds from "../../assets/images/no-feeds.png";
import SendIcon from "../../assets/images/send-icon.svg";
import plusIcon from "../../assets/images/plus-icon.png";
import messageIcon from "../../assets/images/message-icon.png";
import CameraIconNew from "../../assets/images/camera-icon-new.png";
import VideoIconNew from "../../assets/images/video-icon.png";
import CameraIconPink from "../../assets/images/camera-icon-pink.png";
import CameraIconWhite from "../../assets/images/camera-icon-white.svg";
import SendButton from "../../assets/images/send.svg";
import listIcon from "../../assets/images/list.svg";

class feedList extends Component {
  render() {
    return (
      <div className="full-listbox">
        {/* <div className="feedsList">
          <h2>FOMO stories</h2>
        </div>

        <div className="list-box">
          <div className="box">
            <div className="detail-box">
              {this.state.groupSettings ? (
                <div className="user-detail">
                  {this.state.groupSettings.rcv_ledby_on == "true" ? (
                    <figure>
                      <img
                        src={
                          slide
                            ? this.state.allUsersPhotoId[slide.photo_id]
                              ? this.state.allUsersPhotoId[slide.photo_id]
                                  .thumb_value
                                ? baseURL +
                                  this.state.allUsersPhotoId[
                                    slide.photo_id
                                  ].thumb_value.split("..")[1]
                                : avtarIcon
                              : avtarIcon
                            : avtarIcon
                        }
                        alt="user"
                      />
                    </figure>
                  ) : null}
                  <div className="text-box">
                    {this.state.groupSettings.rcv_ledby_on == "true" ? (
                      <p>{slide.alias}</p>
                    ) : null}
                    {this.state.groupSettings.rcv_captions_on == "true" ? (
                      slide.caption ? (
                        <span>{unicodeToChar(slide.caption)}</span>
                      ) : null
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            <figure>
              <img src={Nofeeds} />
            </figure>
            <div className="mobile-box">
              <div className="inner-mobile-box">
                <button
                  type="botton"
                  onClick={this.openSendHandler}
                  className="messsage-btn"
                >
                  <img src={SendButton} /> message
                </button>

                <ul>
                  <li
                    className={
                      headExplodingReaction.find((obj) => {
                        if (obj.photo_id == this.state.photoID) {
                          return "active";
                        }
                      })
                        ? "active"
                        : ""
                    }
                    onClick={
                      this.state.getAllFields
                        ? this.state.getAllFields.reactions
                          ? () => this.addReaction(slide.object_id, quote("🤯"))
                          : () =>
                              this.welcomeStepHandler(7, "signup", "reactions")
                        : ""
                    }
                  >
                    <img src={headExploding} />
                    <span>{headExplodingReaction.length}</span>
                  </li>
                  <li
                    className={
                      fireReaction.find((obj) => {
                        if (obj.photo_id == this.state.photoID) {
                          return "active";
                        }
                      })
                        ? "active"
                        : ""
                    }
                    onClick={
                      this.state.getAllFields
                        ? this.state.getAllFields.reactions
                          ? () => this.addReaction(slide.object_id, quote("🔥"))
                          : () =>
                              this.welcomeStepHandler(7, "signup", "reactions")
                        : ""
                    }
                  >
                    <img src={fireIcon} />
                    <span>{fireReaction.length}</span>
                  </li>
                  <li
                    className={
                      facepalmReaction.find((obj) => {
                        if (obj.photo_id == this.state.photoID) {
                          return "active";
                        }
                      })
                        ? "active"
                        : ""
                    }
                    onClick={
                      this.state.getAllFields
                        ? this.state.getAllFields.reactions
                          ? () => this.addReaction(slide.object_id, quote("🤦‍♂️"))
                          : () =>
                              this.welcomeStepHandler(7, "signup", "reactions")
                        : ""
                    }
                  >
                    <img src={facepalmIcon} />
                    <span>{facepalmReaction.length}</span>
                  </li>
                  <li
                    className={
                      laughingReaction.find((obj) => {
                        if (obj.photo_id == this.state.photoID) {
                          return "active";
                        }
                      })
                        ? "active"
                        : ""
                    }
                    onClick={
                      this.state.getAllFields
                        ? this.state.getAllFields.reactions
                          ? () => this.addReaction(slide.object_id, quote("😂"))
                          : () =>
                              this.welcomeStepHandler(7, "signup", "reactions")
                        : ""
                    }
                  >
                    <img src={laughingIcon} />
                    <span>{laughingReaction.length}</span>
                  </li>
                </ul>
              </div>
              <button type="button" className="see-more-btn">
                <img src={listIcon} />
                See more
              </button>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default feedList;
