import React, { Component } from "react";
import Layout from "../../hoc/layout/layout";
import "./home.scss";
import { instance } from "../../axios";
import Conversation from "../../components/conversation/conversation";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { Redirect } from "react-router-dom";
import Streaming from "../../components/streaming/streaming";
import WhoHere from "../../components/whoHere/whoHere";
import Feeds from "../../components/feeds/feeds";
import { channelOne, channelTwo } from "../../pusher";
import WelcomePopup from "../../components/welcomePopup/welcomePopup";
import Popup from "../../components/popup/popup";
class Home extends Component {
  state = {
    userID: "",
    photoID: "",
    userDetail: "",
    notFound: false,
    groupDetail: "",
    mapView: false,
    showWelcomePopup: false,
    tableUnseenMessage: 0,
    eventUnseenMessage: 0,
    userUnseenMsg: 0,
    showInvalidPopup: false,
    groupNameRegex: /^[A-Za-z0-9][A-Za-z0-9_.-]*[A-Za-z0-9]+$/,
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.step !== this.props.step) {
      this.setState({
        showWelcomePopup: this.props.step ? true : false,
      });
    }
  }

  componentDidMount() {
    let userID = localStorage.getItem("user_id");
    let photoID = localStorage.getItem("photo_id");
    let groupTable = window.location.pathname.split("/")[3]
      ? window.location.pathname.split("/")[3].toUpperCase()
      : window.location.pathname.split("/")[3];
    let inviteId = window.location.pathname.split("/")[2]
      ? window.location.pathname.split("/")[2]
      : 0;
    let groupEvent = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1].toUpperCase()
      : window.location.pathname.split("/")[1];

    let groupMapId = window.location.pathname.split("/")[4];

    console.log(groupTable);
    localStorage.setItem("groupTable", groupTable);
    localStorage.setItem("groupEvent", groupEvent);

    let urlMain = window.location.pathname.split("/");
    if (urlMain[1]) {
      urlMain[1] = urlMain[1].toUpperCase().replace(/%20/g, "");
    }
    if (urlMain[3]) {
      urlMain[3] = urlMain[3].toUpperCase().replace(/%20/g, "");
    }

    console.log(urlMain.join("/"));
    if (window.location.pathname !== urlMain.join("/")) {
      window.location.href = urlMain.join("/");
    }

    channelOne.bind_global((event, data) => {
      console.log("home", event);
      if (data.approval_status == "group_logo") {
        instance
          .get(
            `${groupEvent}/_groupstatus.json?userid=${userID}&photoid=${photoID}`
          )
          .then((res) => {
            this.props.groupDetail(res.data);
          });
      }
      switch (event) {
        case "public-event":
          if (data.type == "admin-update") {
            this.props.groupDetail(data);
            this.setState({
              groupDetail: data,
            });
          }

          break;
        case "client-public":
          console.log(data);
          break;
      }
    });
    if (userID) {
      instance
        .get(`_getfomoonboard.json?user_id=${userID}&photo_id=${photoID}`)
        .then((res) => {
          console.log(res);
          var completedStep = res.data.onboard_completed_dic.profile;
          let requiredDetail = res.data.onboard_dic.profile.guest;

          var difference = requiredDetail.filter(
            (x) => completedStep.indexOf(x) === -1
          );
          console.log(difference);
          if (difference.length != 0) {
            this.setState({
              showWelcomePopup: true,
            });
            this.props.stepHandler(8, "guestNeeds");
          }
          // let requiredDetail = res.data.onboard_dic.profile.guest;
          // let completedStep = res.data.onboard_completed_dic.profile
          // let checkFields = getField.filter(element => completedStep.includes(element))
          // this.setState({
          // 	showWelcomePopup: true
          // })
        });
      instance
        .get(
          `_fomomsg_getprofile?user_id=${userID}&photo_id=${photoID}&notification_flag=true`
        )
        .then((res) => {
          this.setState({
            userDetail: res.data,
          });
          if (inviteId != res.data.invited_by_id) {
            if (groupMapId) {
              window.location.pathname =
                "/" +
                groupEvent +
                "/" +
                res.data.invited_by_id +
                "/" +
                groupTable +
                "/" +
                groupMapId;
            } else {
              window.location.pathname =
                "/" +
                groupEvent +
                "/" +
                res.data.invited_by_id +
                "/" +
                groupTable +
                "/";
            }
          }

          instance
            .get(
              `${groupTable}/_joingroup?user_id=${userID}&photo_id=${photoID}&alias=${res.data.alias}&group_type=current_group&group_id=${groupTable}`
            )
            .then((res) => {
              // console.log(res, 'joined')
            });

          instance
            .get(
              `${groupEvent}/_joingroup?user_id=${userID}&photo_id=${photoID}&alias=${res.data.alias}&group_type=current_group&group_id=${groupEvent}`
            )
            .then((res) => {
              // console.log(res, 'joined')
            });
        });
    }

    this.setState({
      showWelcomePopup: userID ? false : true,
    });

    localStorage.removeItem("backUrl");
    this.setState({
      userID: userID ? userID : "",
      photoID: photoID ? photoID : "",
      groupTable: groupTable,
      groupEvent: groupEvent,
    });

    if (
      groupTable == "0" ||
      groupTable == "" ||
      groupTable == "undefined" ||
      groupTable == undefined
    ) {
      if (userID != "" && userID != undefined) {
        let makeInviteId = 0;
        if (
          inviteId != "" &&
          inviteId != undefined &&
          inviteId != "undefined"
        ) {
          makeInviteId = inviteId;
        }
        console.log(makeInviteId);

        instance
          .get(
            `_newrandgroupid.json?userid=${userID}&photoid=${photoID}&event_id=${groupEvent}&mapdata_id=''`
          )
          .then((res) => {
            console.log(res, "_newrandgroupid");
            console.log(res.data.groupid);
            window.location.pathname =
              "/" + groupEvent + "/" + makeInviteId + "/" + res.data.groupid;
          });
      }
    }
    let protected_url = ["_login", "_signup", "_profile", "_emailconfirmed"];
    if (
      protected_url.includes(groupEvent) ||
      protected_url.includes(groupTable)
    ) {
      this.setState({
        notFound: true,
      });
    } else {
      console.log(groupEvent);
      if (groupEvent) {
        // setTimeout(() => {
        if (this.state.groupNameRegex.test(groupEvent.replace(/%20/g, ""))) {
          instance
            .get(
              `${groupEvent}/_groupstatus.json?userid=${userID}&photoid=${photoID}`
            )
            .then((response) => {
              if (response.data.userstatus == "INVALID") {
                window.location.href = "/_notfound";
                // this.setState({
                //   showInvalidPopup: true,
                // });
              }
              if (
                response.data.user_access === "USER" ||
                response.data.user_access === "OWNER"
              ) {
                this.props.groupDetail(response.data);

                this.setState({
                  groupDetail: response.data,
                });
                if (response.data.mapdata_id && groupTable != undefined) {
                  // let locationSlit = window.location.pathname.split("/");
                  // let mapUrl =
                  //   window.location.pathname + "/" + response.data.mapdata_id;
                  // if (locationSlit[locationSlit.length - 1] == "") {
                  //   mapUrl = window.location.pathname + response.data.mapdata_id;
                  // }
                  window.location.pathname =
                    "/" +
                    groupEvent +
                    "/" +
                    inviteId +
                    "/" +
                    groupTable +
                    "/" +
                    response.data.mapdata_id;
                }
              } else {
                // this.setState({
                // 	notFound: true,
                // });
              }
            });
        } else {
          window.location.href = "/_notfound";
        }
        // }, 1000);
      }

      if (userID) {
        if (this.state.groupNameRegex.test(groupTable)) {
          instance
            .get(
              `${groupTable}/_groupstatus.json?userid=${userID}&photoid=${photoID}`
            )
            .then((response) => {
              console.log(response, "test Table");
              console.log(response.data.user_access);
              if (
                response.data.user_access === "USER" ||
                response.data.user_access === "OWNER"
              ) {
              } else {
                // this.setState({
                // 	notFound: true,
                // });
                instance
                  .get(
                    `_newrandgroupid.json?userid=${userID}&photoid=${photoID}&event_id=${groupEvent}&mapdata_id=''`
                  )
                  .then((res) => {
                    console.log(res, "_newrandgroupid");
                    console.log(res.data.groupid);
                    window.location.pathname =
                      "/" +
                      groupEvent +
                      "/" +
                      inviteId +
                      "/" +
                      res.data.groupid;
                  });
              }
            });
        } else {
          instance
            .get(
              `_newrandgroupid.json?userid=${userID}&photoid=${photoID}&event_id=${groupEvent}&mapdata_id=''`
            )
            .then((res) => {
              console.log(res, "_newrandgroupid");
              console.log(res.data.groupid);
              window.location.pathname =
                "/" + groupEvent + "/" + inviteId + "/" + res.data.groupid;
            });
        }
      }
    }

    if (groupTable != "0" && groupTable != "" && groupTable != undefined) {
      // console.log(groupTable)
      instance
        .get(
          `${groupTable}/_groupstatus.json?userid=${userID}&photoid=${photoID}`
        )
        .then((response) => {
          console.log(response, "test Table");
          if (response.data.user_access === "USER") {
          }
          // else {
          //   this.setState({
          //     notFound: true,
          //   });
          // }
        });
    }
  }
  popupCloseHandler = () => {
    if (this.props.step) {
      this.setState({
        showWelcomePopup: false,
      });
      this.props.stepHandler("");
    }
  };

  openChatHandler = () => {
    console.log("worl", this.state.openChat);
    this.setState((prevState) => ({
      openChat: !prevState.openChat,
      tableUnseenMessage: 0,
      eventUnseenMessage: 0,
      userUnseenMsg: 0,
    }));
  };
  onTableUnseen = (data) => {
    this.setState({
      tableUnseenMessage: data,
    });
  };
  onEventUnseen = (data) => {
    this.setState({
      eventUnseenMessage: data,
    });
  };
  onUserUnseen = (data) => {
    this.setState({
      userUnseenMsg: this.state.userUnseenMsg + data,
    });
  };

  render() {
    let unSeenConcat =
      this.state.tableUnseenMessage +
      this.state.eventUnseenMessage +
      this.state.userUnseenMsg;
    return (
      <Layout
        backBtn={this.props.backBtn}
        parentMapUrl={this.props.parentMapUrl}
        userDetail={this.state.userDetail}
      >
        {this.state.notFound ? <Redirect to="/" /> : ""}
        {this.state.mapView ? <Redirect to={this.state.mapUrl} /> : ""}

        <div className="main-page">
          {/* {this.state.showInvalidPopup ? (
            <Popup toggleHandler={true} type="invalid-popup">
              <div className="invalid-box">
                <p>Invalid event Id</p>
              </div>
            </Popup>
          ) : (
            false
          )} */}
          {this.state.groupEvent && this.state.groupTable ? (
            <div className="flex-wrapper">
              <Feeds
                openChatHandler={this.openChatHandler}
                unSeenConcat={unSeenConcat}
                userDetail={this.state.userDetail}
                groupDetail={this.state.groupDetail}
              />
              <Streaming groupDetail={this.state.groupDetail} />
              <Conversation
                openChatHandler={this.openChatHandler}
                tableUnseenMessage={this.onTableUnseen}
                eventUnseenMessage={this.onEventUnseen}
                userUnseenMsg={this.onUserUnseen}
                chatOpen={this.state.openChat}
                userDetail={this.state.userDetail}
              />
              <WhoHere userDetail={this.state.userDetail} />
            </div>
          ) : null}
        </div>
        <WelcomePopup
          click={this.popupCloseHandler}
          toggleHandler={this.state.showWelcomePopup}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.welcomeStep.step,
});
const mapDispatchToProps = (dispatch) => {
  return {
    groupDetail: (details) => dispatch(actions.groupDetail(details)),
    stepHandler: (step, stepType) =>
      dispatch(actions.stepHandler(step, stepType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
